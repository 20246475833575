import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ResetForm from './components/ResetForm';
import Modal from '@common/modals/modal/BaseModal';

type Props = {
  show: boolean;
  hide: () => void;
};

const LoginModal: FC<Props> = ({ show, hide }) => {
  const { t } = useTranslation();

  return (
    <Modal show={show} hide={hide} center>
      <div className="register-modal">
        <p className="text-black tracking-wider text-center self-center">{t('Password reset').toUpperCase()}</p>
        <ResetForm hide={hide} />
      </div>
    </Modal>
  );
};

export default LoginModal;
