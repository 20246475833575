import React, { useCallback, useContext } from 'react';
import style from './NoOfferCards.module.scss';
import { IconPercent } from '@common/modals/register/comon/icons';
import Translation from '@common/translation';
import RegistrationContext from '@common/modals/register/context/context';
import { RegistrationPages } from '@common/modals/register/context/d';
import { useStoreState } from '@store';

const BonusCard = ({setActivePage}: any) => {

  const { showLoading } = useStoreState((state) => state);

  const handleClick = useCallback(() => {
    if(showLoading) return;
    setActivePage(RegistrationPages.BONUS);
  }, [setActivePage, showLoading]);

  return (
    <div
      onKeyDown={handleClick}
      role="button"
      tabIndex={0}
      className={`flex flex-col justify-between ${style.noOfferCard} ${style.noOfferBonusCard} gap-5 cursor-pointer${showLoading ? ' cursor-not-allowed': ''}`}
      onClick={handleClick}
    >
      <div className={'flex flex-col items-center gap-2'}>
        <div className={`${style.noOfferCardIcon} ${style.noOfferBonusCardIcon}`}>
          <IconPercent />
        </div>
        <div className={`${style.noOfferVipCardPrice}`}>50% <Translation use={'bonus'} /></div>
        <Translation use={'registrationNoOfferCardBonusText'} as={'div'} className={`${style.noOfferVipCardText}`} />
      </div>
    </div>
  );
};

export default BonusCard;