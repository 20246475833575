import React, { useContext } from 'react';
import style from './NoOffer.module.scss';
import { IconPercent } from '@common/modals/register/comon/icons';
import Translation from '@common/translation';
import VipCard from '@common/modals/register/components/no-offer/cards/VipCard';
import BonusCard from '@common/modals/register/components/no-offer/cards/BonusCard';
import FormButton from '@common/ui-components/buttons/FormButton';
import RegistrationContext from '@common/modals/register/context/context';
import { useStoreState } from '@store';


const NoOffer = () => {

  const { showLoading } = useStoreState((state) => state);
  const { clearNextPage, setActivePage } = useContext(RegistrationContext);

  return (
    <div className={`flex flex-col w-full bg-white ${style.noOfferFormContainer}`}>
      <div className={`${style.noOfferHeader} flex flex-col gap-3 items-center justify-center`}>
        <div className={`${style.noOfferHeaderIcon}`}>
          <IconPercent />
        </div>
        <div className={'flex flex-col gap-2 justify-center items-center'}>
          <Translation use={'registrationNoOfferHeaderTitle'} as={'p'} className={'font-semibold text-xl'} />
          <Translation use={'registrationNoOfferHeaderSubText'} as={'p'}
                       className={'text-md font-normal text-center opacity-70'} />
        </div>
      </div>
      <div className={`flex flex-col justify-center items-center pt-16 pb-8 px-10 ${style.noOfferBodyContainer}`}>
        <div className={`w-full flex justify-between items-start gap-6 ${style.noOfferBodyCardsContainer}`}>
          <VipCard setActivePage={setActivePage} />
          <BonusCard setActivePage={setActivePage}/>
        </div>
        <div className={`${style.noOfferFormButton} py-8`}>
          <FormButton text={'registrationNoOfferButtonText'} bgColor={'green'} textColor={'white'} type="submit"
                      handleClick={clearNextPage} loading={showLoading} />
        </div>
        {/*<div className={'font-normal text-xs text-black text-opacity-50 text-center'}>
          <Translation use="registrationNoOfferBottomText" as="p" />
        </div>*/}
      </div>
    </div>
  );
};

export default NoOffer;