import { useCallback, useEffect, useMemo } from 'react';
import { EventNotification, TProcessNotificationProps } from './d';
import { useStoreState, useStoreActions } from '@store';
import Pusher from 'pusher-js';


const useProcessEventNotification = () => {


  const { token } = useStoreState((state) => state.user.session.sessionInfo);
  const { userInfo } = useStoreState((state) => state.user as any);
  const {setSubscription} = useStoreActions((actions) => actions.user);


  const processNotification = useCallback((notification: TProcessNotificationProps)=> {
    switch(notification.type) {
      case EventNotification.CANCEL_REQUEST_CHANGED: {
        setSubscription(notification.data);
        return;
      }


      default:
        return;
    }
  },[setSubscription]);

  useEffect(() => {
    if(!userInfo?.id || !token) return;

    Pusher.logToConsole = false;

    const pusher = new Pusher('829f852bad64887e714c', {
      cluster: 'eu'
    });

    const channel = pusher.subscribe(`notifications.${userInfo?.id}`);
    channel.bind('EventNotification', function(data: TProcessNotificationProps) {
      processNotification(data);
    });


    return ()=> {
      pusher.unsubscribe(`notifications.${userInfo?.id}`);
    };

  }, [userInfo, token, processNotification]);


  return useMemo(()=> ({
    processNotification
  }), [processNotification]);
};

export default useProcessEventNotification;


