import { PropsWithChildren, useCallback, useMemo, useReducer, useRef } from 'react';
import {
  initialRegistrationBonusesData,
  REGISTRATION_BONUSES_EVENTS, RegistrationBonusPages,
  TRegistrationBonusesActions,
  TRegistrationBonusesReducer,
} from './d';
import RegistrationBonusesContext from '@common/modals/registerPromo/context/context';


const reducer = (state: TRegistrationBonusesReducer, action: TRegistrationBonusesActions) => {
  switch (action.type) {
    default:
      return state;

    case REGISTRATION_BONUSES_EVENTS.SET_ACTIVE_PAGE: {
      console.log('called ', action);
      const isNeedBack = [RegistrationBonusPages.VIP, RegistrationBonusPages.BONUS].includes(action.payload);

      return {
        ...state,
        lastPage: state.activePage || RegistrationBonusPages.REGISTER_PROMO_CODE_PICKER,
        activePage: action.payload,
        isNeedBack,
      };
    }

    case REGISTRATION_BONUSES_EVENTS.SET_NEXT_PAGE: {
      return {
        ...state,
        lastPage: state.activePage || RegistrationBonusPages.REGISTER_PROMO_CODE_PICKER,
        nextPage: action.payload,
      };
    }


    case REGISTRATION_BONUSES_EVENTS.CLEAR_NEXT_PAGE: {
      return {
        ...state,
        lastPage: RegistrationBonusPages.REGISTER_PROMO_CODE_PICKER,
        nextPage: undefined,
      };
    }

    case REGISTRATION_BONUSES_EVENTS.GO_BACK: {
      const { activePage, lastPage } = state;
      if (activePage === RegistrationBonusPages.REGISTER_PROMO_CODE_PICKER) return state;
      let newActivePage = lastPage || RegistrationBonusPages.REGISTER_PROMO_CODE_PICKER;
      if (!lastPage) {
        switch (activePage) {
          case RegistrationBonusPages.VIP:
          case RegistrationBonusPages.BONUS:
            newActivePage = RegistrationBonusPages.REGISTER_PROMO_CODE_PICKER;
        }
      }
      const isNeedBack = [RegistrationBonusPages.VIP, RegistrationBonusPages.BONUS].includes(newActivePage);

      return {
        ...state,
        lastPage: RegistrationBonusPages.REGISTER_PROMO_CODE_PICKER,
        activePage: newActivePage,
        isNeedBack,
        errorData: undefined,
      };
    }

  }
};


export const useRegistrationBonusesContext = (entryData = {}) => {
  const dataReduceState = useRef({
    ...initialRegistrationBonusesData,
    ...entryData,
  });

  const [state, dispatch] = useReducer(reducer, dataReduceState.current as TRegistrationBonusesReducer);


  const setActivePage = useCallback((payload: RegistrationBonusPages) => dispatch({
    type: REGISTRATION_BONUSES_EVENTS.SET_ACTIVE_PAGE,
    payload,
  }), [dispatch]);

  const setNextPage = useCallback((payload: RegistrationBonusPages) => dispatch({
    type: REGISTRATION_BONUSES_EVENTS.SET_NEXT_PAGE,
    payload,
  }), [dispatch]);

  const goBackPage = useCallback(() => dispatch({
    type: REGISTRATION_BONUSES_EVENTS.GO_BACK,
  }), [dispatch]);

  const clearNextPage = useCallback(() => dispatch({
    type: REGISTRATION_BONUSES_EVENTS.CLEAR_NEXT_PAGE,
  }), [dispatch]);


  return useMemo(() => ({
    ...state,
    isRegPromo: state.activePage === RegistrationBonusPages.REGISTER_PROMO_CODE_PICKER,
    setActivePage,
    setNextPage,
    goBackPage,
    clearNextPage,
  }), [state, setActivePage, setNextPage, goBackPage, clearNextPage]);

};


export type TRegistrationBonusesContextType = ReturnType<typeof useRegistrationBonusesContext>;

export type TRegistrationContextProps = {
  entryData?: TRegistrationBonusesReducer
}

const RegistrationBonusesContextContainer = ({
                                        entryData = {} as TRegistrationBonusesReducer,
                                        children,
                                      }: PropsWithChildren<TRegistrationContextProps>) => {

  const providerData = useRegistrationBonusesContext(entryData);

  return (
    <RegistrationBonusesContext.Provider value={providerData}>
      {children}
    </RegistrationBonusesContext.Provider>
  );
};

export default RegistrationBonusesContextContainer;