import React, { useContext, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import style from '@common/modals/register/components/promo/Promo.module.scss';
import { IconRiCoupon4Line } from '@common/modals/register/comon/icons';
import Translation from '@common/translation';
import FormButton from '@common/ui-components/buttons/FormButton';
import PromoPlans from '@common/modals/register/components/promo/Prices/PromoPlans';
import PaymentMethods from 'components/common/modals/register/components/payment-methods';
import { useStoreState } from '@store';
import RegistrationContext from '@common/modals/register/context/context';
import SelectedPackage from '@common/modals/register/components/selected-package';
import { formatPrice } from '@helpers/utils';


const Promo = () => {
  const { showLoading } = useStoreState((state) => state);
  const { clearNextPage } = useContext(RegistrationContext);
  const {values} = useFormikContext();
  const {t} = useTranslation();
  const [promoValue] = useMemo(()=> [
    (values as any)?.selectedPackages ? (()=> {
      return `${formatPrice((values as any)?.selectedPackages?.promo_value)}${(values as any)?.selectedPackages?.is_amount ? '€' : '%'}`;
    })() : '##.##'
  ] ,[values]);
  
  return (
    <div className={`flex flex-col w-full bg-white ${style.promoFormContainer}`}>
      <div className={`${style.promoHeader} flex flex-col gap-3 items-center justify-center`}>
        <div className={`${style.promoHeaderIcon}`}>
          <IconRiCoupon4Line />
        </div>
        <div className={'flex flex-col gap-2 justify-center items-center'}>
          <Translation use={'registrationPromoHeaderTitle'} as={'p'} className={`${style.promoHeaderTitle}`} />
          <p className={`${style.promoHeaderAreaText}`}>{t('registrationPromoHeaderAreaText')} <b>{promoValue}</b></p>
          {/*<Translation use={'registrationPromoHeaderAreaText'} as={'p'} className={`${style.promoHeaderAreaText}`} params={{promo: promoValue }}/>*/}
          <Translation use={'registrationPromoHeaderSubText'} as={'p'} className={`${style.promoHeaderText}`} />
        </div>
      </div>
      <div className={`flex flex-col px-6 pt-5 pb-5 ${style.promoBodyContainer}`}>
        <div className={`flex justify-between items-start pb-4 ${style.promoPackagesMethodContainer}`}>
          <PromoPlans />
          <div className={'flex flex-col flex-1 justify-between h-full'}>
            <PaymentMethods />
            <SelectedPackage />
          </div>
        </div>
        <div className={`py-3  ${style.promoButtonContainer}`}>
          <FormButton text={'registrationPromoButtonText'} bgColor={'green-600'} textColor={'white'} type='submit' handleClick={clearNextPage} loading={showLoading} />
        </div>
      </div>
    </div>
  );
};

export default Promo;