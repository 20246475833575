import { ErrorMessage, Field, FieldProps } from 'formik';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Translation from '@common/translation';


const FormikInputPassword = ({ className, placeholder, name, fieldType = 'password', label, disabled }: any) => {
  const [passwordShow, setPasswordShow] = useState(false);
  const { t } = useTranslation();
  
  const handleShowPassword = useCallback(()=> {
    if(disabled) return;
    setPasswordShow(v=> !v);
  },[disabled, setPasswordShow]);

  return (
    <Field name={name} disabled={disabled}>
      {({ field, meta }: FieldProps) => {
        const isError = meta.error && meta.touched;
        
        return (
          <div className={`relative flex flex-col${isError ? ' text-red-500' : ''}`}>
            {label ? <Translation use={label} as={'label'}   className={'text-sm'} />  : null}
            <input
              type={fieldType === 'password' ? (passwordShow ? 'text' : 'password') : fieldType}
              // tabIndex="-1"
              disabled={disabled}
              placeholder={t(placeholder)}
              className={`bg-white w-full rounded border border-gray-400 focus:outline-none focus:border-indigo-500 text-base  mt-1 mb-1${isError ? ' text-red-500 border-red-500' : ''} ${className || ''}${disabled ? ' cursor-not-allowed': ''}`}
              {...field}
            />
            {fieldType === 'password' && (
              <span
                onKeyDown={handleShowPassword}
                role="button"
                tabIndex={0}
                className={`password-show${disabled ? ' cursor-not-allowed': ''}`}
                onClick={handleShowPassword}
              >
              <i className={passwordShow ? 'fas fa-eye-slash' : 'fas fa-eye'} />
            </span>
            )}
            <div className="min-h-24 min-w-full text-xs text-red-500 text-left font-light font-semibold">{isError ? t(meta.error || '') : <>&nbsp;</>}</div>
          </div>
        );
      }}
    </Field>
  );
};


export default FormikInputPassword;