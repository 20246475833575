import React, { FC, useCallback } from 'react';
import style from './PaymentMethod.module.scss';
import { useStoreState } from '@store';

type Props = {
  Icon: string;
  methodName: string;
  handleMethodSelect: (value: string) => void;
  method: string;
};

const Method: FC<Props> = ({ Icon, methodName, handleMethodSelect, method }) => {

  const { showLoading } = useStoreState((state) => state);

  
  const onClick = useCallback(()=> {
    if(!methodName || showLoading) return;
    handleMethodSelect(methodName);
  },[methodName, handleMethodSelect, showLoading]);


  return (
    <div
      onKeyDown={onClick}
      role="button"
      tabIndex={0}
      onClick={onClick}
      id="payment-method"
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          onClick();
        }
      }}
      className={`${style.paymentMethodContainer} px-4 py-2 ${
        method === methodName ? 'border-2 border-green-700' : 'border-1 border-gray-400'
      }${showLoading ? ' cursor-not-allowed' : ''}`}
    >
      <img className={`${methodName === 'sepaDirectDebit' ? 'sepa-logo' : 'w-full h-full'}`} src={Icon} alt="card icon" />
    </div>
  );
};

export default Method;
