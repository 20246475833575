import React, { FC, useContext } from 'react';
import ResetForm from './components/ResetForm';
import Logo from '@images/registration/BUM-logo 2.png';
import Translation from '@common/translation';
import FormButton from '@common/ui-components/buttons/FormButton';
import RegistrationContext from '@common/modals/register/context/context';

type Props = {
  hide: () => void;
};

const PasswordReset: FC<Props> = ({ hide }) => {


  const { setLogin } = useContext(RegistrationContext);

  return (
    <div className={'login-form-container gap-6'}>
      <div
        className={`md:absolute mx-auto left-0 right-0 lg:static transition-all duration-300 ease-in-out`}
      >
        <img className={`logo-site pt-2 xxl:pt-0`} src={Logo} alt="site logo" />
      </div>
      <div className={'form-content'}>
        <Translation use={'Password reset'} as={'p'} className={'text-black tracking-wider text-center self-center uppercase'} />
        <ResetForm hide={hide} />
        <div className={'w-full font-semibold flex justify-center text-xl font-semibold mt-4'}>
          <hr className="hor-line" />
          <FormButton
            text="Sign in"
            textColor="black"
            handleClick={setLogin}
            bgColor="mainYellow"
            type="button"
            className={'w-full'}
          />
        </div>
      </div>
    </div>
  );
};

export default PasswordReset;
