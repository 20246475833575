import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { iconChange } from '../../../movies/common/ListItem/ListItem';
import styles from './MovieFlags.module.scss';
import GermanAudio from '@images/DE-BU-icon.png';

type MovieFlagsProps = {
  needs_icon: boolean
  new: boolean
  reduced: boolean;
  is_svod: boolean;
  is_top100?: boolean;
}

const MovieFlags:FC<MovieFlagsProps> = ({
  new: isNew,
  needs_icon,
  reduced,
  is_svod,
  is_top100
}) => {

  const { i18n } = useTranslation();

  return (
    <>
      {isNew ? (
        <div className={`${styles.newIcon}`}>
          <img
            src={iconChange.newIcon[i18n.language as keyof (typeof iconChange)['newIcon']]}
            alt="new icon"
            className="w-full rounded-tr-md"
          />
        </div>
      ) : null}
      {reduced ? (
        <div className={styles.reducedIcon}>
          <img
            src={iconChange.redIcon[i18n.language as keyof (typeof iconChange)['redIcon']]}
            alt="reduced movie"
            className="w-full rounded-tr-md"
          />
        </div>
      ) : null}
      <div className={styles.audioVipTop100Section}>
        {is_svod ? (
          <div className={styles.flag}>
            <img
              src={iconChange.vipIcon[i18n.language as keyof (typeof iconChange)['vipIcon']]}
              alt="vip free icon"
              className="w-full" />
          </div>
        ) : null}
        {needs_icon ? (
          <div className={styles.flag}>
            <img src={GermanAudio} alt="german audio icon" className="w-full" />
          </div>
        ) : null}
        {is_top100 ? (
          <div className={styles.flag}>
            <img
              src={iconChange.topIcon[i18n.language as keyof (typeof iconChange)['topIcon']]}
              alt="top 100 movie icon"
              className="w-full" />
          </div>
        ) : null}
      </div>
    </>
  );
};

export default MovieFlags;