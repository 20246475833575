import { useQuery } from '@tanstack/react-query';
import { DepositPricesModel, StoreMethodsModel } from '../types';
import { getDepositPrices } from '@http/endpoints/payments';

type DepositPricesQueryModel = {
  depositPrices: DepositPricesModel[];
  stored_methods: StoreMethodsModel[];
  isSuccess: boolean;
  balance: string;
  text: string | null;
};

const useDepositPricesQuery = (show: boolean): DepositPricesQueryModel => {
  const { data, isSuccess } = useQuery(
    ['depositmodaldata'],
    () => getDepositPrices({ params: { filter: 'deposit' } }),
    {
      enabled: show,
    }
  );
  const depositPrices = data?.data?.prices;
  const balance = data?.data?.balance;
  const text = data?.data?.text;
  const stored_methods = data?.data?.stored_methods;

  return { depositPrices, isSuccess, balance, text, stored_methods };
};

export default useDepositPricesQuery;
