import React, { FC } from 'react';
import styles from './FavoriteButton.module.scss';

type Props = {
  text: string;
  handleClick: () => void;
  active?: boolean;
};

const FavoriteButton: FC<Props> = ({ text, handleClick, active }) => {
  return (
    <div
      onKeyDown={handleClick}
      role="button"
      tabIndex={0}
      onClick={handleClick}
      className={`${
        active ? styles.wishlist_button : `${styles.wishlist_button} ${styles.active_wishlist_button}`
      } w-full bg-mainRed flex content-center text-white items-center px-5 py-3 rounded-md cursor-pointer shadow-lg select-none`}
    >
      <span>
        <i className="fas fa-star" />
      </span>
      <p className={styles.text}>{text}</p>
    </div>
  );
};

export default FavoriteButton;
