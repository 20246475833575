import React, { useCallback, useEffect, useState } from 'react';
import { setUser } from '@sentry/react';
import { useToasts } from 'react-toast-notifications';
import { useLocation } from 'react-router-dom';
import { LOCAL_STORAGE } from '../../../../types/localStorage';
import Modal from '@common/modals/modal/BaseModal';
import { useStoreState } from '@store';
import { getLocalStorageObj, removeLSItem } from '@helpers/getLocalStorage';
import { RegistrationPages } from '@common/modals/register/context/d';
import RegistrationBonusesContextContainer from '@common/modals/registerPromo/context';
import RegPromoBonusForm from '@common/modals/registerPromo/RegPromoBonusForm';
import { rejectPromoRegistration } from '@http/endpoints/user';



const RegisterPromo = () => {
  const location = useLocation();
  const { showRegisterBonus } = useStoreState((state) => state);
  const { isAuthenticated } = useStoreState((state) => state.user);
  const [show, setShow] = useState<boolean>(false);
  const [showCloseIcon, setCloseIcon] = useState<boolean>(true);
  const [isNeedReject, setIsNeedReject] = useState<boolean>(false);
  const [entryData, setEntryData] = useState<any>({});
  const { addToast } = useToasts();
  const hide = useCallback(()=> {
    setShow(false);
  },[setShow]);

  const removeLocalStorage = () => {
    removeLSItem(LOCAL_STORAGE.BONUS_MODAL_AUTO_OPEN);
    removeLSItem(LOCAL_STORAGE.BONUS_MODAL_DATA);
    removeLSItem(LOCAL_STORAGE.BONUS_SUCCESS);
  };


  useEffect(() => {
    if (location.pathname === '/auth/payment-success' || location.pathname === '/failed'|| location.pathname === '/success') return;
    const data = getLocalStorageObj(LOCAL_STORAGE.BONUS_MODAL_DATA);
    if(!data) {
      return;
    }
    if(getLocalStorageObj(LOCAL_STORAGE.REGISTRATION_MODAL_DATA)) {
      removeLocalStorage();
      return;
    }
    const isSuccess = localStorage.getItem(LOCAL_STORAGE.BONUS_SUCCESS);
    setCloseIcon(data?.showCloseIcon !== undefined ? data?.showCloseIcon : true);
    setEntryData({
      lastPage: data.activePage,
      isNeedBack: false,
      activePage: (()=> {
        if(!isSuccess) return RegistrationPages.REGISTER_PROMO_CODE_PICKER;
        switch(data.activePage) {
          case RegistrationPages.VIP: return RegistrationPages.VIP_FINISH;
          case RegistrationPages.BONUS: return RegistrationPages.BONUS_FINISH;
          case RegistrationPages.PROMO: return RegistrationPages.PROMO_FINISH;
        }
      })(),
      formData: data.formData
    });
    setIsNeedReject(!isSuccess || data?.activePage === RegistrationPages.REGISTER_PROMO_CODE_PICKER);
    setShow(true);
    removeLocalStorage();
  }, [showRegisterBonus, location]);

  const handleRegisterPromoPicker = useCallback(async () => {
    if (!isAuthenticated) {
      return;
    }
    try {
      const { data, error } = await rejectPromoRegistration();
      if(error) {
        throw error;
      }
      if(data?.status === 200) {
        setUser({
          ...data.data.data.user,
          ...data.data.data.user_info
        });
        addToast(data?.data?.message, {
          appearance: 'success'
        });
        hide();
      }
    }catch (error: any) {
      if (error.response?.data?.message) {
        addToast(error.response.data.message, {
          appearance: 'warning'
        });
      }
    } finally {
      removeLSItem(LOCAL_STORAGE.BONUS_MODAL_AUTO_OPEN);
      removeLSItem(LOCAL_STORAGE.BONUS_MODAL_DATA);
      removeLSItem(LOCAL_STORAGE.BONUS_SUCCESS);
    }
  }, [isAuthenticated, hide, addToast]);
  
  const handleHide = useCallback(()=> {
    hide();
    if(isNeedReject) handleRegisterPromoPicker().then();
  },[isNeedReject, hide, handleRegisterPromoPicker]);

  if(!show) return <></>;

  return (
    <Modal show={true} hide={handleHide} center noPadding closeOnOverlayClick={false} closeOnEsc={false} >
      <div className="registration-modal p-5">
        <RegistrationBonusesContextContainer entryData={entryData}>
          <div className={'wizard-container'}>
            <RegPromoBonusForm hide={hide} />
          </div>
        </RegistrationBonusesContextContainer>
      </div>
    </Modal>
  );
};

export default RegisterPromo;