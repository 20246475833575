import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import style from './RegistrationSuccess.module.scss';
import { IconCrownFill, IconPercent, IconRiCoupon4Line } from '@common/modals/register/comon/icons';
import RegistrationContext from '@common/modals/register/context/context';
import { RegistrationPages } from '@common/modals/register/context/d';
import Translation from '@common/translation';
import FormButton from '@common/ui-components/buttons/FormButton';
import Logo from '@images/registration/BUM-logo 2.png';
import { formatCurrency, formatPrice } from '@helpers/utils';
import RegistrationBonusesContext from '@common/modals/registerPromo/context/context';


const SuccessPage = ({ hide }: { hide: () => void }) => {
  const history = useHistory();
  const { activePage, formData } = useContext(RegistrationBonusesContext);

  const [Icon, title, text, color, params] = useMemo(() => {
    if (activePage === RegistrationPages.VIP_FINISH) return [IconCrownFill, 'registrationSuccessVipTitle', 'registrationSuccessVipText', style.yellow, {vip: `VIP ${formData?.selectedPackages?.title}`}];
    if (activePage === RegistrationPages.BONUS_FINISH) return [IconPercent, 'registrationSuccessTitle', 'registrationSuccessBonusText', style.purple, {amount: formatCurrency(formData?.selectedPackages?.final_price)}];
    return [IconRiCoupon4Line, 'registrationSuccessTitle', 'registrationSuccessPromoText', undefined, {promoCode: formData?.promoCode, amount: formatCurrency(formData?.selectedPackages?.final_price)}];
  }, [activePage, formData]);

  const isNeedLogo = useMemo(() => !(activePage === RegistrationPages.VIP_FINISH || activePage === RegistrationPages.BONUS_FINISH), [activePage]);

  const handleRedirect = useCallback(() => {
    const path = activePage === RegistrationPages.VIP_FINISH ? '/movies/svod' : '/movies/all?page=1';
    history.push(path);
    hide();
  }, [activePage, history, hide]);

  return (
    <div className={style.successPageContainer}>
      {!isNeedLogo ? <div className={`${style.iconContainer} ${color}`}>
        <Icon />
      </div> : <div className={style.logoContainer}><img src={Logo} alt={'site logo'} /></div>}
      <Translation use={title} as={'div'} className={style.successTitle} />
      <Translation use={text} params={params} as={'div'} className={`${style.successText}${isNeedLogo ? ` ${style.successTextPromo}`: ''}`} />
      <FormButton text={'registrationSuccessButtonLabel'} bgColor={'green'} textColor={'white'} type={'button'}
                  className={style.successButton} handleClick={handleRedirect} />
    </div>
  );
};

export default SuccessPage;