import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { MovieByIdModal } from '../../types';
import styles from './CategoryTagList.module.scss';

type Props = {
  data: MovieByIdModal['categories'];
};

const CategoryTagList: FC<Props> = ({ data }) => {
  return (
    <div className={styles.container}>
      {data.length
        ? data.map((tag) => {
            return (
              <Link key={tag.id} to={`/movies/category/${tag.id}/${tag.name}`} className={styles.pill}>
                <p className={styles.pill_text}>{tag.name}</p>
              </Link>
            );
          })
        : null}
    </div>
  );
};

export default CategoryTagList;
