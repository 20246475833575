import videojs from 'video.js';
import { useEffect, useRef, useState } from 'react';
import { useStoreState } from '@store';
import { useInterval } from '@hooks';
import { updateStreamTime } from '@http/endpoints/movies';

const usePlayer = ({ src, controls, autoplay, trailerThumb, type, mediaID, fromTrailer, setShowRate }) => {
  const [start, setStart] = useState(true);
  const { userInfo } = useStoreState((state) => state.user);
  const countRef = useRef(0);

  useInterval(() => {
    if (start) {
      updateTime();
    } else {
      return;
    }
  }, 30000);

  useEffect(() => {
    return () => {
      if (countRef?.current >= 18 && setShowRate) {
        setShowRate(true);
      }
    };
  }, [countRef, setShowRate]);

  async function updateTime() {
    countRef.current = countRef.current + 1;
    if (fromTrailer) return;
    // eslint-disable-next-line
    const { data, error } = await updateStreamTime({
      type,
      user_id: userInfo.id,
      resource_id: mediaID,
    });
  }

  const options = {
    preload: 'auto',
    fluid: true,
    controlBar: {
      volumePanel: {
        inline: false,
        volumeControl: {
          vertical: true,
        },
      },
    },
    responsive: true,
    playbackRates: [0.5, 1, 1.5, 2],
    html5: {
      hls: {
        enableLowInitialPlaylist: true,
        smoothQualityChange: true,
        overrideNative: true,
      },
    },
  };
  const videoRef = useRef(null);
  const [player, setPlayer] = useState(null);

  useEffect(() => {
    const vjsPlayer = videojs(videoRef.current, {
      ...options,
      controls,
      autoplay,
      sources: [src],
      errorDisplay: false,
    });
    vjsPlayer.landscapeFullscreen({
      fullscreen: {
        enterOnRotate: true,
        alwaysInLandscapeMode: true,
        iOS: true,
      },
    });

    const handlePlayStart = () => {
      if (vjsPlayer.currentTime === 0) {
        updateTime();
        vjsPlayer.currentTime = 1;
      }
      setStart(true);
    };

    const handlePause = () => {
      setStart(false);
    };

    const handleEnd = () => {
      setStart(false);
    };
    vjsPlayer.on('play', function () {
      handlePlayStart();
    });
    vjsPlayer.on('pause', function () {
      handlePause();
    });
    vjsPlayer.on('ended', function () {
      handleEnd();
    });

    setPlayer(vjsPlayer);

    return () => {
      if (player !== null) {
        player.dispose();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (player !== null) {
      player.src({ src });
    }
  }, [src, player]);

  useEffect(() => {
    if (player !== null && trailerThumb !== null) {
      player.poster(trailerThumb);
    }
  }, [trailerThumb, player]);

  return videoRef;
};

export default usePlayer;
