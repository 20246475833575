import { TUserSubscriptionData } from '@store/model/types';

export enum EventNotification {
  CANCEL_REQUEST_CHANGED='CANCEL_REQUEST_CHANGED'
}



export type TProcessNotificationProps = {
  type: EventNotification,
  data: TUserSubscriptionData
}
