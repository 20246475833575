import { FC } from 'react';
import { Link } from 'react-router-dom';
import { MovieByIdModal } from '../../types';
import styles from './ActressTagList.module.scss';

type Props = {
  data: MovieByIdModal['actors'];
};

const ActressTagList: FC<Props> = ({ data }) => {
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Stars:</h1>
      {data.length
        ? data.map((tag) => {
            return (
              <Link
                key={tag.id}
                to={`/star/${tag.id}/${tag.full_name.replace(/ /g, '-').replace(/[^\w-]+/g, '')}`}
                className={styles.pill}
              >
                <p className={styles.pill_text}>{tag.full_name}</p>
              </Link>
            );
          })
        : null}
    </div>
  );
};

export default ActressTagList;
