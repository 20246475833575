export const getLocalStorageObj = (key: string) => {
  try {
    return JSON.parse(localStorage.getItem(key) as string);
  } catch (e) {
    return null;
  }
};

export const getLocalStorageItem = (key: string) => {
  try {
    return localStorage.getItem(key);
  } catch (e) {
    return null;
  }
};

export const removeLSItem = (key: string) => localStorage.removeItem(key);