import React, { useCallback, useContext } from 'react';
import { useFormikContext } from 'formik';
import style from './PromoCode.module.scss';
import { IconRiCoupon4Line } from '@common/modals/register/comon/icons';
import FormButton from '@common/ui-components/buttons/FormButton';
import FormikInput from '@common/ui-components/input-fields/FormikInput';
import { RegistrationPages } from '@common/modals/register/context/d';
import RegistrationContext from '@common/modals/register/context/context';
import Translation from '@common/translation';
import { useStoreState } from '@store';
import { checkPromoCode } from '@http/endpoints/promoCode';

const PromoCode = () => {

  const { showLoading } = useStoreState((state) => state);
  const { setNextPage } = useContext(RegistrationContext);
  const { setFieldValue, setFieldError } = useFormikContext();

  const handlerPromo = useCallback(() => setNextPage(RegistrationPages.PROMO), [setNextPage]);

  const promoCodeCbFn = useCallback(async (value: string, name?: string) => {
    if (!value  || !value?.length || value?.length <= 2) return;
    await setFieldValue(`isValid_${name}`, false);
    const data = await checkPromoCode(value);
    if (!data) {
      setFieldError('promoCode', 'invalidPromoCode');
    } else {
      if (name) await setFieldValue(`isValid_${name}`, true);
    }
  }, [setFieldValue, setFieldError]);
  
  return (
    <div className={`${style.promoCodeContainer}`}>
      <IconRiCoupon4Line className={`${style.promoCodeIcon}`} />
      <div className={'flex flex-col flex-auto items-start h-full'}>
        <Translation use={'registrationPromoCodeTitle'} as={'div'}
                     className={`w-full font-semibold ${style.promoCodeInputLabel}`} />
        <div className={'w-full'}>
          <FormikInput
            name={'promoCode'}
            placeholder={'Enter promo code..'}
            className={style.promoCodeInput}
            disabled={showLoading}
            //withCbFn
           // cbFn={promoCodeCbFn}
          />
        </div>
      </div>
      <div>
        <FormButton text="Next" textColor="white" bgColor="gray-666" type={'submit'} disabled={showLoading} className={style.promoCodeButton}
                    handleClick={handlerPromo} loading={showLoading} />
      </div>
    </div>
  );
};


export default PromoCode;