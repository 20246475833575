import { FC, useEffect, useState } from 'react';
import { LinkItem } from '../../common';
import { INavLink } from './types';

type Props = {
  navLinks: INavLink[];
};
const DestopNav: FC<Props> = ({ navLinks }) => {

  return (
    <div className={`hidden lg:flex w-full bg-white lg:flex-wrap `} id="navigation">
      {navLinks.map((item, index) => (
        <LinkItem key={index} path={item.path} item={item} />
      ))}
    </div>
  );
};

export default DestopNav;
