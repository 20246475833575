import React, { ComponentType, ElementType, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';


export type TTranslateProps = {
  use: string,
  as?: ElementType | ComponentType,
  params?: any
} & PropsWithChildren<any>


const Translation = ({ use, as: Element = 'span', params = undefined, ...rest }: TTranslateProps) => {
  const { t } = useTranslation();

  return (
    <Element {...rest}>
      {t(use, params) || use}
    </Element>
  );
};
export default Translation;