import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SvodPriceModel } from '../../../../../../pages/unauthenticated/svod-deal/types';
import style from './VipPlan.module.scss';
import { formatPrice } from '@helpers/utils';
import { useStoreState } from '@store';

type Props = {
  data: SvodPriceModel,
  handleSelectPrice: (id: number) => void
  currentPrice?: number
};
const VipPlanPrice = ({
                        data,
                        handleSelectPrice,
                        currentPrice
                      }: Props) => {
  const { t } = useTranslation();

  const { showLoading } = useStoreState((state) => state);

  const [price, isSelected, isTrial] = useMemo(() => [
    data?.trial ? formatPrice(data.price) : formatPrice(Number(data.price) / Math.floor(data.days_valid / 30)),
    currentPrice && currentPrice === data.id,
    data?.trial
  ], [data, currentPrice]);
  
  const onClick = useCallback(()=> {
    if(showLoading || !data?.id) return;
    handleSelectPrice(data.id);
  },[data, handleSelectPrice, showLoading]);



  return (
    <div
      onKeyDown={onClick}
      role="button"
      tabIndex={0}
      onClick={onClick}
      className={`${style.vipPlanPriceContainer} flex justify-start items-center py-3 px-2 border border-gray-400 cursor-pointer${isSelected ? ` bg-green-600 border-green-600 text-white font-bold` : ''}${showLoading ? ' cursor-not-allowed' : ''}`}
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          handleSelectPrice(data.id);
        }
      }}
    >
      <p className={`${style.vipPlanPriceText}${!isSelected ? ' font-medium': ' font-semibold'}`}>
        <span className={'capitalize'}>{data?.title?.toLowerCase() || ''}</span>
        <span> = </span>
        <span>€ {price}</span>
        <span className={`${!isSelected ? 'text-gray-400' : 'text-white'} font-normal`}>{`${isTrial ? '' : `/${t('Month')}`}`.toLowerCase()}</span>
      </p>
    </div>
  );
};

export default VipPlanPrice;