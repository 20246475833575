import React, { useCallback, useEffect, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useFormikContext } from 'formik';
import Skeleton from 'react-loading-skeleton';
import style from './PromoPlans.module.scss';
import PromoPlanPrice from './PromoPlanPrice';
import { getDepositPricesPromoCode } from '@http/endpoints/payments';
import Translation from '@common/translation';

const PromoPlans = () => {


  const { values, errors, setFieldValue } = useFormikContext();

  const { priceId } = values as any;

  const promoCode = useMemo(()=> (values as any)?.promoCode ,[values]);

  const { data, isLoading, isFetched } = useQuery(
    [`deposit-prices-promo-${promoCode}`],
    () => getDepositPricesPromoCode(promoCode),
    {
      enabled: !!promoCode
    }
  );

  const error = useMemo(() => (errors as any)?.priceId, [errors]);
  const loading = useMemo(() => isLoading && !isFetched, [isLoading, isFetched]);
  const plans = useMemo(() => data?.data || [], [data]);



  useEffect(() => {
    if(!plans?.length || (values as any)?.priceId) return;
    const pack = plans?.find((x: any)=> x.price == 15);
    setFieldValue('priceId',pack?.id, false).then();
    setFieldValue('selectedPackages', pack || null, false).then();
  }, [plans, values]);

  const handleSelectPrice = useCallback((value: number) => {
    setFieldValue('priceId', value, false).then();
    setFieldValue('selectedPackages', plans?.find((x: any)=> x.id === value) || null, false).then();
  }, [setFieldValue, plans]);

  return (
    <div className={'flex flex-col flex-1 gap-5 relative'}>
      <Translation use={'selectPackage'} as={'p'} className={'text-md font-normal'} />
      <div className={'flex flex-col gap-2'}>
        {!loading && plans?.length ? plans.map((plan: any, index: number) =>
            <PromoPlanPrice
              handleSelectPrice={handleSelectPrice}
              data={plan}
              key={index}
              currentPrice={priceId}
            />)
          : [...Array(5).keys()].map((x) => (<div key={x + 1} className={`${style.promoSkeletonDiv}`}><Skeleton
                                                                                  className={'w-full h-full'} /></div>))}
      </div>
      {error && <Translation use={error} as={'div'}  className={`${style.promoErrorShow} text-red-600 text-sm`} />}
    </div>
  );
};

export default PromoPlans;