import React, { useCallback, useMemo } from 'react';
import { useFormikContext } from 'formik';
import Method from './Method';
import Translation from '@common/translation';
import Paypal from '@images/payment-icons/paypal-paysafe.svg';
import Visa from '@images/payment-icons/visa-mastercard.svg';
import style from '@common/modals/register/components/vip-plus-packages/VipPlusForm.module.scss';
import Sofort from '@images/payment-icons/method-draw-image.svg';

const PaymentMethods = ({isSvod, disableEpoch}: {isSvod?: boolean, disableEpoch?: boolean}) => {
    const {values, errors, touched, setFieldValue} = useFormikContext();
    
    const {paymentMethod} = values as any;
  const handleMethodSelect = useCallback((value: string) => {
    setFieldValue('paymentMethod', value, false).then();
  },[setFieldValue]);

  const [isError, error, _touched] = useMemo(() => [
    Boolean((errors as any)?.paymentMethod),
    (errors as any)?.paymentMethod,
    (touched as any)?.paymentMethod
  ], [errors, touched]);

  return (
    <div className={'flex flex-col flex-1 gap-5 relative'}>
      <Translation use={'selectPayment'} as={'p'} className={'text-md font-normal'}/>
      <div className={'flex flex-col gap-2'}>
        <Method handleMethodSelect={handleMethodSelect} methodName={'card'} Icon={Visa} method={paymentMethod} />
        {!isSvod ? (
          <Method handleMethodSelect={handleMethodSelect} methodName={'sofort'} method={paymentMethod} Icon={Sofort} />
        ) : null}
        {!disableEpoch ? (<Method handleMethodSelect={handleMethodSelect} methodName={'paypal'} method={paymentMethod} Icon={Paypal} />) : null}
      </div>
      {isError && _touched && <Translation use={error} as={'div'}  className={`${style.vipPlusErrorShow} text-red-600 text-sm`} />}
    </div>
  );
};

export default PaymentMethods;