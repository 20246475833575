import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-use';
import styles from './EnjoyVipButton.module.scss';
import BgStudios from '@assets/images/up-sell-modal/bg-studios.png';
import BgStudiosMobile from '@assets/images/up-sell-modal/mobile/bg-studios-mobile.jpeg';
import MoneyIcon from '@images/up-sell-modal/money-icon-white.png';
import BeatVIP from '@images/up-sell-modal/button-beateuhsevip.png';
import BeatVIPmobile from '@images/up-sell-modal/mobile/beate_uhse_mobile.png';

type Props = {
  handleClick: () => void;
  fullPrice: string;
  validDays: number;
};

const EnjoyVipButton: FC<Props> = ({ fullPrice, validDays, handleClick }) => {
  const { t } = useTranslation();
  const isMobile = useMedia('(max-width: 480px)');
  const [priceOne, setPriceOne] = useState<number | null>(0);
  useEffect(() => {
    if (fullPrice) {
      const calculateDays = Number(validDays) < 30 ? Number(validDays) : null;
      const calculateMonths = Number(validDays) >= 30 ? Number((Number(validDays) / 30).toFixed(0)) : null;
      const price = calculateDays ? Number(fullPrice) : calculateMonths ? Number(fullPrice) / calculateMonths : null;
      setPriceOne(price);
    }
  }, [fullPrice, validDays]);

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.banner_container}>
          <img src={isMobile ? BgStudiosMobile : BgStudios} alt="BgStudios" className={styles.bgStudios_img} />
          <div className={styles.banner_wrapper}>
            <div>
              <ul className={styles.ul}>
                <li className={styles.li}>{t('All together for only €8.33')}</li>
                <li className={styles.li}>{t('Over 6000 movies online')}</li>
                <li className={styles.li}>{t('New Movies EVERY DAY')}</li>
              </ul>
            </div>
          </div>
        </div>
        <div className={styles.price_container}>
          <div className={styles.price_wrapper}>
            <div className={styles.price_wrapper_left}>
              <img src={MoneyIcon} alt="MoneyIcon" className={`${styles.money_icon} `} />
              <p className={styles.text}>{t('Save smart')}</p>
            </div>
            <p className={styles.money_text}>
              {`${Number(priceOne).toFixed(2).replace('.', ',')}€`} <p className={styles.per_month}>{t('in Month')}</p>
            </p>
          </div>
        </div>
        <div className={styles.button_container}>
          <div className={styles.button_wrapper}>
            <button onClick={handleClick} className={styles.proceed_button}>
              {t('Proceed to checkout').toUpperCase()}
            </button>
          </div>
          <img src={isMobile ? BeatVIPmobile : BeatVIP} alt="BeatVIP" className={styles.button_banner} />
        </div>
      </div>
    </div>
  );
};

export default EnjoyVipButton;
