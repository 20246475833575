import React from 'react';
import { useTranslation } from 'react-i18next';

const ErrorFallback = () => {
  const { t } = useTranslation();
  const cookiesAllowed = navigator.cookieEnabled;
  return (
    <div className="w-screen h-screen flex flex-col justify-center items-center">
      <h2 className="text-lg font-semibold w-[320px] text-center">
        {cookiesAllowed
          ? 'Something went wrong.'
          : t('Something went wrong. Please make sure that your browser allows cookies to use the website.')}
      </h2>
      <div className="flex mt-10">
        <button
          className="rounded px-6 font-semibold py-2 bg-mainBlue opacity-8 text-white border-0 border-transparent focus:outline-none mr-5"
          onClick={() => window.location.assign(window.location.origin)}
        >
          Refresh
        </button>
        <a
          className="rounded px-6 font-semibold py-2 bg-mainBlue opacity-8 text-white border-0 border-transparent focus:outline-none"
          href="https://beate-uhse-movie.zendesk.com/hc/de/requests/new"
        >
          Contact Us
        </a>
      </div>
    </div>
  );
};

export default ErrorFallback;
