import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-use';
import { RippleButton } from '../RippleButton';
import styles from './JoinClubButton.module.scss';
import BUVipDesktop from '@images/up-sell-button/desktop/beate_uhse_vip_desktop.png';
import BUVipMobile from '@images/up-sell-button/mobile/beate_uhse_vip_mobile.png';
import MoneyIcon from '@images/up-sell-modal/money-icon-black.png';
import {ReactComponent as ArrowRight} from 'assets/images/chevron-right-solid.svg';


type Props = {
  handleClick: () => void;
  fullPrice: string;
  validDays: number;
};
const JoinClubButton: FC<Props> = ({ fullPrice, validDays, handleClick }) => {
  const { t } = useTranslation();
  const [priceOne, setPriceOne] = useState<number | null>(0);
  const isDesktop = useMedia('(min-width: 526px)');
  useEffect(() => {
    if (fullPrice) {
      const calculateDays = Number(validDays) < 30 ? Number(validDays) : null;
      const calculateMonths = Number(validDays) >= 30 ? Number((Number(validDays) / 30).toFixed(0)) : null;
      const price = calculateDays ? Number(fullPrice) : calculateMonths ? Number(fullPrice) / calculateMonths : null;
      setPriceOne(price);
    }
  }, [fullPrice, validDays]);
  return (
    <div className={`${styles.container} mb-5 w-full flex flex-col bg-opacity-8 rounded-md shadow-xl `}>
      <div className={`${styles.price_row} `}>
        <img src={isDesktop ? BUVipDesktop : BUVipMobile} alt="BUVip" className={`${styles.buvip}`} />
        <div className={`${styles.money_container}`}>
          <div className={styles.price_wrapper_left}>
            <img src={MoneyIcon} alt="MoneyIcon" className={`${styles.money_icon} `} />
            <p className={styles.text}>{t('Save smart')}</p>
          </div>
        </div>
        <p className={`${styles.price_container}`}>
          {`${Number(priceOne).toFixed(2).replace('.', ',')}€`}{' '}
          <p className={`${styles.price_label} `}>{t('in Month')}</p>
        </p>
      </div>
      <div className={`flex items-center justify-between bg-gray-300 xl:h-full  ${styles.button_container}`}>
        <div className={styles.list_container}>
          <h1 className={`${styles.list_title} sm:text-2xl font-bold xs:text-xl xss:text-base`}>
            {t('This film is included in VIP+')}
          </h1>
          <ul className={styles.ul}>
            <li className={`${styles.list_item} ml-6 sm:text-xl tracking-tighter xs:text-xl xss:text-sm`}>
              {t('Over 6000 movies online')}
            </li>
            <li className={`${styles.list_item}  ml-6 sm:text-xl tracking-tighter xs:text-xl xss:text-sm`}>
              {t('New Movies EVERY DAY')}
            </li>
          </ul>
        </div>
        <div className="flex justify-center items-center">
          <RippleButton
            rippleColor={'bg-green-400'}
            className={`${styles.button} whitespace-nowrap bg-green-650 py-3 px-8 rounded-full tracking-wider font-semibold lg:text-2xl text-white xs:text-2xl xss:text-sm`}
            onClick={handleClick}
          >
            <div className={'flex row items-center gap-1'}>
              <span>{t('Proceed to checkout VIP').toUpperCase()}</span> <span><ArrowRight
              className={`${styles.arrow_icon}`} color={'#FFFFFF'} /></span>
            </div>

          </RippleButton>
        </div>
      </div>
    </div>
  );
};

export default JoinClubButton;
