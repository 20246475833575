import { action, computed } from 'easy-peasy';
import { MovieModel, SceneModel } from '@store/model/types';


const sceneModel: SceneModel = {
  page: 1,
  list: {},
  hasMore: true,
  scrollPosition: 0,
  setPage: action((state, payload) => {
    state.page = payload;
  }),
  setList: action((state, payload) => {
    state.list = {
      ...state.list,
      [payload.page] : payload.list
    };
  }),
  getNextPage: action((state) => {
    state.page = state.page + 1;
  }),
  setHasMore: action((state, payload) => {
    state.hasMore = payload;
  }),
  setScrollPosition: action((state, payload) => {
    state.scrollPosition = payload;
  }),
  setData: action((state, payload) => {
    state.hasMore = payload.hasMore;
    state.page = payload.page;
    state.scrollPosition = payload.scrollPosition;
    state.list = state?.list || {};
  }),
  resetData: action((state) => {
    state.page = 1;
    state.hasMore= true;
    state.scrollPosition = 0;
    state.list = {};
  }),
};


export default sceneModel;