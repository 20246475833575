import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { DepositPricesModel } from '../../types';
import styles from './Prices.module.scss';

type Props = {
  handlePriceSelect: (value: number | string) => void;
  price: number;
  checkPrice: string | number;
  data: DepositPricesModel[];
};
const Prices: FC<Props> = ({ handlePriceSelect, price, data, checkPrice }) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (data?.length) {
      if (data.length > 3 && !price) {
        handlePriceSelect(data[2].id);
      }
    }
  }, [data, handlePriceSelect, price]);

  return (
    <div className="w-full md:pl-5 mt-4 md:mt-0">
      <p className={styles.price_title}>{t('AMOUNT')}</p>
      <div className={styles.amount_container}>
        {data.length
          ? data.map((priceItem) => {
            return (
              <div
                onKeyDown={() => handlePriceSelect(priceItem.id)}
                role="button"
                tabIndex={0}
                key={priceItem.id}
                onClick={() => {
                  handlePriceSelect(priceItem.id);
                }}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handlePriceSelect(priceItem.id);
                  }
                }}
                className={`${
                  price === priceItem.id || price === Number(priceItem.id)
                    ? 'border-2 border-mainRed'
                    : 'border-2 border-transparent'
                } ${checkPrice ? (Number(checkPrice) > Number(priceItem.price) ? 'hidden' : '') : ''} ${
                  styles.price_container
                }`}
              >
                €{priceItem.price}
              </div>
            );
          })
          : [...Array(5).keys()].map((x) => <Skeleton key={x + 1} height={46} className={'w-full mt-0/5'} />)}
      </div>
    </div>
  );
};
export default Prices;
