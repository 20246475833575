import React, { lazy, useCallback, useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { useStoreActions, useStoreState } from 'easy-peasy';
import Pusher from 'pusher-js';
import useProcessEventNotification from '../../../hooks/useProcessEventNotification';
import AuthRoute from './common/AuthRoute';
import { confirmPaymentRedirect } from '@http/endpoints/payments';

// import MyAccount from "./my-account/MyAccount";
// import PaymentSuccess from "./payment-success/SuccessPage";
// import MyMovies from "./my-movies/MyMovies";
// import MyScenes from "./my-scenes/MyScenes";
// import MySubscriptions from "./my-subscriptions/Club";
// import Wishlist from "./wishlist/Wishlist";
// import PasswordReset from "./pwd-reset/PasswordReset";

const MyAccount = lazy(() => import('./my-account/MyAccount'));
const PaymentSuccess = lazy(() => import('./payment-success/SuccessPage'));
const MyMovies = lazy(() => import('./my-movies/MyMovies'));
const MyScenes = lazy(() => import('./my-scenes/MyScenes'));
const MySubscriptions = lazy(() => import('./my-subscriptions/Club'));
const Wishlist = lazy(() => import('./wishlist/Wishlist'));
const PasswordReset = lazy(() => import('./pwd-reset/PasswordReset'));
/**
 * Pages for authenticated users
 *
 * @component
 * @param  {string} path if user is authenticated set path prefix to auth
 */
const AuthPages = ({ match: { path } }) => {
  const root = path === '/' ? '' : path;
  const { userInfo } = useStoreState((state) => state.user);
  const [paymentID, setPaymentID] = useState('');
  const { updateUser } = useStoreActions((state) => state.user);
  const { setShowDeposit } = useStoreActions((actions) => actions);

  const { addToast } = useToasts();

  useProcessEventNotification();
  
  
  useEffect(() => {
    if (userInfo?.last_payment_id) {
      setPaymentID(userInfo?.last_payment_id);
    }
  }, [userInfo]);

  const sendPaymentID = useCallback(async () => {
    const { data, error } = await confirmPaymentRedirect({
      payment_id: paymentID,
    });
    if (error) {
      if (error?.response?.data?.message) {
        addToast(error.response.data.message, { appearance: 'error' });
      }
      if (error?.response?.data?.data?.open_modal) {
        setShowDeposit(true);
      }
    }
    if (data) {
      updateUser();
      addToast(data.data.message, { appearance: 'success' });
    }
  }, [addToast, paymentID, setShowDeposit, updateUser]);

  useEffect(() => {
    if (paymentID) {
      sendPaymentID();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addToast, paymentID]);




  return (
    <div>
      <Switch>
        <AuthRoute path={`${root}/myaccount`}>
          <MyAccount />
        </AuthRoute>
        <AuthRoute path={`${root}/purchased-movies`}>
          <MyMovies />
        </AuthRoute>
        <AuthRoute path={`${root}/purchased-scenes`}>
          <MyScenes />
        </AuthRoute>
        <AuthRoute path={`${root}/mysubscriptions`}>
          <MySubscriptions />
        </AuthRoute>
        <AuthRoute path={`${root}/wishlist`}>
          <Wishlist />
        </AuthRoute>
        <AuthRoute path={`${root}/payment-success`}>
          <PaymentSuccess />
        </AuthRoute>
        <Route path={`${root}/password-reset`} component={PasswordReset} />
        <Route path="*" render={() => <Redirect to="/" />} />
      </Switch>
    </div>
  );
};

export default AuthPages;
