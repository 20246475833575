import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { authRequest, authResponse } from './interceptors/authentication';
import store from '@store';

const API_URL_PROD = process.env.REACT_APP_API_URL_PROD;
const API_URL_DEV = process.env.REACT_APP_API_URL_DEV;

const url = process.env.NODE_ENV !== 'production' ? API_URL_DEV : API_URL_PROD;

const http = axios.create({
  baseURL: url + '/api',
});

const refreshToken = store.getState().user.session.sessionInfo.refresh_token;

const refreshAuthLogic = (failedRequest) =>
  http
    .post('/refresh', { refresh_token: refreshToken })
    .then((tokenRefreshResponse) => {
      store.getActions().user.setAuthInfo(tokenRefreshResponse.data.data);
      failedRequest.response.config.headers['Authorization'] = 'Bearer ' + tokenRefreshResponse.data.data.token;
      return Promise.resolve();
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error(error);
      store.getActions().user.removeAuthInfo();
    });

http.interceptors.request.use(authRequest);
http.interceptors.response.use(...authResponse);
createAuthRefreshInterceptor(http, refreshAuthLogic, {
  statusCodes: [401, 403],
});

export default http;
