import http from '@http';
import toResponse from '@helpers/toResponse/toResponse';

export const checkUser = (email) => toResponse(http.post('/check-user', {email}));

// Get user info route
export const getUser = () => toResponse(http.get('/user'));

// Login user route
export const loginUser = (credentials) => toResponse(http.post('/login', credentials));

// Register user route
export const registerUser = (credentials) => toResponse(http.post('/register', credentials));

// Logout user route - delete httpOnly cookie - token
export const logoutUser = () => toResponse(http.get('/logout'));

// Change user password route - On MyAcconut page
export const changePassword = (credentials) => toResponse(http.post('/change-password', credentials));

// Change user email route - On MyAccount page
export const changeEmail = (credentials) => toResponse(http.post('/change-email', credentials));

export const changeUsername = (credentials) => toResponse(http.post('/change-username', credentials));

// Reset password route = Send email to confirm for forgot password
export const passwordResetMail = (credentials) => toResponse(http.post('/password/send', credentials));

// Get user data and token route - After confirm password reset in mail link token received in url on reset-password page
export const confirmMailToken = (token) => toResponse(http.get(`/password/find/${token}`));

// Send new password route - after reset mail confirm
export const newPasswordSend = (credentials) => toResponse(http.post('/password/reset', credentials));

// Send gift code
export const checkGiftCode = (token) => toResponse(http.get(`/claim?gift_code=${token}`));

export const rejectPromoRegistration = () => toResponse(http.patch('/reject-register-bonus'));