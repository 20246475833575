import React, { FC } from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';

type Props = {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
};
const RateModal: FC<Props> = ({ open, onClose, children }) => {
  const { t } = useTranslation();
  const customStyles = {
    modal: {
      animation: `${open ? 'customEnterAnimation' : 'customLeaveAnimation'} 500ms`,
      background: '#fff',
      borderRadius: '6px',
      width: 'auto',
      padding: '20px',
    },
    overlay: {
      background: 'rgba(0, 0, 0, 0.5)',
    },
  };
  return (
    <Modal open={open} onClose={onClose} styles={customStyles}>
      <div className="mt-5 px-5 pb-5">
        <p className="font-semibold text-lg md:text-xl hover:opacity-75 select-none text-center">{t('Please rate')}</p>
        <div className="pt-5">{children}</div>
      </div>
    </Modal>
  );
};

export default RateModal;
