import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SvodPriceModel } from '../../../../../../pages/unauthenticated/svod-deal/types';
import style from './PromoPlans.module.scss';
import { formatPrice } from '@helpers/utils';
import { DepositPricePromoModel } from '@common/modals/register/components/promo/types';
import { useStoreState } from '@store';

type Props = {
  data: DepositPricePromoModel,
  handleSelectPrice: (id: number) => void
  currentPrice?: number
};
const PromoPlanPrice = ({
                          data,
                          handleSelectPrice,
                          currentPrice,
                        }: Props) => {
  const { showLoading } = useStoreState((state) => state);
  const { t } = useTranslation();

  const [price, fullPrice, promoValue, isReversed,isAmount, isSelected] = useMemo(() => [
    formatPrice(data.price),
    formatPrice(data.final_price),
    formatPrice(data.promo_value),
    data.is_reversed,
    data.is_amount,
    currentPrice && currentPrice === data.id,
  ], [data, currentPrice]);
  
  const handleClick = useCallback(()=> {
    if(showLoading) return;
    if(!data?.id) return;
    handleSelectPrice(data.id);
  },[showLoading, handleSelectPrice, data]);

  return (
    <div
      onKeyDown={handleClick}
      role="button"
      tabIndex={0}
      onClick={handleClick}
      className={`${style.promoPlanPriceContainer} flex justify-center items-center py-3 px-2 border border-gray-400 cursor-pointer
      ${isSelected ? ` bg-green-600 border-green-600 text-white font-bold` : ''}
      ${showLoading ? ' cursor-not-allowed' : ''}
      `}
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          handleClick();
        }
      }}
    >
      <p className={`${style.promoPlanPriceText}${!isSelected ? ' font-medium' : ' font-semibold'}`}>{/*
        {`${price}€ ${isReversed ? '-' : '+'} ${promoValue}${isAmount ? '€': '%'}  = ${fullPrice}€`}*/}
        {`${price}€`}
      </p>
    </div>
  );
};

export default PromoPlanPrice;