import React, { Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastProvider as NotificationsProvider } from 'react-toast-notifications';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from '@pages';
import Spinner from '@common/spinner/Spinner';
import { reportCrashError } from '@http/endpoints/reports';
import { ScrollToTop } from '@hooks';
import { ReactQueryProvider } from 'core/root';

const AppProvider = ({ children }) => {
  const handleError = async (error, info) => {
    let message = `Error message (BUM Website): ${error?.message || 'no error message'}, System: ${
      navigator?.appVersion || ''
    } ${navigator?.userAgent || ''}, Location: ${info?.componentStack || 'no location provided'}`;
    if (message.includes('petalsearch') || message.includes('yandex')) return;

    const { data } = await reportCrashError({
      message,
      signature: '10e16b6298edf76cf29d0e4c0a6a9e9d969b2e5776b0e9c3e9a89b27314ebcfa33141fe9dc95ae8bfa8b7158bde5757a',
    });
    // eslint-disable-next-line no-console
    if (data) console.error(data);
  };
  return (
    <Router>
      <Suspense fallback={<Spinner show={true} />}>
        <ReactQueryProvider>
          <NotificationsProvider autoDismiss={true} placement={'top-center'}>
            <ErrorBoundary FallbackComponent={ErrorFallback} onError={handleError}>
              <ScrollToTop />
              {children}
            </ErrorBoundary>
          </NotificationsProvider>
        </ReactQueryProvider>
      </Suspense>
    </Router>
  );
};

export default AppProvider;
