import React, { useCallback, useContext, useMemo } from 'react';
import { Form, Formik } from 'formik';
import { useToasts } from 'react-toast-notifications';
import { object } from 'yup';
import { useQuery } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';
import { LOCAL_STORAGE } from '../../../../../types/localStorage';
import RegistrationContext from '@common/modals/register/context/context';
import PersonalInformation from '@common/modals/register/components/personal-information';
import { RegistrationPages } from '@common/modals/register/context/d';
import {
  BonusPackagesSchema,
  PersonalInformationSchema, PromoPackagesSchema,
  VipPlusPackagesSchema,
} from '@common/modals/register/components/d';
import { useStoreActions, useStoreState } from '@store';
import VipPlusPackages from '@common/modals/register/components/vip-plus-packages';
import FormButton from '@common/ui-components/buttons/FormButton';
import Bonus from '@common/modals/register/components/bonus';
import { IconArrowBack } from '@common/modals/register/comon/icons';
import Logo from '@images/registration/BUM-logo 2.png';
import NoOffer from '@common/modals/register/components/no-offer';
import Promo from '@common/modals/register/components/promo';
import { sleep } from '@helpers/utils';
import SuccessPage from '@common/modals/register/components/success-page';
import { getOnlyDepositPrices, getOnlySvodPrices, sendPaymentData, testPayments } from '@http/endpoints/payments';
import readCookie from '@helpers/readCookie';
import { checkUser, registerUser } from '@http/endpoints/user';
import { removeLSItem } from '@helpers/getLocalStorage';
import { checkPromoCode } from '@http/endpoints/promoCode';

const url = window.location.protocol + '//' + window.location.host.split(':')[0];

const isTest = false;
const Registration = ({ hide }: { hide: () => void }) => {
  const { showLoading } = useStoreState((state) => state);
  const { isAuthenticated } = useStoreState((state) => state.user);
  const { setLoading, setRegistrationFlow } = useStoreActions((actions) => actions);
  const { setAuthInfo, updateUser } = useStoreActions((actions) => actions.user);
  const {
    isNeedBack,
    activePage,
    setActivePage,
    nextPage,
    goBackPage,
    lastPage,
    formData,
  } = useContext(RegistrationContext);
  const { addToast } = useToasts();
  const location = useLocation();

  useQuery(
    ['deposit-prices-only'],
    getOnlyDepositPrices,
  );
  useQuery(
    ['svod-prices'],
    getOnlySvodPrices,
  );

  const Component = useMemo(() => {
    switch (activePage) {
      case RegistrationPages.VIP:
        return VipPlusPackages;
      case RegistrationPages.BONUS:
        return Bonus;
      case RegistrationPages.NO_OFFER:
        return NoOffer;
      case RegistrationPages.PROMO:
        return Promo;
      case RegistrationPages.BONUS_FINISH:
      case RegistrationPages.VIP_FINISH:
      case RegistrationPages.PROMO_FINISH:
        return SuccessPage;
      default:
        return PersonalInformation;
    }
  }, [activePage]);

  const isLogoNeed = useMemo(() => {
    switch (activePage) {
      case RegistrationPages.BONUS_FINISH:
      case RegistrationPages.VIP_FINISH:
      case RegistrationPages.PROMO_FINISH:
        return false;
      default:
        return true;
    }
  }, [activePage]);

  const schema = useMemo(() => {
    if (activePage === RegistrationPages.PERSONAL_INFO) return PersonalInformationSchema;
    if (activePage === RegistrationPages.VIP) return PersonalInformationSchema.concat(VipPlusPackagesSchema);
    if (activePage === RegistrationPages.BONUS) return PersonalInformationSchema.concat(BonusPackagesSchema);
    if (activePage === RegistrationPages.NO_OFFER) return PersonalInformationSchema;
    if (activePage === RegistrationPages.PROMO) return PersonalInformationSchema.concat(PromoPackagesSchema);
    return object({});
  }, [activePage]);

  const isSuccess = useMemo(() => {
    switch (activePage) {
      case RegistrationPages.VIP_FINISH:
      case RegistrationPages.PROMO_FINISH:
      case RegistrationPages.BONUS_FINISH:
        return true;
      default:
        return false;
    }
  }, [activePage]);

  const sendDataToPP = useCallback(async (_data: {
    amount: number,
    paymentMethod: string,
    redirectUrl: string,
    filter?: 'svod' | 'deposit',
    promoCode?: string
  }) => {
    const { paymentMethod, amount, redirectUrl, filter, promoCode } = _data;
    const { data, error } = await sendPaymentData({
      amount: amount,
      payment_method: paymentMethod,
      redirect_url: redirectUrl,
      filter: filter || 'deposit',
      is_registration: true,
      promoCode
    });
    if (error?.response?.data?.message) {
      setLoading(false);
      addToast(error.response.data.message, {
        appearance: 'warning'
      });
      return;
    }
    if (data) {
      const redirectUrl = data?.data?.data?.redirect_url;
      window.location.assign(redirectUrl ? redirectUrl : `${url}/`);
      setLoading(false);
      hide();
    } else {
      setLoading(false);
      /** do something if we don't have data */
    }

  }, [addToast, setLoading, hide]);


  const handleSubmit = useCallback(async (values: any, {
    errors,
    setErrors,
    setFieldTouched,
    setFieldValue,
    setFieldError
  }: any): Promise<void> => {
    if(activePage === RegistrationPages.PERSONAL_INFO) {
      if(!values?.isValid_email) {
        setLoading(true);
        const { data } = await checkUser(values.email);
        setLoading(false);
        if (data && data?.data?.data?.id) {
          setFieldError('email', 'emailAlreadyTaken', true);
          return;
        }
      }
    }

    if(activePage === RegistrationPages.PERSONAL_INFO && values?.promoCode) {
      if(!values?.isValid_promoCode) {
        setLoading(true);
        const { data } = await checkPromoCode(values.promoCode);
        setLoading(false);
        if (!data) {
          setFieldError('promoCode', 'invalidPromoCode', true);
          return;
        }
      }
    }

    if (nextPage) {
      if (nextPage === RegistrationPages.PROMO) {
        if (!values?.promoCode?.length) {
          setErrors({
            ...errors,
            'promoCode': 'pleaseEnterPromoCode',
          });
          setFieldTouched('promoCode', true, true);
          setFieldValue('promoCode', ' ');
          return;
        }
      }
      if (errors?.promoCode) {
        setErrors({
          ...errors,
          'promoCode': undefined,
        });
        setFieldTouched('promoCode', false, false);
      }
      setActivePage(nextPage);
      return;
    }
    try {
      setLoading(true);
      setRegistrationFlow(true);
      if (!isAuthenticated) {
        const clicker_id = readCookie('clicker_id') || null;
        const credentials = Object.assign({
          email: values.email,
          password: values.password,
          password_confirmation: values.password2,
          clicker_id: null || '',
          is_used_register_promo: activePage === RegistrationPages.NO_OFFER ? 1 : 0
        }, activePage === RegistrationPages.NO_OFFER ? {
          rejected_registration_promo_date: new Date()
        } : {});
        if (clicker_id) credentials.clicker_id = clicker_id;
        const { data, error } = await registerUser(credentials);
        if (error) {
          throw error;
        } else if (data) {
          if (data.data.status === 'success') {
            await setAuthInfo(data.data.data);
            if ([RegistrationPages.VIP, RegistrationPages.BONUS, RegistrationPages.PROMO].includes(activePage)) {
              localStorage.setItem(LOCAL_STORAGE.BONUS_MODAL_DATA, JSON.stringify({
                activePage,
                lastPage,
                isNeedBack,
                nextPage,
                formData: values,
                redirectURL: `${url}/`,
              }));
              localStorage.setItem(LOCAL_STORAGE.BONUS_MODAL_AUTO_OPEN, `${new Date().getTime()}`);
              if (isTest) {
                await sleep(4000);
                await testPayments({
                  promoCode: values.promoCode,
                });
                /** failed payment */
                window.location.href = 'http://localhost:3000/auth/payment-success?ans=NINVCVV2|1353899745&x_redirect_back=http://localhost:3000';
                /** success payment */
             //   window.location.href = 'http://localhost:3000/auth/payment-success?ans=Y|1353899745&x_redirect_back=http://localhost:3000';
                /** Success RG */
               // window.location.href='http://localhost:3000/success?flag=1900b64f6de&id=94725&hash=GJBJju67%2FG9cmaljBehqNdq4vqg%3D';
              } else {
                await sendDataToPP({
                  amount: values?.priceId || values.planId,
                  paymentMethod: values.paymentMethod,
                  redirectUrl: `${url}${location.pathname}`,
                  filter: activePage === RegistrationPages.VIP ? 'svod' : 'deposit',
                  promoCode: values?.promoCode
                });
              }
            } else {
              addToast(data.data.message, { appearance: 'success' });
              hide();
            }
            //if (redirectPath === 'svod') setShowDeposit(true);
            //hide();
          }
        }
      } else {
        if ([RegistrationPages.VIP, RegistrationPages.BONUS, RegistrationPages.PROMO].includes(activePage)) {
          localStorage.setItem(LOCAL_STORAGE.BONUS_MODAL_DATA, JSON.stringify({
            activePage,
            lastPage,
            isNeedBack,
            nextPage,
            formData: values,
            redirectURL: `${url}/`,
          }));
          localStorage.setItem(LOCAL_STORAGE.BONUS_MODAL_AUTO_OPEN, `${new Date().getTime()}`);
          if (isTest) {
            await sleep(4000);
            await testPayments({
              promoCode: values.promoCode,
            });
            await updateUser();
            /** failed payment */
            window.location.href = 'http://localhost:3000/auth/payment-success?ans=NINVCVV2|1353899745&x_redirect_back=http://localhost:3000';
            /** success payment */
            //window.location.href = 'http://localhost:3000/auth/payment-success?ans=Y|1353899745&x_redirect_back=http://localhost:3000';
            /** Success RG */
           // window.location.href='http://localhost:3000/success?flag=1900b64f6de&id=94725&hash=GJBJju67%2FG9cmaljBehqNdq4vqg%3D';
          } else {
            await sendDataToPP({
              amount: values?.priceId || values.planId,
              paymentMethod: values.paymentMethod,
              redirectUrl: `${url}${location.pathname}`,
              filter: activePage === RegistrationPages.VIP ? 'svod' : 'deposit',
              promoCode: values?.promoCode
            });
          }
        }
      }
    } catch (error: any) {
      if (error.response?.data?.message) {
        let msg = error.response.data.message;
        if(error?.response?.data?.errors) {
          msg =  (()=> {
            const key = Object.keys(error?.response?.data?.errors)?.[0];
            if(key) {
             return error?.response?.data?.errors?.[key]?.[0] || error.response.data.message;
            }
            return msg;
          })();
        }
        addToast(msg, {
          appearance: 'warning',
        });
      }
      removeLSItem(LOCAL_STORAGE.BONUS_MODAL_AUTO_OPEN);
      removeLSItem(LOCAL_STORAGE.BONUS_MODAL_DATA);
    } finally {
      setLoading(false);
    }
  }, [setRegistrationFlow, nextPage, setActivePage, setLoading, isAuthenticated, activePage, setAuthInfo, lastPage, isNeedBack, sendDataToPP, location.pathname, updateUser, addToast]);

  return (
    <Formik
      initialValues={{
        ...(!isTest ? {
          email: '',
          password: '',
          password2: '',
          termsConditions: false,
          promoCode: '',
          planId: '',
          priceId: '',
          paymentMethod: '',
        } : {
          email: 'stefan.milutinovic.rs+test705@gmail.com',
          password: 'Test123!',
          password2: 'Test123!',
          termsConditions: true,
          promoCode: '',
          planId: '',
          priceId: '',
          paymentMethod: '',
        }),
        ...(formData || {}),
      }}
      validationSchema={schema}
      validateOnBlur={false}
      onSubmit={handleSubmit}
    >
      {({ values,  setValues }) => {
        const handleGoBack = async () => {
          await setValues(Object.assign({
              ...values,
            },
            activePage === RegistrationPages.VIP ? {
              planId: undefined,
              paymentMethod: undefined,
              selectedPackages: undefined,
            } : {},
            activePage === RegistrationPages.BONUS ? {
              priceId: undefined,
              paymentMethod: undefined,
              promoCode: undefined,
              selectedPackages: undefined,
            } : {},
            activePage === RegistrationPages.PROMO ? {
              promoCode: undefined,
              priceId: undefined,
              paymentMethod: undefined,
              selectedPackages: undefined,
            } : {},
          ), false);
          goBackPage();
        };

        return (
          <div className={'wizard-form-container gap-6'}>
            {isNeedBack && !isSuccess &&
              <FormButton bgColor={''} textColor={'black'} className={'registration-back-button rounded'}
                          disabled={showLoading} icon={IconArrowBack} handleClick={handleGoBack} />}
            {isLogoNeed && <div
              className={`mx-auto lg:static transition-all duration-300 ease-in-out`}
            >
              <img className={`logo-site pt-2 xxl:pt-0`} src={Logo} alt="site logo" />
            </div>}
            <div className={'registration-form-container'}>
              <Form className={'registration-form'}>
                <Component hide={hide} />
              </Form>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

export default Registration;