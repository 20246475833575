import React, { FC } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MovieByIdModal } from '../../../../types';
import styles from './MoviePagePlayer.module.scss';
import { VideoPlayer } from '@common/video-player';

type Props = {
  data: MovieByIdModal;
};

const MoviePagePlayer: FC<Props> = ({ data }) => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <>
      <div
        onKeyDown={() => history.push(`/movie/${data.id}`)}
        role="button"
        tabIndex={0}
        className={`${styles.title} text-[38px] cursor-pointer work-sans-font font-bold mt-4 md:mt-0 opacity-75 leading-[27px]`}
        onClick={() => history.push(`/movie/${data.id}`)}
      >
        {data.title}
      </div>
      <div className={styles.studio_time_container}>
        <Link
          to={`/movies/studio/${data.studio?.id}/${encodeURI(data.studio?.name)}`}
          className={styles.studio_container}
        >
          <p>Studios:</p>
          <span
            className={`${styles.studio_title} text-mainRed font-semibold text-small hover:opacity-75 select-none text-2xl`}
          >
            {data.studio?.name?.toUpperCase()}
          </span>
        </Link>
        <p className={styles.time}>
          <i className="far fa-clock pr-1" title={t('Playtime')} /> {data.duration && data.duration} {t('Minutes')}
        </p>
      </div>
      {data?.trailer && (
        <div className="w-full mb-2 ">
          <VideoPlayer
            src={data?.trailer || ''}
            controls={true}
            autoplay={false}
            trailerThumb={data?.trailer_thumbnail || ''}
            fromTrailer={true}
          />
        </div>
      )}
    </>
  );
};

export default MoviePagePlayer;
