import { Field, FieldProps } from 'formik';
import React from 'react';
import Translation from '@common/translation';


const FormikCheckBox = ({ className, disabled, placeholder, name, children, useHelper = true, showBorder = true, align = 'center'  }: any) => {

  return (
    <Field name={name}>
      {({ field, meta }: FieldProps) => {
        const isError = meta.error && meta.touched;

        return (
          <div className={`mt-2 relative flex flex-col p-2${isError ? ' text-red-500 rounded-lg' : ''}${showBorder ? ' border border-red-500' : ''}`}>
            <div className={`flex items-${align} gap-3 ${isError ? ' text-red-500:!important' : ''}`}>
              <input
                type="checkbox"
                placeholder={placeholder}
                checked={field.value}
                className={`${className} w-5 h-5 rounded${isError ? ' text-red-500 bg-gray-100 border-red-500 rounded focus:ring-red-500 dark:focus:ring-red-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600' : ' text-primary-600 border-gray-300'}${disabled ? ' cursor-not-allowed': ''}`}
                disabled={disabled}
                {...field}
              />
              {children}
            </div>
            {useHelper && isError ? <Translation use={meta.error} as={'div'} className="min-h-24 min-w-full text-xs text-red-500 text-left font-light font-semibold" /> : <div className="min-h-24 min-w-full text-xs text-red-500 text-left font-light font-semibold">&nbsp;</div> }
          </div>
        );
      }}
    </Field>
  );
};


export default FormikCheckBox;