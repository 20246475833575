import React, { FC, forwardRef, memo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import CoverFlags from '../CoverFlags';
import { ListItemModel } from './types';
import PlayIcon from '@images/red-play-icon.png';
import GermanAudio from '@images/DE-BU-icon.png';
//new icons en/de
import NeuIcon from '@images/NEU-BU-icon.png';
import NewIcon from '@images/NEW-BU-icon.png';
//reduced icons en/de
import ReducedIcon from '@images/DISCOUNT-BU-icon.png';
import ReduziertIcon from '@images/RABATT-BU-icon.png';
import VipFlag from '@images/FREE VIP-BU-icon.png';
import VipDeFlag from '@images/GRATIS VIP-BU-icon.png';
import Top100DeIcon from '@images/TOP100FILM-BU-icon.png';
import Top100Icon from '@images/TOP100-BU-icon.png';
//reduced+German icons en/de
import ReducedGermanIcon from '@images/reduced+german-icon.png';
import ReduziertGermanIcon from '@images/reduziert+german-icon.png';
import './ListItem.module.scss';

export const iconChange = {
  newIcon: { en: NewIcon, de: NeuIcon },
  redIcon: { en: ReducedIcon, de: ReduziertIcon },
  redGerIcon: { en: ReducedGermanIcon, de: ReduziertGermanIcon },
  vipIcon: { en: VipFlag, de: VipDeFlag },
  topIcon: { en: Top100Icon, de: Top100DeIcon }
};

type Props = {
  ref?: any
  data: ListItemModel;
  handleSendStatistics?: (id: number, name: string) => void;
  customHandler?: (event: any) => void;
  fromPromotion?: boolean;
  darkMode?: boolean;
  hideInfo?: boolean;
  hidePrice?: boolean;
  oneRow?: boolean;
  isSvod?: boolean;
};
// eslint-disable-next-line react/display-name
const ListItem = forwardRef<any, Props>(({
  data,
  handleSendStatistics,
  fromPromotion,
  darkMode,
  hideInfo,
  hidePrice,
  oneRow,
  isSvod,
  customHandler,
}, ref) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const handleClick = (e: any) => {
    if (handleSendStatistics) handleSendStatistics(data.id, 'movies');
    if(customHandler) {
      customHandler(data.id);
    }
    history.push(`/movie/${data.id}/${data.title.replace(/ /g, '-').replace(/[^\w-]+/g, '')}`);
  };

  return (
    <div
      ref={ref}
      onKeyDown={handleClick}
      role="button"
      tabIndex={0}
      onClick={handleClick}
      className={`poster-list-item cursor-pointer relative ${hideInfo ? 'mb-2' : hidePrice ? 'mb-16' : 'mb-16'} ${
        fromPromotion ? 'poster-list-promotion' : ''
      }`}
      id={`id-${data.id}`}
    >
      <div className={'hover-effect-container'}>
        <div className={'hover-effect'}></div>
        <div className={'play-icon'}>
          <div className="play-icon-container">
            <LazyLoadImage src={PlayIcon} alt="play button icon" className="w-full" />
          </div>
        </div>
        <div className="poster-item-title font-semibold">
          {data.title || ''}
        </div>
      </div>
        <div>
          <picture
            className={`cover-image poster-icon w-full duration-200 ${fromPromotion ? 'overflow-hidden' : ''}`}
            onLoad={() => setIsLoaded(true)}
          >
            <source
              media="(max-width: 640px)"
              srcSet={
                data?.thumbnails?.large
                  ? data.thumbnails.large
                  : data?.thumbnails?.original
                  ? data.thumbnails.original
                  : ''
              }
            />
            <source
              media="(min-width: 641px) and (max-width: 1023px)"
              srcSet={
                data?.thumbnails?.large
                  ? data.thumbnails.large
                  : data?.thumbnails?.original
                  ? data.thumbnails.original
                  : ''
              }
            />
            <source
              media="(min-width: 1024px)"
              srcSet={
                data?.thumbnails?.large
                  ? data.thumbnails.large
                  : data?.thumbnails?.original
                  ? data.thumbnails.original
                  : ''
              }
            />
            <img
              src={data?.thumbnails?.original || ''}
             // style={{ width: '185px', height: '275px' }}
              className="poster-icon"
              alt={data.title}
            />
          </picture>
        </div>
       {/* {isLoaded ? (
          <div className="play-icon-container">
            <img src={PlayIcon} alt="play button icon" className="w-full" />
          </div>
        ) : null}*/}
      {/*  {data.needs_icon && isLoaded ? (
          <div className="german-audio">
            <img src={GermanAudio} alt="german audio icon" className="w-full" />
          </div>
        ) : null}*/}
      <CoverFlags data={data} isLoaded={isLoaded} />
       {/* {data.new && isLoaded ? (
          <div className="new-icon">
            <img
              src={iconChange.newIcon[i18n.language as keyof (typeof iconChange)['newIcon']]}
              alt="new icon"
              className="w-full"
            />
          </div>
        ) : null}
        {!data.new && data.reduced && isLoaded ? (
          <div className="reduced-icon">
            <img
              src={iconChange.redIcon[i18n.language as keyof (typeof iconChange)['redIcon']]}
              alt="reduced movie"
              className="w-full"
            />
          </div>
        ) : null}
        <div className={'audio-vip-top100-section'}>
          {data.is_svod && isLoaded ? (
            <div className="flag">
              <img
                src={iconChange.vipIcon[i18n.language as keyof (typeof iconChange)['vipIcon']]}
                alt="vip free icon"
                className="w-full" />
            </div>
          ) : null}
          {data.needs_icon && isLoaded ? (
            <div className="flag">
              <img src={GermanAudio} alt="german audio icon" className="w-full" />
            </div>
          ) : null}
          {data.is_top100 && isLoaded ? (
            <div className="flag">
              <img
                src={iconChange.topIcon[i18n.language as keyof (typeof iconChange)['topIcon']]}
                alt="top 100 movie icon"
                className="w-full" />
            </div>
          ) : null}
        </div>*/}
        {/*{data.needs_icon && data.reduced && isLoaded ? (
          <div className="reduced-icon-german">
            <img
              src={iconChange.redGerIcon[i18n.language as keyof (typeof iconChange)['redGerIcon']]}
              alt="reduced german movie"
              className="w-full"
            />
          </div>
        ) : null}*/}
{/*      {data?.title && isLoaded && !hideInfo ? (
        <div className="poster-item-bottom-info leading-3 pt-1">
          <Link to={`/movie/${data.id}/${data.title.replace(/ /g, '-').replace(/[^\w-]+/g, '')}`} title={data.title}>
            <span className={`work-sans-font text-mainRed ${oneRow && 'truncate'}`} title={data.title}>
              {data?.title || ''}
            </span>
          </Link>

          {(data?.stream_price || data?.stream_price === 0) && !hidePrice ? (
            <span
              onKeyDown={() =>
                history.push(`/movie/${data.id}/${data.title.replace(/ /g, '-').replace(/[^\w-]+/g, '')}`)
              }
              role="button"
              tabIndex={0}
              className={`work-sans-font text-md mt-1 ${darkMode ? 'text-white' : 'text-black'}`}
              onClick={() => history.push(`/movie/${data.id}/${data.title.replace(/ /g, '-').replace(/[^\w-]+/g, '')}`)}
            >
              {isSvod
                ? t('Include')
                : `${t('Rent')}: ${
                    data.stream_price === 0 ? t('FREE') : data?.stream_price.toFixed(2).replace('.', ',')
                  } €`}
            </span>
          ) : null}
        </div>
      ) : (
        ''
      )}*/}
    </div>
  );
});

export default memo(ListItem);
