import React from 'react';
import type { SVGProps } from 'react';


export  function IconCrownFill(props: SVGProps<SVGSVGElement>) {
  return (<svg xmlns="http://www.w3.org/2000/svg" width="29px" height="29px" viewBox="0 0 24 24" {...props}><path fill="currentColor" d="M2.005 19h20v2h-20zm0-14l5 3l5-6l5 6l5-3v12h-20z"></path></svg>);
}

export function IconPercent(props: SVGProps<SVGSVGElement>) {
  return (<svg xmlns="http://www.w3.org/2000/svg" width="29px" height="29px" viewBox="0 0 24 24" {...props}><path fill="currentColor" d="M7.5 11q-1.45 0-2.475-1.025T4 7.5t1.025-2.475T7.5 4t2.475 1.025T11 7.5T9.975 9.975T7.5 11m0-2q.625 0 1.063-.437T9 7.5t-.437-1.062T7.5 6t-1.062.438T6 7.5t.438 1.063T7.5 9m9 11q-1.45 0-2.475-1.025T13 16.5t1.025-2.475T16.5 13t2.475 1.025T20 16.5t-1.025 2.475T16.5 20m0-2q.625 0 1.063-.437T18 16.5t-.437-1.062T16.5 15t-1.062.438T15 16.5t.438 1.063T16.5 18M5.4 20L4 18.6L18.6 4L20 5.4z"></path></svg>);
}

export function IconCarbonContinue(props: SVGProps<SVGSVGElement>) {
  return (<svg xmlns="http://www.w3.org/2000/svg" width="29px" height="29px" viewBox="0 0 32 32" {...props}><path fill="currentColor" d="M10 28a1 1 0 0 1-1-1V5a1 1 0 0 1 1.501-.865l19 11a1 1 0 0 1 0 1.73l-19 11A1 1 0 0 1 10 28m1-21.266v18.532L27 16zM4 4h2v24H4z"></path></svg>);
}
export function IconRiCoupon4Line(props: SVGProps<SVGSVGElement>) {
  return (<svg xmlns="http://www.w3.org/2000/svg" width="29px" height="29px" viewBox="0 0 24 24" {...props}><path fill="currentColor" d="M10.005 21h-7a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h7a2 2 0 1 0 4 0h7a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1h-7a2 2 0 1 0-4 0M8.54 19a4 4 0 0 1 3.465-2c1.48 0 2.773.804 3.465 2h4.535V5H15.47a4 4 0 0 1-3.465 2A4 4 0 0 1 8.54 5H4.005v14zM6.005 8h2v8h-2zm10 0h2v8h-2z"></path></svg>);
}

export function IconArrowBack (props: SVGProps<SVGSVGElement>) {
  return (<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}><path fill="currentColor" d="m6.921 12.5l5.793 5.792L12 19l-7-7l7-7l.714.708L6.92 11.5H19v1z"></path></svg>);
}