import { action, persist } from 'easy-peasy';
import Cookies from 'js-cookie';
import { StoreModel } from './types';
import userModel from './userModel';
import { COOKIES } from '@common/modals/subscribe/types';
import moviesModel from '@store/model/moviesModel';
import sceneModel from '@store/model/sceneModel';
import studiosModel from '@store/model/studiosModel';
import searchModel from '@store/model/searchModel';
import starsModel from '@store/model/starsModel';

const storeModel: StoreModel = {
  user: userModel,
  moviesModel: moviesModel,
  scenesModel: sceneModel,
  studiosModel: studiosModel,
  searchModel: searchModel,
  starsModel: starsModel,
  showRegisterBonus: false,
  showLogin: false,
  showDepositModal: false,
  showSubscriptionModal: false,
  showNewsletterModal: false,
  isNeedSubscription: !!(Cookies.get(COOKIES.BUM_SUBSCRIBED)),
  agreement: persist({ showAgreement: false }, { storage: 'sessionStorage' }),
  showPhoneVerify: {},
  showMessageModal: false,
  selectedPlan: {},
  depositModalData: {},
  redirectPath: null,
  redirectURL: null,
  showLoading: false,
  isRegistrationFlow: false,
  setShowLogin: action((state, payload) => {
    state.showLogin = payload;
  }),
    setNeedSubscription: action((state, payload) => {
    state.isNeedSubscription = payload;
  }),
  setShowDeposit: action((state, payload) => {
    state.showDepositModal = payload;
  }),
  setShowMessageModal: action((state, payload) => {
    state.showMessageModal = payload;
  }),
  setShowNewsletterModal: action((state, payload) => {
    state.showNewsletterModal = payload;
  }),
  setShowAgreement: action((state, payload) => {
    state.agreement.showAgreement = payload;
  }),
  setDepositModalData: action((state, payload) => {
    state.depositModalData = payload;
  }),
  setSelectedPlan: action((state, payload) => {
    state.selectedPlan = payload;
  }),
  setShowPhoneVerify: action((state, payload) => {
    state.showPhoneVerify = payload;
  }),
  setShowSubscription: action((state, payload) => {
    state.showSubscriptionModal = payload;
  }),
  setRedirectPath: action((state, payload) => {
    state.redirectPath = payload;
  }),
  setRedirectURL: action((state, payload) => {
    state.redirectURL = payload;
  }),
  setLoading: action((state, payload) => {
    state.showLoading = payload;
  }),
  setShowRegisterBonus: action((state, payload) => {
    state.showRegisterBonus = payload;
  }),
  setRegistrationFlow: action((state, payload) => {
    state.isRegistrationFlow = payload;
  }),
};

export default storeModel;
