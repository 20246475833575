import React from 'react';

const Spinner = ({ show }) => {
  return (
    <div
      id="spinner"
      className={`w-screen h-screen top-0 left-0 fixed flex justify-center content-center items-center ${
        show ? 'block' : 'hidden'
      }`}
    >
      <div className="spinner">
        <div className="dot1"></div>
        <div className="dot2"></div>
      </div>
    </div>
  );
};

export default Spinner;
