/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useCallback, useState } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useToasts } from 'react-toast-notifications';
import { object, string } from 'yup';
import { useTranslation } from 'react-i18next';
import ErrorText from '../../common/ErrorMssg';
import FormButton from '@common/ui-components/buttons/FormButton';
import FormInput from '@common/ui-components/input-fields/FormInput';
import { useSendGift } from '@hooks';
import { loginUser } from '@http/endpoints/user';
import { useStoreActions, useStoreState } from '@store';
import FormikInputPassword from '@common/ui-components/input-fields/FormikInputPassword';
import FormikInput from '@common/ui-components/input-fields/FormikInput';

type EmailPassword = {
  email: string;
  password: string;
};
type Props = {
  hide: () => void;
  handleResetShow: () => void;
};
const LoginForm: FC<Props> = ({ hide, handleResetShow }) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const { showLoading, redirectPath } = useStoreState((state) => state);
  const { setAuthInfo, setUser } = useStoreActions((actions) => actions.user);
  const {
    setShowDeposit,
    setLoading,
    setShowRegisterBonus,
    setRegistrationFlow,
  } = useStoreActions((actions) => actions);
  const { giftCode } = useStoreState((state) => state.user.session);
  const { claimGift } = useSendGift();

  const RegisterSchema = object().shape({
    email: string().required(t('E-mail address is required and cannot be empty')),
    password: string().min(4, t('Password too short')).required(t('Password is required')),
  });

  const initialValues: EmailPassword = {
    email: '',
    password: '',
  };

  const handleSubmit = async (values: EmailPassword): Promise<void> => {
    setLoading(true);
    const { data, error } = await loginUser(values);
    if (error) {
      setLoading(false);
      addToast(error.response.data.message, {
        appearance: 'warning',
      });
    } else if (data) {
      setLoading(false);
      if (data.data.status === 'success') {
        const payload = {
          token: data.data.data?.token,
          refresh_token: data?.data?.data?.refresh_token,
        } as any;
        hide();
        await setAuthInfo(payload);
        setUser({
          ...data.data.data.user.user,
          ...data.data.data.user?.user_info,
        });
        if (!data.data.data.user?.user_info?.is_used_register_promo) {
          setRegistrationFlow(false);
          setShowRegisterBonus(true);
        }
        // addToast(data.data.message, { appearance: "success" });
        if (redirectPath === 'svod') setShowDeposit(true);

        if (giftCode) {
          claimGift(giftCode);
        }

      }
    }
  };

  const handleReset = useCallback(() => {
    if (showLoading) return;
    handleResetShow();
  }, [handleResetShow, showLoading]);

  return (
    <div className="w-full pt-10">
      <Formik
        initialValues={initialValues}
        validationSchema={RegisterSchema}
        validateOnBlur={false}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {() => {
          return (
            <Form className={'flex flex-col'}>
              <FormikInput
                name="email"
                placeholder={`E-Mail ${t('or')} ${t('Username')}`}
                disabled={showLoading}
              />
              <FormikInputPassword
                name="password"
                placeholder="Password"
                disabled={showLoading}
              />
              <p className="text-sm font-light text-black text-left opacity-75 py-1">
                <span
                  onKeyDown={handleReset}
                  role="button"
                  tabIndex={0}
                  className={`underline font-normal cursor-pointer${showLoading ? ' cursor-not-allowed' : ''}`}
                  onClick={handleReset}
                >
                  {t('Forgot Password')}?
                </span>
              </p>
              <FormButton text="Login" textColor="black" bgColor="mainYellow" type="submit" loading={showLoading} />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default LoginForm;
