

export enum RegistrationPages {
  PERSONAL_INFO = 0x1,
  VIP = 0x2,
  VIP_FINISH= 0x4,
  BONUS = 0x8,
  BONUS_FINISH = 0x10,
  PROMO = 0x11,
  PROMO_FINISH=0x12,
  NO_OFFER = 0x13,
  LOGIN = 0x14,
  RESET=0x15,
  ERROR_PAGE=0x16,
  REGISTER_PROMO_CODE_PICKER= 0x17
}

export type TRegistrationReducer = {
  activePage: RegistrationPages
  nextPage?: RegistrationPages
  lastPage?: RegistrationPages
  isNeedBack?: boolean
  formData?: any
  errorData?: any
}



export enum REGISTRATION_EVENTS {
  SET_ACTIVE_PAGE = 'SET_ACTIVE_PAGE',
  SET_NEXT_PAGE = 'SET_NEXT_PAGE',
  CLEAR_NEXT_PAGE = 'CLEAR_NEXT_PAGE',
  GO_BACK = 'GO_BACK'
}


export type TRegistrationActions = {
  type: REGISTRATION_EVENTS,
  payload?: any
}

export const initialRegistrationData = {
  activePage: RegistrationPages.LOGIN,
};