import { FC, lazy } from 'react';
import { Link } from 'react-router-dom';
import Logo from '@images/BU-logo/resize/BUM-logo.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// Components
const SafeIcons = lazy(() => import('./components/SafeIcons'));
const FooterLinks = lazy(() => import('./components/FooterLinks'));
const PaymentIcons = lazy(() => import('./components/PaymentIcons'));

const Footer: FC = () => {
  return (
    <footer className="w-full left-0 pt-10 pb-0 text-center z-0">
      <div className={`flex w-full justify-center mb-8`}>
        <Link to="/">
          <LazyLoadImage className="w-1/2 sm:w-1/2 md:w-2/6 xl:w-1/5 mx-auto" src={Logo} alt="site logo" />
          {/*<img loading="lazy" className="w-1/2 sm:w-1/2 md:w-2/6 xl:w-1/5 mx-auto" src={Logo} alt="site logo" />*/}
        </Link>
      </div>
      <SafeIcons />
      <FooterLinks />
      <Link to="/2257" className="text-lightDark text-center inline-block mx-auto hover:opacity-75">
        18 U.S.C. 2257 Record-Keeping Requirements Compliance Statement
      </Link>
      <PaymentIcons />
    </footer>
  );
};
export default Footer;
