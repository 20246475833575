import React, { useCallback, useEffect, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useFormikContext } from 'formik';
import Skeleton from 'react-loading-skeleton';
import style from '../Bonus.module.scss';
import { getDepositPricesPromoCode, getOnlyDepositPrices } from '@http/endpoints/payments';
import Translation from '@common/translation';
import DepositPrice from '@common/modals/register/components/bonus/prices/DepositPrice';
import PromoPlanPrice from '@common/modals/register/components/promo/Prices/PromoPlanPrice';


const DepositPackages = () => {
  const { values, errors, touched, setFieldValue } = useFormikContext();
  const { priceId, promoCode } = values as any;



  const { data, isLoading, isFetched } = useQuery(
    [`deposit-prices-promo-${promoCode}`],
    () => getDepositPricesPromoCode(promoCode),
    {
      enabled: !!promoCode
    }
  );
  const loading = useMemo(() => isLoading && !isFetched, [isLoading, isFetched]);
  const packages = useMemo(() => data?.data || [], [data]);
  
  const handleSelectPrice = useCallback((value: number) => {
    setFieldValue('priceId', value, false).then();
    setFieldValue('selectedPackages', packages?.find((x: any)=> x.id === value) || null, false).then();
  }, [setFieldValue, packages]);

  useEffect(() => {
    if(!packages?.length || (values as any)?.priceId) return;
    const pack = packages?.find((x: any)=> x.price == 25);
    setFieldValue('priceId',pack?.id, false).then();
    setFieldValue('selectedPackages', pack || null, false).then();
  }, [packages, values]);


  const [isError, error, _touched] = useMemo(() => [
    Boolean((errors as any)?.priceId),
    (errors as any)?.priceId,
    (touched as any)?.priceId
  ], [errors, touched]);

  return (
    <div className={'flex flex-col flex-1 gap-5 relative'}>
      <Translation use={'selectPackage'} as={'p'} className={'text-md font-normal'} />
      <div className={'flex flex-col gap-2'}>
        {!loading && packages?.length ? packages.map((plan: any, index: number) =>
            <PromoPlanPrice
              handleSelectPrice={handleSelectPrice}
              data={plan}
              key={index}
              currentPrice={priceId}
            />)
          : [...Array(5).keys()].map((x) => (<div key={x + 1} className={`${style.bonusPackagesSkeletonDiv}`}><Skeleton
            className={'w-full h-full'} /></div>))}
      </div>
      {isError && _touched && <Translation use={error} as={'div'}  className={`${style.bonusErrorShow} text-red-600 text-sm`} />}
    </div>
  );
};

export default DepositPackages;