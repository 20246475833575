import { FC, useState } from 'react';

type Props = {
  item: number;
  type: string;
  handleRate: (value: number) => void;
};
const Star: FC<Props> = ({ item, type, handleRate }) => {
  const [isHover, setIsHover] = useState<boolean>(false);
  return (
    <i
      onKeyDown={() => handleRate(item)}
      role="button"
      tabIndex={0}
      key={item}
      className={`${
        isHover ? 'fas fa-star text-mainYellow' : `${type} text-mainRed`
      }  mx-3 inline-block cursor-pointer`}
      onClick={() => handleRate(item)}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    />
  );
};

export default Star;
