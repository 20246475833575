import { PropsWithChildren, useCallback, useMemo, useReducer, useRef } from 'react';
import {
  initialRegistrationData,
  REGISTRATION_EVENTS,
  RegistrationPages,
  TRegistrationActions,
  TRegistrationReducer,
} from '@common/modals/register/context/d';
import RegistrationContext from '@common/modals/register/context/context';


const reducer = (state: TRegistrationReducer, action: TRegistrationActions) => {
  switch (action.type) {
    default:
      return state;

    case REGISTRATION_EVENTS.SET_ACTIVE_PAGE: {
      
      const isNeedBack = [RegistrationPages.VIP, RegistrationPages.PROMO, RegistrationPages.BONUS, RegistrationPages.NO_OFFER].includes(action.payload);
      
      return {
        ...state,
        lastPage: state.activePage || RegistrationPages.PERSONAL_INFO,
        activePage: action.payload,
        isNeedBack
      };
    }

    case REGISTRATION_EVENTS.SET_NEXT_PAGE: {
      return {
        ...state,
        lastPage: state.activePage || RegistrationPages.PERSONAL_INFO,
        nextPage: action.payload
      };
    }


    case REGISTRATION_EVENTS.CLEAR_NEXT_PAGE: {
      return {
        ...state,
        lastPage: RegistrationPages.PERSONAL_INFO,
        nextPage: undefined
      };
    }
    
    case REGISTRATION_EVENTS.GO_BACK: {
      const {activePage, lastPage} = state;
      if(activePage === RegistrationPages.PERSONAL_INFO) return state;
      let newActivePage = lastPage || RegistrationPages.PERSONAL_INFO;
      if(!lastPage) {
        switch(activePage) {
          case RegistrationPages.VIP:
          case RegistrationPages.BONUS:
          case RegistrationPages.PROMO:
          case RegistrationPages.NO_OFFER:
            newActivePage = RegistrationPages.PERSONAL_INFO;
        }
      }
      const isNeedBack = [RegistrationPages.VIP, RegistrationPages.PROMO, RegistrationPages.BONUS, RegistrationPages.NO_OFFER].includes(newActivePage);

      return {
        ...state,
        lastPage: RegistrationPages.PERSONAL_INFO,
        activePage: newActivePage,
        isNeedBack,
        errorData: undefined
      };
    }

  }
};


export const useRegistrationContext = (entryData = {}) => {
  const dataReduceState = useRef({
    ...initialRegistrationData,
    ...entryData,
  });

  const [state, dispatch] = useReducer(reducer, dataReduceState.current as TRegistrationReducer);


  const setActivePage = useCallback((payload: RegistrationPages) => dispatch({
    type: REGISTRATION_EVENTS.SET_ACTIVE_PAGE,
    payload,
  }), [dispatch]);

  const setNextPage = useCallback((payload: RegistrationPages) => dispatch({
    type: REGISTRATION_EVENTS.SET_NEXT_PAGE,
    payload,
  }), [dispatch]);

  const goBackPage = useCallback(()=> dispatch({
    type: REGISTRATION_EVENTS.GO_BACK
  }), [dispatch]);

  const clearNextPage = useCallback(()=> dispatch({
    type: REGISTRATION_EVENTS.CLEAR_NEXT_PAGE
  }), [dispatch]);

  const setLogin = useCallback(()=> dispatch({
    type: REGISTRATION_EVENTS.SET_ACTIVE_PAGE,
    payload: RegistrationPages.LOGIN
  }), [dispatch]);

  const setReset = useCallback(()=> dispatch({
    type: REGISTRATION_EVENTS.SET_ACTIVE_PAGE,
    payload: RegistrationPages.RESET
  }), [dispatch]);

  const setRegister = useCallback(()=> dispatch({
    type: REGISTRATION_EVENTS.SET_ACTIVE_PAGE,
    payload: RegistrationPages.PERSONAL_INFO
  }), [dispatch]);

  return useMemo(() => ({
    ...state,
    isLogin: state.activePage === RegistrationPages.LOGIN,
    isReset: state.activePage === RegistrationPages.RESET,
    isRegPromo: state.activePage === RegistrationPages.REGISTER_PROMO_CODE_PICKER,
    setActivePage,
    setNextPage,
    goBackPage,
    clearNextPage,
    setRegister,
    setLogin,
    setReset,
  }), [state, setActivePage, setNextPage, goBackPage, clearNextPage, setRegister, setLogin,setReset]);

};


export type TRegistrationContextType = ReturnType<typeof useRegistrationContext>;

export type TRegistrationContextProps = {
  entryData?: TRegistrationReducer
}

const RegistrationContextContainer = ({ entryData = {} as TRegistrationReducer, children }: PropsWithChildren<TRegistrationContextProps>) => {

  const providerData = useRegistrationContext(entryData);

  return (
    <RegistrationContext.Provider value={providerData}>
      {children}
    </RegistrationContext.Provider>
  );
};

export default RegistrationContextContainer;