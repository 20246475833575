import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-use';
import { useAddWishlistMutation, useRemoveWishlistMutation } from '../../mutation';
import { MovieByIdModal } from '../../../../types';
import { SceneList } from '../SceneList';
import MovieFlags from '../../../MovieFlags';
import styles from './MoviePagePoster.module.scss';
import { FavoriteButton } from '@common/ui-components/buttons/FavoriteButton';
import { useStoreState } from '@store';
import { LazyLoadImage } from 'react-lazy-load-image-component';

type Props = {
  movieById: MovieByIdModal;
};

const MoviePagePoster: FC<Props> = ({ movieById }) => {
  const { t } = useTranslation();
  const { isAuthenticated } = useStoreState((state) => state.user);
  const { addWishlistMutation } = useAddWishlistMutation(movieById.id);
  const { removeWishlistMutation } = useRemoveWishlistMutation(movieById.id);
  const isDesktop = useMedia('(min-width: 1131px)');

  const handleToFavorites = () => {
    addWishlistMutation();
  };

  const handleRemoveFavorite = () => {
    removeWishlistMutation();
  };

  return (
    <div className={`${styles.movie_page_poster} w-full md:pr-10 mb-5`}>
      <div className={'w-full relative'}>
        <picture className={` ${styles.border_radius}`}>
          <source
            media="(max-width: 640px)"
            srcSet={
              movieById?.thumbnails?.large
                ? movieById.thumbnails.large
                : movieById?.thumbnails?.original
                  ? movieById.thumbnails.original
                  : ''
            }
          />
          <source
            media="(min-width: 641px) and (max-width: 1023px)"
            srcSet={
              movieById?.thumbnails?.large
                ? movieById.thumbnails.large
                : movieById?.thumbnails?.original
                  ? movieById.thumbnails.original
                  : ''
            }
          />
          <source
            media="(min-width: 1024px)"
            srcSet={
              movieById?.thumbnails?.large
                ? movieById.thumbnails.large
                : movieById?.thumbnails?.original
                  ? movieById.thumbnails.original
                  : ''
            }
          />
          <LazyLoadImage
            src={movieById?.thumbnails?.original ? movieById.thumbnails.original : ''}
            className={`w-full ${styles.border_radius}`}
            alt="movie poster"
          />
        </picture>
        <MovieFlags {...movieById} />
        <div className={styles.wishlist}>
          {isAuthenticated ? (
            <div className="w-full">
              {movieById.is_favorited ? (
                <FavoriteButton handleClick={handleRemoveFavorite} text={t('Remove from Wishlist')} />
              ) : (
                <FavoriteButton active handleClick={handleToFavorites} text={t('Add to Wishlist')} />
              )}
            </div>
          ) : null}
        </div>
      </div>

      {isDesktop && <SceneList movieById={movieById} />}
    </div>
  );
};

export default MoviePagePoster;
