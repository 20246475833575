import http from '@http';
import toResponse from '@helpers/toResponse/toResponse';

export const getSubscriptions = async () => {
  const { data } = await http.get(`/user/subscription`);
  return data;
};

// subscribe user to email list for free movies
export const subscribeUser = (values) => toResponse(http.post(`/email/sign-ups`, values));

export const cancelSubscription = (values) => toResponse(http.post(`/cancel-subscription`, values));
