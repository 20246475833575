/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useState } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { object, string } from 'yup';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import ErrorText from '../../common/ErrorMssg';
import FormButton from '@common/ui-components/buttons/FormButton';
import FormInput from '@common/ui-components/input-fields/FormInput';
import { passwordResetMail } from '@http/endpoints/user';
import FormikInput from '@common/ui-components/input-fields/FormikInput';

type Email = {
  email: string;
};

type Props = {
  hide: () => void;
};
const RegisterForm: FC<Props> = ({ hide }) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const RegisterSchema = object().shape({
    email: string()
      .email(t('E-mail address is not valid'))
      .required(t('E-mail address is required and cannot be empty')),
  });

  const initialValues: Email = {
    email: '',
  };

  const handleSubmit = async (values: Email) => {
    setIsLoading(true);
    const { data, error } = await passwordResetMail(values);
    if (error) {
      setIsLoading(false);
      if (error?.response?.data?.message) addToast(error.response.data.message, { appearance: 'error' });
    } else if (data) {
      setIsLoading(false);
      if (data.data.status === 'success') {
        addToast(data?.data?.message || 'Check your E-mail', {
          appearance: 'success',
        });
      }
      hide();
    }
  };

  return (
    <div className="w-full pt-10">
      <Formik
        initialValues={initialValues}
        validationSchema={RegisterSchema}
        validateOnBlur={false}
        onSubmit={(values: Email) => {
          handleSubmit(values);
        }}
      >
        {() => {
          return (
            <Form>
              <FormikInput
                name={'email'}
                placeholder={'E-Mail'}
              />
              <FormButton text="Reset password" textColor="white" bgColor="mainRed" type="submit" loading={isLoading} />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default RegisterForm;
