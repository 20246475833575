import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { LOCAL_STORAGE } from '../types/localStorage';
import { getLocalStorageItem, getLocalStorageObj, removeLSItem } from '@helpers/getLocalStorage';
import { useStoreActions, useStoreState } from '@store';
import { RegistrationPages } from '@common/modals/register/context/d';



const useErrorPayments = () => {
  const { isAuthenticated } = useStoreState((state) => state.user);
  const { setShowLogin, setRegistrationFlow, setShowDeposit} = useStoreActions((actions) => actions);
  const location = useLocation();
  
  useEffect(() => {
    if (location.pathname === '/auth/payment-success' || location.pathname === '/failed'|| location.pathname === '/success' || location.hash === '#registration') return;
    if(!isAuthenticated) {
      removeLSItem(LOCAL_STORAGE.REGISTRATION_MODAL_AUTO_OPEN);
      removeLSItem(LOCAL_STORAGE.REGISTRATION_MODAL_DATA);
      removeLSItem(LOCAL_STORAGE.DEPOSIT_MODAL_AUTO_OPEN);
      removeLSItem(LOCAL_STORAGE.DEPOSIT_MODAL_DATA);
      return;
    }
    const regModal = getLocalStorageItem(LOCAL_STORAGE.REGISTRATION_MODAL_AUTO_OPEN);
    if (regModal || (!regModal && getLocalStorageObj(LOCAL_STORAGE.REGISTRATION_MODAL_DATA))) {
      setShowLogin(true);
      return;
    }

    const depModal =  getLocalStorageItem(LOCAL_STORAGE.DEPOSIT_MODAL_AUTO_OPEN);
    if (depModal || (!depModal && getLocalStorageObj(LOCAL_STORAGE.DEPOSIT_MODAL_DATA))) {
      setShowDeposit(true);
      return;
    }

  }, [location, setShowLogin, setShowDeposit, isAuthenticated]);


  useEffect(() => {
    if(!location || !location.hash || location?.hash !== '#registration' || isAuthenticated) return;
    if(getLocalStorageObj(LOCAL_STORAGE.REGISTRATION_MODAL_DATA)) {
      return;
    }
    localStorage.setItem(LOCAL_STORAGE.REGISTRATION_MODAL_DATA, JSON.stringify({
      isNeedBack: false,
      activePage: RegistrationPages.PERSONAL_INFO,
      lastPage: undefined
    }));
    localStorage.setItem(LOCAL_STORAGE.REGISTRATION_MODAL_AUTO_OPEN, `${new Date().getTime()}`);
    localStorage.setItem(LOCAL_STORAGE.REGISTRATION_SUCCESS, `${new Date().getTime()}`);
    setShowLogin(true);
  }, [location, isAuthenticated]);


};

export default useErrorPayments;