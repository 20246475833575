import React, { useCallback, useContext } from 'react';
import { useFormikContext } from 'formik';
import style from './PersonalInformation.module.scss';
import FormikInput from '@common/ui-components/input-fields/FormikInput';
import FormikInputPassword from '@common/ui-components/input-fields/FormikInputPassword';
import FormButton from '@common/ui-components/buttons/FormButton';
import { IconCarbonContinue, IconCrownFill, IconPercent } from '@common/modals/register/comon/icons';
import PromoCode from '@common/modals/register/components/personal-information/promo-code';
import FormikCheckBox from '@common/ui-components/input-fields/FormikCheckBox';
import Translation from '@common/translation';
import RegistrationContext from '@common/modals/register/context/context';
import { RegistrationPages } from '@common/modals/register/context/d';
import { useStoreState } from '@store';
import { checkUser } from '@http/endpoints/user';

const PersonalInformation = () => {
  const { showLoading } = useStoreState((state) => state);
  const { setFieldError, values, setFieldValue } = useFormikContext();
  const { setNextPage, setLogin } = useContext(RegistrationContext);

  const resetPromoCode = useCallback(() => {
    setFieldValue('promoCode', '').then();
  }, [setFieldValue]);

  const handleVipPlus = useCallback(() => {
    resetPromoCode();
    setNextPage(RegistrationPages.VIP);
  }, [setNextPage, resetPromoCode]);

  const handleBonus = useCallback(() => {
    resetPromoCode();
    setNextPage(RegistrationPages.BONUS);
  }, [setNextPage, resetPromoCode]);

  const handleNoOffer = useCallback(() => {
    resetPromoCode();
    setNextPage(RegistrationPages.NO_OFFER);
  }, [setNextPage, resetPromoCode]);

  const handleOnLogin = useCallback(() => {
    if (showLoading) return;
    setLogin();
  }, [setLogin, showLoading]);

  const emailCbFn = useCallback(async (value: string, name?: string) => {
    if (!value  || !value?.length || value?.length <= 2) return;
    await setFieldValue(`isValid_${name}`, false);
    const { data } = await checkUser(value);
    if (data && data?.data?.data?.id) {
      setFieldError('email', 'emailAlreadyTaken');
    } else {
      if (name) await setFieldValue(`isValid_${name}`, true);
    }
  }, [setFieldError, setFieldValue]);

  return (
    <div className={`${style.personalInformationContainer} flex flex-col`}>
      <FormikInput
        name={'email'}
        label={'E-Mail'}
        disabled={showLoading}
        withCbFn
        cbFn={emailCbFn}
      />
      <FormikInputPassword
        name={'password'}
        label={'Password'}
        type={'password'}
        disabled={showLoading}
      />
      <FormikInputPassword
        name={'password2'}
        label={'Confirm password'}
        type={'password'}
        disabled={showLoading}
      />
      <div className={'flex justify-center'}>
        <FormikCheckBox
          name={'termsConditions'}
          className={'terms-conditions-checkbox'}
          disabled={showLoading}
        >
          <Translation use={'registrationTermsCondition'} as={'a'} href={'/terms'} target={'_blank'}
                       className={'text-md font-normal underline'} />
        </FormikCheckBox>
      </div>
      <div className={'flex flex-col py-3'}>
        <Translation use={'registrationSpecialOfferTitle'} as={'p'} className="text-center pb-2 text-black text-xl" />
        <div className={'flex flex-col gap-2'}>
          <FormButton text="registrationVipButton" type={'submit'} textColor="white" bgColor="orange-800"
                      disabled={showLoading} loading={showLoading} icon={IconCrownFill} handleClick={handleVipPlus} />
          <FormButton text="registrationBonusButton" type={'submit'} textColor="white" bgColor="fuchsia-800"
                      disabled={showLoading} loading={showLoading} handleClick={handleBonus} icon={IconPercent} />
          <FormButton text="registrationNoOfferButton" type={'submit'} textColor="white" bgColor="slate-400"
                      disabled={showLoading} loading={showLoading} icon={IconCarbonContinue} handleClick={handleNoOffer} />
        </div>
      </div>
      <div className={`${style.personalInformationPromoCodeContainer} mb-3`}>
        <PromoCode />
      </div>
      <div className={`flex justify-center font-semibold ${style.personalInformationLoginPart}`}>
        <Translation use={'registrationAlreadyRegisterTest'} />
        <Translation use={'registrationLoginLinkText'}
                     className={`ml-3 underline cursor-pointer${showLoading ? ' cursor-not-allowed' : ''}`}
                     onClick={handleOnLogin} />
      </div>
      {/* <Translation use={'registrationBottomText'} as={'p'} className={`${style.personalInformationBottomText}`} />*/}
    </div>
  );
};

export default PersonalInformation;