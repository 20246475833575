import { useCallback } from 'react';
import { useStoreActions } from 'easy-peasy';
import { checkGiftCode } from '@http/endpoints/user';

const useSendGift = () => {
  const { setGiftCode } = useStoreActions((state) => state.user);
  const { setShowMessageModal } = useStoreActions((actions) => actions);

  const claimGift = useCallback(
    async (token) => {
      const { data, error } = await checkGiftCode(token);
      if (data) {
        setShowMessageModal(true);
        setGiftCode(null);
      } else if (error) {
        setGiftCode(null);
      }
    },
    [setGiftCode, setShowMessageModal]
  );

  return { claimGift };
};

export default useSendGift;
