import React, { useContext } from 'react';
import style from '@common/modals/register/components/vip-plus-packages/VipPlusForm.module.scss';
import { IconCrownFill } from '@common/modals/register/comon/icons';
import Translation from '@common/translation';
import VipPlans from '@common/modals/register/components/vip-plus-packages/Prices/VipPlans';
import PaymentMethods from 'components/common/modals/register/components/payment-methods';
import FormButton from '@common/ui-components/buttons/FormButton';
import { useStoreState } from '@store';
import RegistrationContext from '@common/modals/register/context/context';


const VipPlusPackages = () => {
  const { showLoading } = useStoreState((state) => state);
  const { clearNextPage } = useContext(RegistrationContext);

  return (
    <div className={`flex flex-col w-full bg-white ${style.vipPlusFormContainer}`}>
      <div className={`${style.vipPlusFormHeader} flex flex-col gap-3 items-center justify-center`}>
        <div className={`${style.vipPlusFormHeaderIcon}`}>
          <IconCrownFill />
        </div>
        <div className={'flex flex-col gap-2 justify-center items-center'}>
          <Translation use={'registrationVipPlusFormHeaderTitle'} as={'p'} className={`${style.vipPlusFormHeaderTitle}`} />
          <Translation use={'registrationVipPlusFormHeaderSubText'} as={'p'}
                       className={`${style.vipPlusFormHeaderText}`} />
        </div>
      </div>
      <div className={`flex flex-col px-6 pt-5 pb-5 ${style.vipPlusBodyContainer}`}>
        <div className={`flex justify-between items-start pb-4 ${style.vipPlusPriceMethodContainer}`}>
          <VipPlans />
          <PaymentMethods isSvod/>
        </div>
        <div className={`py-4 ${style.vipPlusButtonContainer}`}>
          <FormButton text={'registrationVipPlusButtonLabel'} bgColor={'green-600'} textColor={'white'} type={'submit'} handleClick={clearNextPage} loading={showLoading} />
        </div>
        <div className={'font-normal text-xs text-black text-opacity-50 text-center'}>
          <Translation use="registrationVipBottomText" as="p" />
          <Translation use="registrationVipBottomText2" as="p" />
        </div>
      </div>
    </div>
  );
};

export default VipPlusPackages;
