import AppProvider from './context/AppProvider';
import { Pages } from '@pages';

function App() {
  return (
    <AppProvider>
      <Pages />
    </AppProvider>
  );
}
export default App;
