/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React, { FC, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useClickAway } from 'react-use';
import { useTranslation } from 'react-i18next';
import { INavLink } from './types';
import iconDE from '@images/de-flag.png';
import iconEN from '@images/en-flag.png';
import { isUserInfoModel, useStoreState } from '@store';

/**
 * Side Navigation for Mobile
 * @param navLinks
 * @param showMenu
 * @param setShowMenu
 * @returns {JSX.Element}
 * @constructor
 */
type Props = {
  navLinks: INavLink[];
  showMenu: boolean;
  setShowMenu: (show?: boolean) => void;
};
const SideNav: FC<Props> = ({ navLinks, showMenu, setShowMenu }) => {
  const ref = useRef(null);
  const { t, i18n } = useTranslation();
  const { userInfo } = useStoreState((state) => state.user);

  useEffect(() => {
    const storedLan = localStorage.getItem('user_lang');
  }, []);

  useClickAway(ref, () => {
    if (showMenu) {
      setTimeout(() => {
        setShowMenu(false);
      }, 200);
    }
  });
  const handleLangChange = async (lang: string) => {
    localStorage.setItem('user_lang', lang);

    await i18n.changeLanguage(lang);
    setTimeout(() => window.location.reload(), 200);
  };

  return (
    <ul
      className={`bg-white shadow-lg side-nav ease-in-out beateuhse-navigation ${
        showMenu ? 'nav-show' : 'nav-hide'
      } left-4 w-3/4 sm:w-2/5 px-4 sm:px-6 md:px-8 shadow-lg z-10`}
      ref={ref}
    >
      {isUserInfoModel(userInfo) && userInfo?.deposit && (
        <li className="pt-3 text-lg">
          <div className="flex w-full justify-center items-center text-center">
            <p className="text-sm rounded font-bold flex-nowrap inline-block text-mainRed work-sans-font opacity-75">
              {`Kto.-St. ${Number(userInfo.deposit).toFixed(2).replace('.', ',')} €`}
            </p>
          </div>
        </li>
      )}

      {navLinks.map((item: INavLink, index: number) => (
        <li
          onKeyDown={() => setShowMenu(false)}
          role="button"
          tabIndex={0}
          key={index}
          className={`text-black py-3 text-lg ${
            item.name === 'Club' || item.name === 'Subscription' ? 'text-mainRed' : 'text-black'
          }`}
          onClick={() => setShowMenu(false)}
        >
          {item.externalLink ? (
            <a href={item.path} target="_blank" rel="noopener noreferrer" className={`inline-block w-full`}>
              {item.name}
            </a>
          ) : (
            <Link
              className={`inline-block w-full ${item.club ? 'text-mainGreenDark font-semibold' : ''}`}
              to={item.path}
            >
              {t(item.name)}
            </Link>
          )}
        </li>
      ))}
      <li className="flex flex-row space-x-6 text-white py-3 text-lg justify-center">
        <img
          onKeyDown={() => handleLangChange('de')}
          role="button"
          tabIndex={0}
          src={iconDE}
          alt="language iceon cursor-pointer"
          className="w-5 h-5"
          onClick={() => handleLangChange('de')}
        />
        <img
          onKeyDown={() => handleLangChange('en')}
          role="button"
          tabIndex={0}
          src={iconEN}
          alt="language iceon cursor-pointer"
          className="w-5 h-5"
          onClick={() => handleLangChange('en')}
        />
      </li>
    </ul>
  );
};

export default SideNav;
