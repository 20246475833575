import http from '@http';

export const getWishlist = async (queryKey, { params }) => {
  const { data } = await http.get(`/wishlists`, { params });
  return data;
};

export const addWishlist = async (id, type) => {
  const { data } = await http.post(`/wishlists`, { data_id: id, filter: type });
  return data;
};

export const removeWishlist = async (id, type) => {
  const { data } = await http.delete(`/wishlists/${id}?filter=${type}`);
  return data;
};
