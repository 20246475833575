import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useToasts } from 'react-toast-notifications';
import Cookies from 'js-cookie';
import { isMobile } from 'react-device-detect';
import { COOKIES, SendDataModel } from './types';
import { useStoreActions, useStoreState } from '@store';
import readCookie from '@helpers/readCookie';
import { subscribeUser } from '@http/endpoints/subscriptions';
import EmailSubscribeForm from '@common/modals/subscribe/EmailSubscribeForm';

const EmailSubscribeModal: FC = () => {
  const timer = useRef(0);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const { showNewsletterModal } = useStoreState((state) => state);
  const { setShowNewsletterModal, setNeedSubscription } = useStoreActions((state) => state);
  const { updateUser } = useStoreActions((state) => state.user);
  const { isAuthenticated, isNewsletterSubscribed, userInfo } = useStoreState((state) => state.user);

  const Schema = Yup.object().shape({
    email: Yup.string().required(t('E-mail address is required field')).email(t('Invalid E-mail address')),
    checkConfirmed: Yup.boolean().required(t('Please confirm agreement')).test('checked-confirmed', t('Please confirm agreement'), (value)=> !!value),
  });


  const setSubscriberCookie = (timeDays = 400) => {
    Cookies.set(COOKIES.BUM_SUBSCRIBED, 'true', { expires: timeDays });
    Cookies.set(COOKIES.BUM_SUBSCRIBED_EXPIRES, `${new Date().setDate(new Date().getDate() + timeDays)}`, { expires: timeDays });
    setNeedSubscription(true);
  };

  useEffect(() => {
    if (!showNewsletterModal) return;
    if(!isAuthenticated || !isNewsletterSubscribed) setShowModal(true);
  }, [showNewsletterModal, isAuthenticated, isNewsletterSubscribed]);

  useEffect(() => {
    const cookieValue = Cookies.get(COOKIES.BUM_SUBSCRIBED);
    timer.current = setTimeout(
      () => {
        if (!isAuthenticated && !cookieValue && !isMobile) {
          setShowModal(true);
          return;
        }
        if (isAuthenticated && showModal && !showNewsletterModal) {
          setShowModal(false);
        }
      },
      isAuthenticated ? 0 : 1000 * 120,
    ) as any;
    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
        timer.current = 0;
      }
    };
  }, [showNewsletterModal, isAuthenticated, showModal, userInfo, timer]);

  useEffect(() => {
    const cc = Cookies.get(COOKIES.BUM_SUBSCRIBED_EXPIRES);
    if (!cc) return;
    const isNeedUpdateCookie = (() => {
      const currentTime = new Date().getTime();
      const expTime = Number(cc);
      const diffDays = Math.ceil(Math.abs(currentTime - expTime) / (1000 * 60 * 60 * 24));
      if (isNaN(diffDays)) return false;
      return diffDays < 10;
    })();
    if (!isNeedUpdateCookie) {
      return;
    }
    setSubscriberCookie();
  }, []);

  const initialValues = {
    email: '',
    checkConfirmed: false,
  };

  const handleSubmit = useCallback(async (values: { email: string }) => {
    const clicker_id = readCookie('clicker_id') || null;

    const sendData: SendDataModel = { email: values.email, current_page: window.location.href };

    if (clicker_id) {
      sendData['clicker_id'] = clicker_id;
    }

    const { data, error } = await subscribeUser(sendData);

    if (data) {
      addToast(data?.data?.message || t('Email submitted successfully'), {
        appearance: 'success',
      });
      await updateUser();
      setSubscriberCookie();
      setShowModal(false);
    }

    if (error) {
      addToast(error.response?.data?.message || '', {
        appearance: 'warning',
      });
      setShowModal(false);
    }
  }, [addToast, t, setShowModal]);

  const handleClose = useCallback(() => {
    if (isLoading) return;
    setShowModal(false);
    if (showNewsletterModal) setShowNewsletterModal(false);
    Cookies.set(COOKIES.BUM_SUBSCRIBED, 'true', { expires: 7 });
    Cookies.remove(COOKIES.BUM_SUBSCRIBED_EXPIRES);
  }, [showNewsletterModal, setShowNewsletterModal, setShowModal, isLoading]);


  return (
    <div
      className={`subscribe-modal shadow-xl bg-gray-200 ${showModal ? 'subscribe-modal-show' : 'subscribe-modal-hide'}`}
    >
      <span
        onKeyDown={handleClose}
        role="button"
        tabIndex={0}
        className="subscription-close opacity-50 cursor-pointer"
        onClick={handleClose}
      >
        <i className="fas fa-times" />
      </span>
      <p className="text-center w-full mb-5 text-md font-semibold font-roboto text-gray-800">
        {t('Join our mailing list for free movie offers every week')}!
      </p>

      <Formik
        initialValues={initialValues}
        validationSchema={Schema}
        onSubmit={async (values, methods) => {
          if (!values?.checkConfirmed) {
            methods.setFieldError('email', `${t('Please confirm agreement')}.`);
            return;
          }
          try {
            setIsLoading(true);
            await handleSubmit(values);
          } catch (e) {
            console.log(e);
          } finally {
            setIsLoading(false);
            setShowModal(false);
            methods.resetForm();
          }
        }}
      >
        {() => {
          return (
            <EmailSubscribeForm isLoading={isLoading} />
          );
        }}
      </Formik>
    </div>
  );
};

export default EmailSubscribeModal;
