import React, { FC, useCallback, useRef, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useClickAway } from 'react-use';
import { useTranslation } from 'react-i18next';
import { logoutUser } from '@http/endpoints/user';
import { useStoreActions } from '@store';

const navLinks = [
  { path: '/auth/myaccount', name: 'My Account' },
  { path: '/auth/purchased-movies?page=1', name: 'My Movies' },
  { path: '/auth/purchased-scenes?page=1', name: 'My Scenes' },
  { path: '/auth/mysubscriptions', name: 'My Subscriptions' },
  { path: '/auth/wishlist?page=1', name: 'Wishlist' },
];

const AuthMenu: FC = () => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const { removeAuthInfo } = useStoreActions((actions) => actions.user);
  const ref = useRef(null);
  const [isWaiting, setIsWaiting] = useState<boolean>(false);
  const { t } = useTranslation();
  const history = useHistory();
  const { setShowDeposit, setShowRegisterBonus, setShowLogin } = useStoreActions((actions) => actions);

  useClickAway(ref, () => {
    if (showMenu) {
      setTimeout(() => {
        setShowMenu(false);
      }, 200);
    }
  });

  const handleLogout = useCallback(async () => {
    if(isWaiting) return;
    try {
      if(!isWaiting) setIsWaiting(true);
      const { data } = await logoutUser();
      if (data) {
        await removeAuthInfo();
        setShowRegisterBonus(false);
        setShowLogin(false);
        setTimeout(() => history.push('/'), 500);
      }
    }catch (e) {
      /** */
    }finally {
      setIsWaiting(false);
    }
  }, [removeAuthInfo, history, setIsWaiting, isWaiting, setShowRegisterBonus, setShowLogin]);

  return (
    <div className="relative inline-block text-left z-50 w-16 text-right" ref={ref}>
      <div
        onKeyDown={() => setShowMenu(!showMenu)}
        role="button"
        tabIndex={0}
        onClick={() => setShowMenu(!showMenu)}
        className="cursor-pointer hover:opacity-75 lg:bg-mainRed lg:text-white lg:py-1 rounded-md lg:text-center"
      >
        {/* <span className="rounded-md shadow-sm hidden lg:inline-block mr-2">
          {sessionInfo
            ? sessionInfo.email
              ? sessionInfo.email.length > 10
                ? `${sessionInfo.email.slice(0, 10)}...`
                : sessionInfo.email
              : ""
            : ""}
        </span> */}
        <span>
          <i className="fas fa-user lg:hidden" />
        </span>
        <span>
          <i className="fas fa-cog ml-1" />
        </span>
        <span>
          <i className="fas fa-caret-down ml-2 hidden lg:inline-block" />
        </span>
      </div>
      <div
        className={`${showMenu ? 'visible' : 'hidden'} h-${
          showMenu ? 'full' : '0'
        } origin-top-right absolute right-0 w-48 rounded-md shadow-lg`}
      >
        <div className="rounded-md bg-white shadow-lg w-full z-50">
          <div className="py-1 rounded" role="menu">
            <div
              onKeyDown={() => setShowDeposit(true)}
              tabIndex={0}
              className="block px-5 py-3 text-sm leading-5 text-black hover:opacity-75 cursor-pointer inline-flex justify-between w-full"
              role="menuitem"
              onClick={() => setShowDeposit(true)}
            >
              <span className="inline-block">{t('Deposit')}</span>
            </div>
            {navLinks.map((item, index) => (
              <NavLink key={index} to={item.path} className="inline-block w-full">
                <div
                  onKeyDown={() => setShowMenu(false)}
                  tabIndex={0}
                  className="block px-5 py-3 text-sm leading-5 text-black hover:opacity-75 cursor-pointer inline-flex justify-between w-full"
                  role="menuitem"
                  onClick={() => setShowMenu(false)}
                >
                  <span className="whitespace-no-wrap">{t(item.name)}</span>
                </div>
              </NavLink>
            ))}
            <div
              onKeyDown={handleLogout}
              tabIndex={0}
              className="block px-5 py-3 text-sm leading-5 text-black hover:opacity-75 cursor-pointer inline-flex justify-between w-full"
              role="menuitem"
              onClick={handleLogout}
            >
              <span className="inline-block">{t('Logout')}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthMenu;
