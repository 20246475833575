import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { MovieByIdModal } from '../../../../types';
import { SceneItem } from '../../../../../scenes/common/SceneItem';
import styles from './SceneList.module.scss';

type Props = {
  movieById: MovieByIdModal;
};

const SceneList: FC<Props> = ({ movieById }) => {
  const { t } = useTranslation();
  return (
    <>
      {movieById?.scenes?.length ? (
        <div className={styles.scene_container}>
          {(movieById?.needs_svod_button === 0 && movieById?.is_svod) || movieById?.stream_button === 1 ? (
            <h2 className={`text-black font-semibold work-sans-font opacity-75 mb-2`}>{t('Scenes')} </h2>
          ) : (
            <h2 className={`text-black font-semibold work-sans-font opacity-75 mb-2`}>
              {t('Scenes')}{' '}
              {movieById?.scene_stream_price && movieById?.stream_button === 0
                ? `/ ${Number(movieById?.scene_stream_price).toFixed(2).replace('.', ',')} €`
                : null}
            </h2>
          )}
          <div className="scenes-list-container flex flex-wrap -mx-2">
            {movieById?.scenes?.length
              ? movieById?.scenes.map((item) => {
                  return <SceneItem key={item.id} data={item} moviePage={true} showTitle={true} />;
                })
              : null}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default SceneList;
