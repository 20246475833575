import http from '@http';
import toResponse from '@helpers/toResponse/toResponse';

export const getSearchDropdown = async (queryKey, { params }) => {
  const { data } = await http.get(`/dropdowns`, { params });
  return data;
};

export const getSearchResults = async (queryKey, { params }) => {
  const { data } = await http.get(`/search`, { params });
  return data;
};

export const sendSearchStatistics = (values) => toResponse(http.post('/search-terms', values));
