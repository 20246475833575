import { FC } from 'react';
import styles from './PlayButton.module.scss';
import PlayIcon from '@assets/images/play-button/black_play.png';

type Props = { text: string; handleClick: () => void; iconRight?: boolean; icon?: boolean };
const PlayButton: FC<Props> = ({ text, handleClick, icon, iconRight = false }) => {
  return (
    <div
      onKeyDown={handleClick}
      role="button"
      tabIndex={0}
      onClick={handleClick}
      className={`${styles.button} w-full bg-mainGreen flex content-center text-white items-center px-5 py-5 rounded-md cursor-pointer shadow-lg hover:opacity-75`}
    >
      {icon ? <i className="fas fa-play text-white " /> : null}
      <p className={`${styles.text} text-center text-white w-full duration-150 select-none`}>
        {iconRight && <img src={PlayIcon} alt="PlayIcon" className={styles.play_icon} />} {text}
      </p>
    </div>
  );
};

export default PlayButton;
