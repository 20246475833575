import { FC, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { useLocalStorage, useMedia } from 'react-use';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { useQueryClient } from '@tanstack/react-query';
import CategoryTagList from '../CategoryTagList/CategoryTagList';
import ActressTagList from '../ActressTagList/ActressTagList';
import RatingStars from '../RatingStars/RatingStars';
import { MovieByIdModal } from '../../types';
import { MoviePurchaseInfoModel } from '../../../types';
import { UpSellModal } from '../UpSellModal';
import { MoviePagePoster } from './components/MoviePagePoster';
import { MoviePagePlayer } from './components/MoviePagePlayer';
import { MoviePageButtons } from './components/MoviePageButtons';
import styles from './MovieInfo.module.scss';
import { SceneList } from './components/SceneList';
import { isUserInfoModel, useStoreActions, useStoreState } from '@store';
import { buyStreamMovies } from '@http/endpoints/payments';
import PlayerModal from '@common/modals/player/PlayerModal';
import RateModal from '@common/modals/rate/RateModal';
import { StreamPlayer } from '@common/stream-player';
import { DiscreetPayment } from 'shared/discreet-payment';

type Props = {
  movieById: MovieByIdModal;
  refetchMovieData?: () => void;
  refetchSceneData?: () => void;
};

const MovieInfo: FC<Props> = ({ movieById, refetchMovieData, refetchSceneData }) => {
  const history = useHistory();
  const location = useLocation();
  const downloadRef = useRef<HTMLAnchorElement | null>(null);
  const { isAuthenticated, userInfo } = useStoreState((state) => state.user);
  const [_storageValue, setStorageValue] = useLocalStorage<MoviePurchaseInfoModel | string>('movie-purchase-info', '');
  const { setShowDeposit, setDepositModalData, setShowSubscription } = useStoreActions((actions) => actions);
  const { setShowLogin, setRedirectURL } = useStoreActions((actions) => actions);
  const { updateUser } = useStoreActions((actions) => actions.user);
  const [showPlayer, setShowPlayer] = useState(false);
  const [alreadyBought, setAlreadyBought] = useState(false);
  const [showRate, setShowRate] = useState(false);
  const [alreadyDownloaded, setAlreadyDownloaded] = useState(false);
  const [showSvodMessage, setShowSvodMessage] = useState(false);
  const queryClient = useQueryClient();
  const isDesktop = useMedia('(min-width: 1131px)');
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const promoCode = Cookies.get('bum_promo_code');

  useEffect(() => {
    if (
      movieById.trailer !== null ||
      (movieById.needs_svod_button === 0 && movieById.is_svod) ||
      movieById.stream_button === 1 ||
      movieById.download_button
    )
      window.scrollTo({ top: 0, left: 0 });
  }, [movieById]);

  const checkIfSvod = () => {
    if (!movieById?.is_svod || (isUserInfoModel(userInfo) && userInfo?.svod_member)) return false;
    const session = sessionStorage.getItem('svod-message');
    if (session) {
      return false;
    }
    sessionStorage.setItem('svod-message', 'true');
    return true;
  };

  const handleStreamPay = () => {
    const checkForMessage = checkIfSvod();
    if (isAuthenticated && checkForMessage) {
      setShowSvodMessage(true);
      return;
    }

    if (!isAuthenticated) {
      setShowLogin(true);
    } else if (!alreadyBought) {
      if (isUserInfoModel(userInfo) && Number(movieById.stream_price) > Number(userInfo?.deposit)) {
        setDepositModalData({
          refetchData: ['movies'],
          type: 'movie',
          id: movieById.id,
          option: 'stream',
          price: Number(movieById.stream_price),
        });
        setStorageValue({
          type: 'stream',
          entity_id: movieById.id,
          entity_type: 'movie',
          ...(promoCode && { promo_code: promoCode }),
        });
        setShowDeposit(true);
      } else {
        setAlreadyBought(true);
        const values = {
          type: 'stream',
          entity_id: movieById.id,
          entity_type: 'movie',
          ...(promoCode && { promo_code: promoCode }),
        };
        handleBuyMovie(values);
      }
    }
  };

  const handleDownloadPay = () => {
    const checkForMessage = checkIfSvod();
    if (isAuthenticated && checkForMessage) {
      setShowSvodMessage(true);
      return;
    }
    if (!isAuthenticated) {
      setShowLogin(true);
    } else if (!alreadyDownloaded) {
      if (isUserInfoModel(userInfo) && Number(movieById.download_price) > Number(userInfo?.deposit)) {
        setDepositModalData({
          refetchData: ['movies'],
          type: 'movie',
          id: movieById.id,
          option: 'download',
          price: Number(movieById.download_price),
        });
        setStorageValue({
          type: 'download',
          entity_id: movieById.id,
          entity_type: 'movie',
          ...(promoCode && { promo_code: promoCode }),
        });
        setShowDeposit(true);
      } else {
        setAlreadyDownloaded(true);
        const values = {
          type: 'download',
          entity_id: movieById.id,
          entity_type: 'movie',
          ...(promoCode && { promo_code: promoCode }),
        };
        handleBuyMovie(values);
      }
    }
  };

  const handleJoinClub = () => {
    setDepositModalData({
      refetchData: ['movies'],
    });
    setShowSubscription(true);
    setRedirectURL(location.pathname);
    history.push('/member-plan');
  };

  const handleBuyMovie = async (values: MoviePurchaseInfoModel, fromSVOD?: boolean) => {
    if (promoCode) {
      values.promo_code = promoCode;
    }

    const { data, error } = await buyStreamMovies(values);
    if (error) {
      addToast(error.response.data.message, { appearance: 'error' });
    }
    if (data) {
      if (data.data?.status === 'success') {
        setShowSvodMessage(false);
        updateUser();
        queryClient.invalidateQueries(['movie-by-id']);
        if (refetchSceneData) refetchSceneData();
        if (fromSVOD) {
          setShowPlayer(true);
        } else {
          addToast(data.data.message, { appearance: 'success' });
          window.scrollTo({ top: 0, left: 0 });
        }
      }
    }
  };

  const handleDownloadMovie = () => {
    if (downloadRef.current) downloadRef.current.click();
  };

  const handleStreamButton = () => {
    if (movieById?.stream_link) {
      setShowPlayer(true);
    } else {
      const values = {
        type: 'stream',
        entity_id: movieById.id,
        entity_type: 'movie',
        ...(promoCode && { promo_code: promoCode }),
      };
      handleBuyMovie(values, true);
    }
  };

  const handleShowClubDeal = () => {
    setShowSvodMessage(false);
    setShowSubscription(true);
    setRedirectURL(location.pathname);
    history.push('/member-plan');
  };

  return (
    <div>
      <PlayerModal open={showPlayer} onClose={() => setShowPlayer(false)}>
        <div className="trailer-container">
          <StreamPlayer url={movieById?.stream_link || ''} type={'movie'} mediaID={movieById?.id || ''} />
        </div>
      </PlayerModal>
      <div className={`${styles.container} block w-full pt-10`}>
        <MoviePagePoster movieById={movieById} />
        <div className="w-full max-w-[575px]">
          <MoviePagePlayer data={movieById} />
          <div className="xl:flex mt-4 ">
            <MoviePageButtons
              data={movieById}
              handleClick={() => handleStreamButton()}
              handleJoinClub={handleJoinClub}
              handleStreamPay={handleStreamPay}
              handleDownloadMovie={handleDownloadMovie}
              handleDownloadPay={handleDownloadPay}
            />
          </div>
          <DiscreetPayment row />
          <div className="text-sm font-semibold py-4">
            {movieById?.release_date && movieById?.release_date >= 2019 ? (
              <p>
                {t('Year')}: {movieById.release_date}
              </p>
            ) : null}
          </div>
          <div className="mt-2">
            {movieById.categories.length ? <CategoryTagList data={movieById.categories} /> : null}
          </div>
          <div className="mt-2">{movieById.actors.length ? <ActressTagList data={movieById.actors} /> : null}</div>
          <div className="movie-description pt-2 ">
            <p className="text-sm font-semibold opacity-75 whitespace-pre-line">
              {movieById.description && movieById.description}
            </p>
          </div>
          {!isDesktop && <SceneList movieById={movieById} />}
        </div>
      </div>
      <a className="hidden" ref={downloadRef} href={movieById?.download_link || '#'} download>
        download
      </a>
      <RateModal open={showRate} onClose={() => setShowRate(false)}>
        <RatingStars
          isAuth={isAuthenticated}
          movieID={movieById.id}
          averageRate={movieById.average_rating}
          refetchData={refetchMovieData}
          ratingsNumber={movieById.number_of_ratings || 0}
          closeModal={() => setShowRate(false)}
        />
      </RateModal>
      {movieById?.promo_product && (
        <UpSellModal
          data={movieById}
          handleStreamPay={handleStreamPay}
          handleDownloadPay={handleDownloadPay}
          handleDownloadMovie={handleDownloadMovie}
          handleStreamButton={() => handleStreamButton()}
          showSvodMessage={showSvodMessage}
          handleShowClubDeal={handleShowClubDeal}
          hide={() => setShowSvodMessage(false)}
        />
      )}
    </div>
  );
};

export default MovieInfo;
