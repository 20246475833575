import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useStoreState } from 'easy-peasy';
/**
 * Protected routes for authenticated users
 * @param  {object} children
 */
const AuthenticatedRoute = ({ children, ...rest }) => {
  const { isAuthenticated } = useStoreState((state) => state.user);
  return (
    <Route
      {...rest}
      render={() =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/',
            }}
          />
        )
      }
    ></Route>
  );
};

export default AuthenticatedRoute;
