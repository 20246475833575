import { ChangeEvent, FC, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useClickAway, useDebounce } from 'react-use';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Suggestions from './common/SearchSuggestions';
import { getSearchDropdown, sendSearchStatistics } from '@http/endpoints/search';
import { useStoreState } from '@store';

type Props = {
  hideSearch: () => void;
  mobile?: boolean;
};

const SearchField: FC<Props> = ({ mobile, hideSearch }) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [debouncedValue, setDebouncedValue] = useState<string>('');
  const [enableQuery, setEnableQuery] = useState<boolean>(false);
  const [focusNext, setFocusNext] = useState<boolean>(false);
  const { t } = useTranslation();
  const history = useHistory();
  const { showAgreement } = useStoreState((state) => state.agreement);
  const { isAuthenticated } = useStoreState((state) => state.user);

  const { status, data } = useQuery(
    ['searchdropdown', { params: { q: debouncedValue, type: 'all' } }],
    getSearchDropdown,
    { enabled: enableQuery, retry: false, refetchOnWindowFocus: false }
  );
  const ref = useRef(null);

  useClickAway(
    ref,
    () => {
      if (ref !== null && searchValue.length >= 2) {
        hideSearch();
        setSearchValue('');
      }
    },
    ['click']
  );
  useDebounce(
    () => {
      if (searchValue.length >= 2) {
        setDebouncedValue(searchValue);
        setEnableQuery(true);
      } else {
        setEnableQuery(false);
      }
    },
    500,
    [searchValue]
  );

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };
  const handleSubmit = () => {
    if (searchValue.length >= 2) {
      history.push(`/search/all/${encodeURI(searchValue)}`);
      hideSearch();
      setSearchValue('');
    }
  };
  const handleInputPress = (e: { key: string }) => {
    if (e.key === 'Enter') {
      history.push(`/search/all/${encodeURI(searchValue)}`);
      hideSearch();
      setSearchValue('');
    }
    if (e.key === 'ArrowDown') {
      setFocusNext(true);
    } else if (e.key === 'Escape') {
      setSearchValue('');
    }
  };
  const handleSuggestionClick = async (id: string | number, type: string, name: string, key?: string) => {
    if (key) {
      if (key === 'Enter') {
        if (type === 'movies') history.push(`/movie/${id}/${name.replace(/ /g, '-').replace(/[^\w-]+/g, '')}`);
        if (type === 'categories') history.push(`/movies/category/${id}/${encodeURI(name)}?page=1`);
        if (type === 'actors') history.push(`/star/${id}/${name.replace(/ /g, '-').replace(/[^\w-]+/g, '')}`);
        if (type === 'studios') history.push(`/movies/studio/${id}/${encodeURI(name)}?page=1`);
        setSearchValue('');
        hideSearch();
      }
    } else {
      if (type === 'movies') history.push(`/movie/${id}/${name.replace(/ /g, '-').replace(/[^\w-]+/g, '')}`);
      if (type === 'categories') history.push(`/movies/category/${id}/${encodeURI(name)}?page=1`);
      if (type === 'actors') history.push(`/star/${id}/${name.replace(/ /g, '-').replace(/[^\w-]+/g, '')}`);
      if (type === 'studios') history.push(`/movies/studio/${id}/${encodeURI(name)}?page=1`);
      setSearchValue('');
      hideSearch();
    }
    // send search term for statistic
    const sendData = {
      search_term: searchValue,
      type,
      id,
    };
    await sendSearchStatistics(sendData);
  };
  return (
    <div className={`search-field-big relative border-solid w-full ${mobile ? 'px-2 pb-4' : ''}`}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <div className={`${mobile ? '' : 'flex'} content-center items-center relative`}>
          {/* <select
            className={`${
              mobile
                ? "search-type-mobile bg-mainGray1 my-2"
                : "search-type-desktop"
            } outline-none text-small text-black py-1 block`}
            value={searchType}
            onChange={(e) => setSearchType(e.target.value)}
          >
            <option value="all">{t("All")}</option>
            <option value="movies">{t("Movies")}</option>
            <option value="categories">{t("Categories")}</option>
            <option value="studios">{t("Studios")}</option>
            <option value="actors">{t("Actors")}</option>
          </select> */}
          <div className="flex content-center items-center w-full" ref={ref}>
            <input
              className={`${
                mobile ? 'search-input-mobile' : 'search-input-desktop'
              } w-full outline-none text-small text-black py-1 bg-mainGray1`}
              type="text"
              value={searchValue}
              disabled={showAgreement && !isAuthenticated}
              onKeyDown={(e) => handleInputPress(e)}
              onChange={handleChange}
              placeholder={`${t('Search')}...`}
            />

            {status === 'loading' && null}
            {status === 'error' && <Suggestions data={[]} handleSuggestionClick={handleSuggestionClick} />}
            {status === 'success' && (
              <div
                className={`${
                  searchValue.length >= 2 ? 'block' : 'hidden'
                } absolute border border-mainGray2 shadow-xl overflow-hidden bg-white ${
                  mobile ? 'search-suggestions-mobile w-full' : 'search-suggestions-desktop w-5/6'
                } `}
              >
                <Suggestions
                  data={data?.data ? data.data : []}
                  handleSuggestionClick={handleSuggestionClick}
                  focusNext={focusNext}
                  clearSearch={() => setSearchValue('')}
                  setFocusNext={setFocusNext}
                  handleAllResults={handleSubmit}
                />
              </div>
            )}
            <button
              onClick={handleSubmit}
              className={`${mobile ? 'bg-mainGray1' : 'bg-mainGray1'} px-3 py-1 focus:outline-none`}
            >
              <i className="fas fa-search text-base"></i>
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SearchField;
