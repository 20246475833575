import React, { useMemo } from 'react';
import { useFormikContext } from 'formik';
import Translation from '@common/translation';
import { formatPrice } from '@helpers/utils';

const SelectedPackage = () => {
  const { values } = useFormikContext();
  const { selectedPackages } = values as any;
  
  const [isPackage, price, promoAmount, finalePrice, isAmount, isDiscount, promoValue] = useMemo(()=> [
    !!selectedPackages,
    selectedPackages?.price ? formatPrice((selectedPackages)?.price) : '',
    selectedPackages?.promo_amount ? formatPrice(selectedPackages?.promo_amount ) : '',
    selectedPackages?.final_price ? formatPrice(selectedPackages?.final_price) : '',
    selectedPackages?.is_amount,
    selectedPackages?.is_reversed,
    selectedPackages?.promo_value ? formatPrice(selectedPackages?.promo_value ) : '',
  ],[selectedPackages]);
  
  return (
    <div className={'flex flex-col flex-1 justify-end'}>
      <div className={`relative flex justify-center items-center pt-3  cursor-pointer gap-1 tracking-wide
        ${!isPackage ? ' opacity-40' : ''}
      `}>
      {/*  <Translation use={'summarizedCredits'} as={'p'} className={'text-xs font-normal absolute top-0 left-0 opacity-80'} />
        <div><sub>{`${price}€`}</sub></div>
        <div><sub>{`${isDiscount ? '-' : '+'}`}</sub></div>
        <div><sub>{`${promoAmount}${isAmount ? '€' : '%'}`}</sub></div>
        <div><sub>{` = `}</sub></div>
        <div className={'text-2xl font-bold'}>{`${finalePrice}€`}</div>*/}
        <table className={'w-full font-normal'}>
          <tr>
            <Translation use={'registerSelectedPackageSummary'} as={'td'} />
          </tr>
          <tr className={'font-bold'}>
            <Translation use={'registerSelectedPackageAmount'} as={'td'} />
            <td className={'text-right'}>{price ? `${price}€` : ''}</td>
          </tr>
          <tr>
            <Translation use={'registerSelectedPackageBonus'} as={'td'} params={{ bonusValue: `${promoValue  ? `${promoValue}${isAmount ? '€' : '%'}`: '##'}`}} />
            <td className={'text-right'}>{promoAmount ? `${promoAmount}€` : ''}</td>
          </tr>
          <tr className={'font-bold'}>
            <Translation use={'registerSelectedPackageTotal'} as={'td'} />
            <td className={'text-right'}>{finalePrice ? `${finalePrice}€` : ''}</td>
          </tr>
        </table>
      </div>
    </div>
  );
};

export default SelectedPackage;