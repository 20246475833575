import { createStore, createTypedHooks } from 'easy-peasy';
import { StoreModel } from './model/types';
import storeModel from './model/index';

export type { ShowPhoneVerifyModel } from './model/types';
export { isDepositModal, isPhoneVerifyModel, isPlanSelectModel, isUserInfoModel } from './model/typeGuards';

const typedHooks = createTypedHooks<StoreModel>();

export const useStoreActions = typedHooks.useStoreActions;
export const useStoreDispatch = typedHooks.useStoreDispatch;
export const useStoreState = typedHooks.useStoreState;

const store = createStore<StoreModel>(storeModel);

export default store;
