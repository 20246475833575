import { boolean, number, object, string } from 'yup';


export const PersonalInformationSchema = object().shape({
  email: string()
    .email('E-mail address is not valid')
    .required('E-mail address is required and cannot be empty'),
  password: string().min(4, 'Password to short').required('Password is required'),
  password2: string()
    .min(4, 'Password to short')
    .test('password-match', 'Passwords must match', function (value) {
      return this.parent.password === value;
    })
    .required('Password is required'),
  termsConditions: boolean().required('pleaseCheckTermsCondition').test('checked-confirmed', 'pleaseCheckTermsCondition', (value: any)=> !!value),
  promoCode: string().optional().test('promo-code', 'pleaseEnterPromoCode', (value: any)=> {
    if(!value) return true;
    return value?.trim()?.length;
   })
});

export const PromoPackagesSchema = object({
  priceId: number().required('selectPackageRequired'),
  paymentMethod: string().required('selectPaymentRequired')
});

export const VipPlusPackagesSchema = object({
  planId: number().required('selectPackageRequired'),
  paymentMethod: string().required('selectPaymentRequired')
});

export const BonusPackagesSchema = object({
  priceId: number().required('selectPackageRequired'),
  paymentMethod: string().required('selectPaymentRequired')
});