import { ErrorMessage, Field, FieldProps, useField } from 'formik';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'react-use';
import Translation from '@common/translation';


type Props = {
  className?: string
  disabled?: boolean
  placeholder?: string
  name: string
  label?: string
  useHelper?: boolean
  withCbFn?: boolean
  cbFn?: (value: string, name?: string) => void|Promise<any>
}

const FormikInput: FC<Props> = ({
  className,
  disabled,
  placeholder,
  name,
  label,
  useHelper = true,
  withCbFn,
  cbFn
}) => {
  const { t } = useTranslation();
  const [field] = useField(name);
  const timeout = useRef(null);
  
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    timeout.current && clearTimeout(timeout.current as any);
    field.onChange(e);
    timeout.current = setTimeout(() => {
      if(!withCbFn || !cbFn) return;
      if (e.target.value?.length >= 2) {
        cbFn(e.target.value, name);
      }
    }, 500)as any;
  };


  useEffect(() => {
    return () => {
      clearTimeout(timeout.current as any);
      timeout.current = null;
    };
  }, []);
  
  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => {
        const { touched, errors } = form;
        const isError = Boolean(errors[field.name]) && touched[field.name];
        const error = errors[field.name] as any;
        return (
          <div className={`relative flex flex-col${isError ? ' text-red-500' : ''}${disabled ? ' cursor-not-allowed': ''}`}>
            {label ? <Translation use={label} as={'label'}   className={'text-sm'} /> : null}
            <input
              type={'text'}
              disabled={disabled}
              placeholder={placeholder ? t(placeholder): undefined}
              className={`bg-white w-full rounded border border-gray-400 focus:outline-none focus:border-indigo-500 text-base  mt-1 mb-1
              ${isError ? ' text-red-500 border-red-500' : ''}
              ${className || ''}
              ${disabled ? ' cursor-not-allowed opacity-50': ''}`}
              {...field}
              onChange={onChange}
            />
            {useHelper && <div className="min-h-24 min-w-full text-xs text-red-500 text-left font-light font-semibold">{isError ? t(error || '') : <>&nbsp;</>}</div>}
          </div>
        );
      }}
    </Field>
  );
};


export default FormikInput;