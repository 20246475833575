import { ErrorMessage, Field, FieldProps, Form, useFormikContext } from 'formik';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useStoreState } from '@store';
import FormikInput from '@common/ui-components/input-fields/FormikInput';
import FormikCheckBox from '@common/ui-components/input-fields/FormikCheckBox';


const EmailSubscribeForm = ({ isLoading }: any) => {
  const { t } = useTranslation();
  const { showNewsletterModal } = useStoreState((state) => state);
  const { values, setFieldValue, resetForm } = useFormikContext() as any;
  const handlerCheckConfirm = useCallback((checked: boolean) => {
    if (isLoading) return;
    setFieldValue('checkConfirmed', checked, true);
  }, [setFieldValue, isLoading]);

  useEffect(() => {
    if (!showNewsletterModal) resetForm();
  }, [showNewsletterModal]);

  return (

    <Form>
      <FormikInput
        name={'email'}
        placeholder="E-mail"
        className="bg-white focus:outline-none w-full rounded border border-gray-400 focus:outline-none focus:border-indigo-500 text-base px-4 py-2"
        disabled={isLoading}
      />

      <div className="flex my-2 flex-col">
        <FormikCheckBox
          type="checkbox"
          checked={values.checkConfirmed}
          name={'checkConfirmed'}
          className={`mt-1 mr-2 inline-block`}
          disabled={isLoading}
        >
           <span
             onKeyDown={() => handlerCheckConfirm(!values.checkConfirmed)}
             role="button"
             tabIndex={0}
             className={`text-sm text-gray-800 cursor-pointer`}
             onClick={() => handlerCheckConfirm(!values.checkConfirmed)}
           >
                  {t('Marketing permission')}:{' '}
             {t(
               'By checking this box you agree to receive emails from Beate Uhse Movie and certify that you are at least 18 years of age',
             )}
             .
           </span>
        </FormikCheckBox>
      </div>
      <button
        type="submit"
        className={`
                ${
          isLoading && 'cursor-not-allowed'
        } relative form-buttons text-white shadow-lg font-semibold bg-mainGreenDark border-0 py-2 px-6 focus:outline-none rounded w-full mt-2`}
      >
        <span className={`${isLoading && 'btnInnerSpinner'} 'blackSpinner'`}></span>
        <span className={isLoading ? 'opacity-0' : ''}>{t('Submit')}</span>
      </button>
    </Form>
  );
};

export default EmailSubscribeForm;