/* eslint-disable import/no-named-as-default-member */
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

const usePagination = () => {
  const location = useLocation();
  const [page, setPage] = useState(parseInt(queryString.parse(location.search)?.page ?? 1));
  const [featuredPage, setFeaturedPage] = useState(1);

  const history = useHistory();

  useEffect(() => {
    let parsedParams = queryString.parse(location.search);

    if (parsedParams?.page) {
      // window.scrollTo(0, 0);
      setPage(Number(parsedParams.page));
    }
    if (parsedParams?.featured_page) {
      // window.scrollTo(0, 0);
      setFeaturedPage(Number(parsedParams.featured_page));
    }
  }, [location, history]);

  const handlePageChange = (value, skipScroll) => {
    if (!skipScroll) window.scrollTo(0, 0);
    const queryParam = queryString.parse(location.search);
    const newQueryParam = {
      ...queryParam,
      page: value,
    };
    history.push({
      pathname: location.pathname,
      search: queryString.stringify(newQueryParam),
    });
  };

  const handleFeaturedPageChange = (value) => {
    window.scrollTo(0, 0);
    const queryParam = queryString.parse(location.search);
    const newQueryParam = {
      ...queryParam,
      featured_page: value,
    };

    history.push({
      pathname: location.pathname,
      search: queryString.stringify(newQueryParam),
    });
  };

  return { page, featuredPage, handlePageChange, handleFeaturedPageChange };
};

export default usePagination;
