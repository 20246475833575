import React, { FC } from 'react';
import styles from './Method.module.scss';

type Props = {
  Icon: string;
  methodName: string;
  handleMethodSelect: (value: string) => void;
  method: string;
};

const Method: FC<Props> = ({ Icon, methodName, handleMethodSelect, method }) => {
  return (
    <div
      onKeyDown={() => handleMethodSelect(methodName)}
      role="button"
      tabIndex={0}
      onClick={() => handleMethodSelect(methodName)}
      id="payment-method"
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          handleMethodSelect(methodName);
        }
      }}
      className={`${styles.container} ${
        method === methodName ? 'border-2 border-mainRed' : 'border-2 border-transparent'
      } `}
    >
      <img className={`${methodName === 'sepaDirectDebit' ? 'sepa-logo' : 'w-full'}`} src={Icon} alt="card icon" />
    </div>
  );
};

export default Method;
