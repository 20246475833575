import { forwardRef, memo, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMedia } from 'react-use';
import LazyLoad from 'react-lazyload';
import VideoThumbnail from '../SceneRef';
import { SceneItemModel } from './types';
import styles from './SceneItem.module.scss';
import PlayIcon from '@images/red-play-icon.png';
import GermanAudio from '@assets/images/german-audio.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';

type Props = {
  data: SceneItemModel;
  coverScene?: boolean;
  moviePage?: boolean;
  showTitle?: boolean;
  ref?: any
  customHandler?: (event: any) => void;
};

// eslint-disable-next-line react/display-name
const SceneItem= forwardRef<any, Props>(({ data, coverScene, moviePage, showTitle, customHandler }, ref) => {
  const history = useHistory();
  const [showVideo, setShowVideo] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);
  const isMobile = useMedia('(max-width: 640px)');

  const changeImageSrc = useCallback(
    (data: SceneItemModel) => {
      setTimeout(() => {
        if (data?.thumbnails?.gif && imageIndex >= data?.thumbnails?.gif?.length - 1) {
          setImageIndex(0);
        } else {
          setImageIndex(imageIndex + 1);
        }
      }, 1000);
    },
    [imageIndex],
  );

  useEffect(() => {
    if (showVideo && data?.thumbnails?.gif && data?.thumbnails?.gif?.length > 0) {
      changeImageSrc(data);
    } else if (showVideo) setImageIndex(0);
  }, [showVideo, imageIndex, data, changeImageSrc]);

  const handleClick = useCallback(() => {
    if(customHandler) {
      customHandler(data.id);
    }
    history.push(`/scene/${data.id}`);
  },[history, customHandler, data]);

  return (
    <div
      ref={ref}
      onKeyDown={handleClick}
      role="button"
      tabIndex={0}
      onClick={handleClick}
      id={`id-scene-${data.id}`}
      className={`${
        coverScene
          ? 'w-full'
          : moviePage
            ? 'block w-full px-1 mb-2 relative'
            : 'block w-full sm:w-1/2 lg:w-1/3 xl:w-1/5  px-1 mb-2'
      } `}
    >
      {showTitle && (
        <div className={styles.scene_title_container}>
          <p className={styles.title} title={data?.title ? data.title : ''}>
            {data?.title ? data.title : ''}
          </p>
        </div>
      )}
      <div
        className={`${coverScene ? 'scene4-3' : 'scene4-3'} relative cursor-pointer bg-mainGray1`}
        onMouseEnter={() => setShowVideo(isMobile && !coverScene ? false : true)}
        onMouseLeave={() => setShowVideo(false)}
        onClick={() => setShowVideo(!showVideo)}
        role="button"
        tabIndex={0}
        onKeyPress={() => {
          () => setShowVideo(!showVideo);
        }}
      >
        <div className={'hover-effect-container pointer-events-none'}>
        {((showVideo && !coverScene) || isMobile) && (
          <>
            <div className={'hover-effect'}></div>
            <div className={'play-icon'}>
              <div className="play-icon-container">
                <LazyLoadImage src={PlayIcon} alt="play button icon" className="w-full" />
              </div>
            </div>
            <div className="scene-item-title font-semibold">
              {data?.movie_title}
            </div>
          </>
        )}
        </div>
        {data.needs_play_button === 1 && !coverScene && (
          <div className="absolute h-full z-10 top-0 pointer-events-none w-full h-full">
            <img className="scene4-3__play-button" src={PlayIcon} alt="Play icon" />
          </div>
        )}
        <LazyLoad height={10} offset={250}>
          <picture className={`duration-200 ${showVideo ? 'hidden' : 'block'} `}>
            <source
              media="(max-width: 640px)"
              srcSet={
                data?.thumbnails?.static?.large
                  ? data.thumbnails.static.large
                  : data?.thumbnails?.static?.original
                  ? data.thumbnails.static.original
                  : ''
              }
            />
            <source
              media="(min-width: 641px) and (max-width: 1023px)"
              srcSet={
                data?.thumbnails?.static?.large
                  ? data.thumbnails.static.large
                  : data?.thumbnails?.static?.original
                  ? data.thumbnails.static.original
                  : ''
              }
            />
            <source
              media="(min-width: 1024px)"
              srcSet={
                coverScene
                  ? data?.thumbnails?.static?.large
                  : data?.thumbnails?.static?.small
                  ? data.thumbnails.static.small
                  : data?.thumbnails?.static?.original
                  ? data.thumbnails.static.original
                  : ''
              }
            />
            <img
              src={data?.thumbnails?.static?.original ? data.thumbnails.static.original : ''}
              alt="scene poster"
              className={`duration-200 ${showVideo ? 'hidden' : 'block'} `}
            />
          </picture>
        </LazyLoad>

        {data?.thumbnails?.video ? (
          <LazyLoad>
            <VideoThumbnail
              src={data.thumbnails.video}
              showVideo={showVideo}
              thumb={data?.thumbnails?.static?.original}
            />
          </LazyLoad>
        ) : null}
        <LazyLoad>
          <img
            src={data?.thumbnails?.gif && data?.thumbnails?.gif[imageIndex]?.url}
            alt="scene poster"
            className={`duration-200 ${!data?.thumbnails?.video && showVideo ? 'block' : 'hidden'}`}
          />
        </LazyLoad>
        {data.needs_icon ? (
          <div className={`german-audio ${showVideo ? 'hidden' : 'block'}`} title="german audio">
            <img src={GermanAudio} alt="german audio icon" className="w-full" />
          </div>
        ) : null}
      </div>
    </div>
  );
});

export default memo(SceneItem);
