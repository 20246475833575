import React, { useCallback, useContext } from 'react';
import style from './NoOfferCards.module.scss';
import { IconCrownFill } from '@common/modals/register/comon/icons';
import Translation from '@common/translation';
import RegistrationContext from '@common/modals/register/context/context';
import { RegistrationPages } from '@common/modals/register/context/d';
import { useStoreState } from '@store';

const VipCard = ({setActivePage}: any) => {

  const { showLoading } = useStoreState((state) => state);

  const handleSeeVipPlusOptions = useCallback(()=> {
    if(showLoading) return;
    setActivePage(RegistrationPages.VIP);
  },[setActivePage, showLoading]);

  return (
    <div
      onKeyDown={handleSeeVipPlusOptions}
      role="button"
      tabIndex={0}
      onClick={handleSeeVipPlusOptions}
      className={`flex flex-col justify-center gap-3${showLoading ? ' cursor-not-allowed': ''}`}
    >
      <div className={`flex flex-col justify-between ${style.noOfferCard} ${style.noOfferVipCard} gap-5`}>
        <div className={'flex flex-col items-center gap-2'}>
          <div className={`${style.noOfferCardIcon} ${style.noOfferVipCardIcon}`}>
            <IconCrownFill />
          </div>
          <div className={`${style.noOfferVipCardPrice}`}>4.99 €</div>
          <Translation use={'registrationNoOfferCardVipText'} as={'div'} className={`${style.noOfferVipCardText}`} />
        </div>
        <Translation use={'registrationNoOfferCardVipSubText'} as={'div'}
                     className={`${style.noOfferVipCardBottomText}`} />
      </div>
    {/*  <div className={'flex justify-center'}>
        <Translation use={'registrationNoOfferCardVipLink'} as={'div'} className={`underline font-semibold${showLoading ? ' cursor-not-allowed' : ' cursor-pointer'}`}
                     onClick={handleSeeVipPlusOptions} />
      </div>*/}
    </div>
  );
};


export default VipCard;