/* eslint-disable import/no-named-as-default-member */
// eslint-disable-next-line import/no-named-as-default
import i18n from 'i18next';
import Backend from 'i18next-http-backend';
// import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from 'react-i18next';

i18n
  // .use(LanguageDetector)
  .use(initReactI18next)
  .use(Backend)
  .init({
    fallbackLng: 'de',
    debug: process.env.NODE_ENV !== 'production' ? false : false,
    //lng: "de",
    load: 'languageOnly',

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: false, // we use content as keys
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
