import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useToasts } from 'react-toast-notifications';
import { addWishlist } from '@http/endpoints/wishlist';

const useAddWishlistMutation = (id: number) => {
  const { addToast } = useToasts();
  const queryClient = useQueryClient();
  const { mutate: addWishlistMutation, isLoading: isAddWishlistMutationLoading } = useMutation(
    () => addWishlist(id, 'movies'),
    {
      onSuccess: (data) => {
        addToast(data.message, { appearance: 'success' });
        queryClient.invalidateQueries(['movies']);
      },
      onError: (data: { message: string }) => {
        addToast(data.message, { appearance: 'error' });
      },
    }
  );
  return { addWishlistMutation, isAddWishlistMutationLoading };
};

export default useAddWishlistMutation;
