import React, { useContext, useEffect } from 'react';
import { useFormikContext } from 'formik';
import style from '@common/modals/register/components/bonus/Bonus.module.scss';
import { IconPercent } from '@common/modals/register/comon/icons';
import Translation from '@common/translation';
import FormButton from '@common/ui-components/buttons/FormButton';
import DepositPackages from '@common/modals/register/components/bonus/prices/DepositPackages';
import PaymentMethods from 'components/common/modals/register/components/payment-methods';
import { useStoreState } from '@store';
import RegistrationContext from '@common/modals/register/context/context';
import SelectedPackage from '@common/modals/register/components/selected-package';

const BonusForm = () => {
  const { showLoading } = useStoreState((state) => state);
  const { clearNextPage } = useContext(RegistrationContext);
  const { setFieldValue } = useFormikContext();



  useEffect(() => {
    setFieldValue('promoCode', 'BUMP50BM24', false).then();
  }, []);


  return (
    <div className={`flex flex-col w-full bg-white ${style.bonusFormContainer}`}>
      <div className={`${style.bonusHeader} flex flex-col gap-3 items-center justify-center`}>
        <div className={`${style.bonusHeaderIcon}`}>
          <IconPercent />
        </div>
        <div className={'flex flex-col gap-2 justify-center items-center'}>
          <Translation use={'registrationBonusHeaderTitle'} as={'p'} className={'font-semibold text-xl'} />
          <Translation use={'registrationBonusHeaderSubText'} as={'p'} className={'text-md font-normal text-center'} />
        </div>
      </div>
      <div className={`flex flex-col px-6 pt-6 pb-6 ${style.bonusBodyContainer}`}>
        <div className={`flex justify-between items-start pb-2 ${style.bonusPackagesMethodContainer}`}>
          <DepositPackages />
          <div className={'flex flex-col flex-1 justify-between h-full'}>
            <PaymentMethods />
            <SelectedPackage />
          </div>
        </div>
        <div className={`py-8 ${style.bonusButtonContainer}`}>
          <FormButton text={'registrationBonusButtonText'} bgColor={'green-600'} textColor={'white'} type={'submit'} disabled={showLoading}  handleClick={clearNextPage} loading={showLoading}/>
        </div>
        {/*<Translation use={'registrationBonusPackagesText'} as={'p'} className={`${style.depositPackagesSubText} text-center opacity-40`} />*/}
      </div>
    </div>
  );
};

export default BonusForm;