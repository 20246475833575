import React, { ChangeEvent, FC, FormEvent, useCallback, useEffect, useState } from 'react';
import { Modal } from 'react-responsive-modal';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { sendPhoneNumber, sendSmsCode } from '@http/endpoints/phoneVerify';
import Spinner from '@common/spinner/Spinner';
import { abortPayment, savedCardCharge, sendPaymentData } from '@http/endpoints/payments';
import { ShowPhoneVerifyModel, useStoreActions, useStoreState } from '@store';

type Props = {
  show: boolean;
  hide: () => void;
};

const PhoneVerify: FC<Props> = ({ show, hide }) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [phoneValid, setPhoneValid] = useState(false);
  const [phoneVerify, setPhoneVerify] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showSpinner, setShowSpinner] = useState(false);
  const [resendOption, setResendOption] = useState(false);
  const { updateUser } = useStoreActions((state) => state.user);
  const location = useLocation();
  const [verificationID, setVerificationID] = useState('');
  const { showPhoneVerify } = useStoreState((state) => state);
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const deletePaymentId = useCallback(async () => {
    const { data } = await abortPayment();
    if (data) {
      updateUser();
    }
  }, [updateUser]);

  const handleSendHP = useCallback(
    async (id: number) => {
      const lang = localStorage.getItem('user_lang');
      const locale = lang ? lang : 'en';

      const checkout = new window.checkout(id, {
        locale,
      });
      // Initialize the payment page
      checkout
        .init()
        .then(function () {
          // If successfull, open the dialog
          resetForm();
          hide();
          setShowSpinner(false);
          checkout.open();

          // Add your event listeners for abort or success events
          checkout.abort(function () {
            deletePaymentId();
            setShowSpinner(false);
          });
          checkout.success(function () {
            window.location.reload();
          });
          checkout.error(function (err: Error) {
            deletePaymentId();
            setShowSpinner(false);
            // eslint-disable-next-line no-console
            console.error(err);
          });
        })
        .catch(function (err: Error) {
          setShowSpinner(false);
          // eslint-disable-next-line no-console
          console.error(err);
        });
    },
    [deletePaymentId, hide]
  );

  const handleSavedCard = useCallback(async () => {
    const values = {
      method_id: (showPhoneVerify as ShowPhoneVerifyModel).method_id,
      amount: (showPhoneVerify as ShowPhoneVerifyModel).amount,
      redirect_url: (showPhoneVerify as ShowPhoneVerifyModel).redirect_url,
      filter: (showPhoneVerify as ShowPhoneVerifyModel).filter,
    };
    const { data, error } = await savedCardCharge(values);
    if (error) {
      setShowSpinner(false);
      if (error?.response?.data?.message) {
        addToast(error.response.data.message, {
          appearance: 'warning',
        });
      }
      if (error?.response?.status === 402) {
        if (error.response.data?.data?.payment_id) {
          handleSendHP(error.response.data.data.payment_id);
        } else if (error.response.data?.data?.redirect_url) {
          window.location.href = error.response.data.data.redirect_url;
        }
      }
    }
    if (data) {
      setShowSpinner(false);

      if (data?.data?.data) addToast(data.data.message, { appearance: 'success' });
      if (Object.keys((showPhoneVerify as ShowPhoneVerifyModel).fromMembership).length) {
        window.location.reload();
      }
      updateUser();
      resetForm();
      hide();
    }
  }, [addToast, handleSendHP, hide, showPhoneVerify, updateUser]);

  const getHPToken = useCallback(async () => {
    const values = {
      amount: (showPhoneVerify as ShowPhoneVerifyModel).price,
      payment_method: (showPhoneVerify as ShowPhoneVerifyModel).method,
      redirect_url: (showPhoneVerify as ShowPhoneVerifyModel).url,
      filter: (showPhoneVerify as ShowPhoneVerifyModel).filter,
    };
    const { data, error } = await sendPaymentData(values);
    if (error) {
      if (error?.response?.data?.message) {
        addToast(error.response.data.message, {
          appearance: 'warning',
        });
      }
    }

    if (data) {
      handleSendHP(data.data.data.payment_id);
    }
  }, [addToast, handleSendHP, showPhoneVerify]);

  useEffect(() => {
    if (phoneValid) {
      setShowSpinner(true);
      if ((showPhoneVerify as ShowPhoneVerifyModel)?.fromSavedCard) handleSavedCard();
      else getHPToken();
    }
  }, [phoneValid, hide, showPhoneVerify, deletePaymentId, addToast, location, updateUser, handleSavedCard, getHPToken]);
  const customStyles = {
    modal: {
      animation: `${show ? 'customEnterAnimation' : 'customLeaveAnimation'} 500ms`,
      background: '#fff',
      borderRadius: '6px',
      width: 'auto',
      padding: '20px',
    },
    overlay: {
      background: 'rgba(0, 0, 0, 0.5)',
    },
  };

  const handleVerify = async () => {
    setSuccessMessage('');
    setResendOption(false);
    setErrorMessage('');

    const phoneRegex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/;
    const regexValid = phoneRegex.test(phoneNumber);

    if (regexValid) {
      const { data, error } = await sendPhoneNumber({
        phone_number: phoneNumber,
      });
      if (error) {
        setErrorMessage(t('Error sending sms. Please try again'));
        setShowSpinner(false);
      } else {
        setSuccessMessage(data?.data?.message);
        setVerificationID(data.data.data);
        setPhoneVerify(true);
        setShowSpinner(false);
      }
    } else {
      setErrorMessage(t('Not a valid phone number'));
    }
  };

  const handleValidate = async (e: FormEvent) => {
    e.preventDefault();
    setSuccessMessage('');
    setResendOption(false);

    const values = {
      code: verificationCode,
      mobile_verification_id: verificationID,
    };
    const { data, error } = await sendSmsCode(values);
    if (error) {
      setPhoneValid(false);
      setShowSpinner(false);
      setErrorMessage(t('The code does not appear to be valid. Please try again.'));
      setSuccessMessage('');
      setResendOption(true);
    } else if (data) {
      setPhoneValid(true);
      setShowSpinner(false);
      setResendOption(false);

      setErrorMessage('');
      setResendOption(false);
    }
  };

  const handlePhoneInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setErrorMessage('');
    setPhoneNumber(e.target.value);
  };

  const resetForm = () => {
    setPhoneNumber('');
    setVerificationCode('');
    setErrorMessage('');
    setSuccessMessage('');
    setPhoneValid(false);
    setPhoneVerify(false);
    setVerificationID('');
  };

  const handleHide = () => {
    resetForm();
    hide();
  };

  return (
    <>
      <Spinner show={showSpinner} />
      <Modal open={show} onClose={handleHide} styles={customStyles}>
        <div id="phone-verify-modal">
          <div>
            <h2 className="py-4 opacity-8 text-center">{t('Phone number must start with +49 or +43 or +41')}:</h2>
          </div>
          <p className="text-mainRed w-full text-center text-sm italic">{errorMessage}</p>
          <p className="text-mainGreen w-full text-center text-sm italic">{successMessage}</p>
          {phoneVerify && (
            <div className="w-full text-center py-2">
              <button
                onClick={resetForm}
                className="whitespace-no-wrap p-1 mx-auto w-full focus:outline-none mt-2 md:mt-0 rounded phoneVerify bg-gray-700 text-sm text-white font-semibold"
              >
                {t('Enter new phone number')}
              </button>
            </div>
          )}
          <div id="phone-verify" className="pb-4">
            <h1 className="">{t('Phone number')}:</h1>
            <div className="md:flex">
              <input
                className={`p-2 rounded border border-mainGray2 bg-mainGray1 w-full focus:outline-none`}
                value={phoneNumber}
                onChange={handlePhoneInputChange}
              />
              {resendOption ? (
                <button
                  className={`px-1 py-2 w-full focus:outline-none md:w-32 mt-2 md:mt-0 md:ml-2 rounded ${
                    phoneVerify ? 'bg-mainRed' : 'bg-gray-700'
                  } text-sm text-white font-semibold`}
                  onClick={handleVerify}
                >
                  {phoneVerify ? (
                    <span>
                      <i className="fas fa-redo text-lg" />
                    </span>
                  ) : (
                    t('Verify now')
                  )}
                </button>
              ) : (
                <button
                  className={`px-1 py-2 w-full focus:outline-none md:w-32 mt-2 md:mt-0 md:ml-2 rounded ${
                    phoneVerify ? 'bg-mainGreen' : 'bg-gray-700'
                  } text-sm text-white font-semibold`}
                  onClick={handleVerify}
                >
                  {phoneVerify ? (
                    <span>
                      <i className="fas fa-check text-lg" />{' '}
                    </span>
                  ) : (
                    t('Verify now')
                  )}
                </button>
              )}
            </div>
          </div>
          <div id="code-verify" className={`pb-4 ${phoneVerify ? '' : 'opacity-50'}`}>
            <h1 className="">{t('Enter verification code')}</h1>
            <form onSubmit={handleValidate}>
              <div className="md:flex">
                <input
                  className={`p-2 rounded border border-mainGray2 bg-mainGray1 w-full focus:outline-none`}
                  value={verificationCode}
                  type="number"
                  disabled={!phoneVerify}
                  required
                  max={999999}
                  onChange={(e) => setVerificationCode(e.target.value)}
                />
                <button
                  disabled={!phoneVerify}
                  className={`px-1 focus:outline-none py-2 w-full md:w-32 mt-2 md:mt-0 md:ml-2 whitespace-no-wrap rounded ${
                    phoneValid ? 'bg-mainGreen' : 'bg-gray-700'
                  } text-sm text-white font-semibold`}
                  type="submit"
                >
                  {phoneValid ? (
                    <span>
                      <i className="fas fa-check text-lg" />
                    </span>
                  ) : (
                    t('Send')
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default PhoneVerify;
