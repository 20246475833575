import { FC, useEffect, useState } from 'react';
import styles from './RippleButton.module.scss';

type Props = {
  children: React.ReactNode;
  onClick: () => void;
  className?: string;
  rippleColor?: string;
  tabIndex?: number;
  disabled?: boolean;
};

const RippleButton: FC<Props> = ({
  children,
  onClick,
  className,
  rippleColor = 'bg-blue-400',
  tabIndex = 0,
  disabled,
}) => {
  const [coords, setCoords] = useState<{ x: number; y: number }>({ x: -1, y: -1 });
  const [isRippling, setIsRippling] = useState<boolean>(false);

  useEffect(() => {
    if (coords.x !== -1 && coords.y !== -1) {
      setIsRippling(true);
      setTimeout(() => setIsRippling(false), 300);
    } else setIsRippling(false);
  }, [coords]);

  useEffect(() => {
    if (!isRippling) setCoords({ x: -1, y: -1 });
  }, [isRippling]);

  return (
    <button
      disabled={disabled}
      tabIndex={tabIndex}
      className={`${className} ${styles.ripple_button} `}
      onClick={(e: React.MouseEvent<HTMLElement>) => {
        const rect = e.currentTarget.getBoundingClientRect();
        setCoords({ x: e.clientX - rect.left, y: e.clientY - rect.top });
        onClick();
      }}
    >
      {isRippling ? (
        <span
          className={`${styles.ripple} ${rippleColor}`}
          style={{
            left: coords.x,
            top: coords.y,
          }}
        />
      ) : (
        ''
      )}
      <span className={styles.content}>{children}</span>
    </button>
  );
};

export default RippleButton;
