import { FC } from 'react';
import { Link } from 'react-router-dom';
import AuthMenu from '../header/components/AuthMenu';
import SideNav from './components/SideNav';
import { INavLink } from './components/types';
import Logo from '@images/BU-logo/resize/BUM-logo.png';
import { useStoreState } from '@store';

/**
 * Mobile Navigation
 *
 * @param navLinks
 * @param handleShowSearch
 * @param showMenu
 * @param setShowMenu
 * @param handleLoginShow
 * @returns {JSX.Element}
 * @constructor
 */
type Props = {
  navLinks: INavLink[];
  handleShowSearch: () => void;
  showMenu: boolean;
  setShowMenu: (show?: boolean) => void;
  handleLoginShow: () => void;
};
const MobileNav: FC<Props> = ({ navLinks, handleShowSearch, showMenu, setShowMenu, handleLoginShow }) => {
  const { isAuthenticated } = useStoreState((state) => state.user);

  return (
    <>
      <div className="mobile-header lg:hidden bg-white shadow-lg w-full text-black px-3 sm:px-6 md:px-8 lg:px-8 inline-flex justify-between">
        <div className="inline-flex relative justify-between content-center items-center">
          {!showMenu ? (
            <button className="mr-2 w-6 focus:outline-none" onClick={() => setShowMenu(true)}>
              <i className="fas fa-bars text-xl" />
            </button>
          ) : (
            <button className="mr-2 w-6 focus:outline-none">
              <i className="fas fa-bars text-xl" />
            </button>
          )}

          <button className="mr-4 w-6 opacity-50 focus:outline-none" onClick={handleShowSearch}>
            <i className="fas fa-search text-xl"></i>
          </button>
        </div>
        <div className="mobile-logo w-40 xs:w-48 absolute mx-auto left-0 right-0 flex items-center content-center justify-center">
          <Link className="flex justify-center" to="/">
            <img className="w-2/3" src={Logo} alt="site logo" />
          </Link>
        </div>
        {isAuthenticated ? (
          <AuthMenu />
        ) : (
          <button className="focus:outline-none opacity-50" onClick={handleLoginShow}>
            <i className="fas fa-sign-in-alt text-xl" />
          </button>
        )}
      </div>
      <SideNav navLinks={navLinks} showMenu={showMenu} setShowMenu={() => setShowMenu(!showMenu)} />
    </>
  );
};

export default MobileNav;
