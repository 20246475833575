import store from '@store';

export function authRequest(config) {
  const lang = localStorage.getItem('user_lang');
  const token = store.getState().user.session.sessionInfo.token;
  config.headers['X-CustomHeader'] = 'true';

  if (lang) {
    config.headers['X-localization'] = lang;
  }
  if (token) {
    config.headers['Authorization'] = 'Bearer ' + token;
  }
  return config;
}

export const authResponse = [
  function (response) {
    return response;
  },
  function (error) {
    if (403 === error.response.status) {
      store.getActions().user.removeAuthInfo();
    }
    return Promise.reject(error);
  },
];
