import React, { FC } from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

type Props = {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
};
const PlayerModal: FC<Props> = ({ open, onClose, children }) => {
  const customStyles = {
    modal: {
      animation: `${open ? 'customEnterAnimation' : 'customLeaveAnimation'} 500ms`,
      background: 'transparent',
      padding: '0',
    },
    overlay: {
      background: 'rgba(0, 0, 0, 0.5)',
    },
  };
  return (
    <Modal open={open} onClose={onClose} styles={customStyles}>
      <div className="player-modal">{children}</div>
    </Modal>
  );
};

export default PlayerModal;
