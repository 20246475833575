import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { INavLink } from '../header/components/types';
import { isUserInfoModel, useStoreActions, useStoreState } from '@store';

type Props = {
  path: string;
  item: INavLink;
};
const LinkItem: FC<Props> = ({ path, item }) => {
  const { t } = useTranslation();
  const { setShowSubscription } = useStoreActions((actions) => actions);
  const { userInfo } = useStoreState((state) => state.user);

  const handleShowModal = () => {
    if (isUserInfoModel(userInfo) && userInfo?.svod_member) return;
    setShowSubscription(true);
  };
  return (
    <>
      {item.externalLink ? (
        <a
          href={item.path}
          target="_blank"
          rel="noopener noreferrer"
          className={`transition duration-150 ease-in-out block text-black hover:opacity-75 py-4 border-solid border-b border-transparent hover:border-mainRed desktop-nav-item`}
        >
          <p className="whitespace-no-wrap text-center text-sm font-semibold">{item.name}</p>
        </a>
      ) : (
        <NavLink
          to={path}
          onClick={() => (item.club ? handleShowModal() : null)}
          className={`transition duration-150 ease-in-out block ${
            item.club ? 'text-mainGreenDark hover:border-mainGreenDark' : 'text-black hover:border-mainRed'
          } hover:opacity-75 py-4 border-solid border-b border-transparent desktop-nav-item`}
        >
          <p className={`whitespace-no-wrap text-center text-sm ${item.club ? 'font-bold' : 'font-semibold'}`}>
            {t(item.name)}
          </p>
        </NavLink>
      )}
    </>
  );
};

export default LinkItem;
