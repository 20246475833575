import React, { FC, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { isUserInfoModel, useStoreState } from '@store';
import { useInterval } from '@hooks';
import { updateStreamTime } from '@http/endpoints/movies';

interface Props {
  url: string;
  type: string;
  mediaID: string | number;
}

const StreamPlayer: FC<Props> = ({ url, type, mediaID }) => {
  const { userInfo } = useStoreState((state) => state.user);
  const countRef = useRef(0);
  const [start, setStart] = useState(false);

  useInterval(() => {
    if (start) {
      updateTime();
    } else {
      return;
    }
  }, 30000);

  async function updateTime() {
    // eslint-disable-next-line
    const { data, error } = await updateStreamTime({
      type,
      user_id: isUserInfoModel(userInfo) ? userInfo.id : -1,
      resource_id: mediaID,
    });
  }

  const handlePlayStart = () => {
    if (countRef.current === 0) {
      updateTime();
      countRef.current = 1;
    }
    setStart(true);
  };

  const handlePause = () => {
    setStart(false);
  };

  const handleEnd = () => {
    setStart(false);
  };

  return (
    <div className="trailer-container bg-black">
      <ReactPlayer
        playing
        onStart={handlePlayStart}
        onPause={handlePause}
        onEnded={handleEnd}
        url={url}
        controls
        height="100%"
        width="100%"
        pip
      />
    </div>
  );
};

export default StreamPlayer;
