import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import styles from './DiscreetPayment.module.scss';
import DiscreetIcon from '@images/diskrete/icon-saveuser.png';

type Props = {
  row?: boolean;
};

const DiscreetPayment: FC<Props> = ({ row }) => {
  const { t } = useTranslation();
  return (
    <>
      <hr style={{ width: row ? '100%' : '90%', color: 'black', marginTop: '11px', marginBottom: '8px' }} />
      <div className={`${row ? styles.container_row : styles.container}`}>
        <div className={styles.title_img_container}>
          <p className="font-bold mr-1 whitespace-nowrap">{t('100% discreet payment')}</p>
          <img src={DiscreetIcon} alt="DiscreetIcon" className={styles.discreet_img} />
        </div>
        <div className={`${row ? styles.beate_title_row : styles.beate_title}`}>
          &ldquo;{'Beate Uhse'}&rdquo; {t('will not appear on your statement.')}
        </div>
      </div>
      <hr style={{ width: row ? '100%' : '90%', color: 'black', marginBottom: '11px', marginTop: '11px' }} />
    </>
  );
};

export default DiscreetPayment;
