/* eslint-disable jsx-a11y/media-has-caption */
import React, { FC, memo } from 'react';
import 'video.js/dist/video-js.css';
import 'videojs-landscape-fullscreen';
import { usePlayer } from './hook';

type Props = {
  src: string;
  controls: boolean;
  autoplay: boolean;
  trailerThumb?: string;
  fromTrailer?: boolean;
  setShowRate?: (value: boolean) => void;
  mediaID?: number | string;
  type?: string;
};

const VideoPlayer: FC<Props> = ({ src, controls, autoplay, trailerThumb, type, mediaID, fromTrailer, setShowRate }) => {
  const playerRef = usePlayer({
    src,
    controls,
    autoplay,
    trailerThumb,
    type,
    mediaID,
    fromTrailer,
    setShowRate,
  });

  return (
    <div data-vjs-player>
      <video ref={playerRef} preload="none" className="video-js" />
    </div>
  );
};

export default memo(VideoPlayer);
