import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ListItemModel } from '../ListItem/types';
import NewIcon from '@images/NEW-BU-icon.png';
import NeuIcon from '@images/NEU-BU-icon.png';
import ReducedIcon from '@images/DISCOUNT-BU-icon.png';
import ReduziertIcon from '@images/RABATT-BU-icon.png';
import ReducedGermanIcon from '@images/reduced+german-icon.png';
import ReduziertGermanIcon from '@images/reduziert+german-icon.png';
import VipFlag from '@images/FREE VIP-BU-icon.png';
import VipDeFlag from '@images/GRATIS VIP-BU-icon.png';
import Top100Icon from '@images/TOP100-BU-icon.png';
import Top100DeIcon from '@images/TOP100FILM-BU-icon.png';
import GermanAudio from '@images/DE-BU-icon.png';




export const iconChange = {
  newIcon: { en: NewIcon, de: NeuIcon },
  redIcon: { en: ReducedIcon, de: ReduziertIcon },
  redGerIcon: { en: ReducedGermanIcon, de: ReduziertGermanIcon },
  vipIcon: { en: VipFlag, de: VipDeFlag },
  topIcon: { en: Top100Icon, de: Top100DeIcon }
};

type Props = {
  isLoaded?: boolean,
  data: ListItemModel;
}

const CoverFlags: FC<Props> = ({isLoaded, data}) => {

  const { i18n } = useTranslation();

  return (
    <>
      {data.new && isLoaded ? (
        <div className="new-icon">
          <img
            src={iconChange.newIcon[i18n.language as keyof (typeof iconChange)['newIcon']]}
            alt="new icon"
            className="w-full"
          />
        </div>
      ) : null}
      {data.reduced && isLoaded ? (
        <div className="reduced-icon">
          <img
            src={iconChange.redIcon[i18n.language as keyof (typeof iconChange)['redIcon']]}
            alt="reduced movie"
            className="w-full"
          />
        </div>
      ) : null}
      <div className={'audio-vip-top100-section'}>
        {data.is_svod && isLoaded ? (
          <div className="flag">
            <img
              src={iconChange.vipIcon[i18n.language as keyof (typeof iconChange)['vipIcon']]}
              alt="vip free icon"
              className="w-full" />
          </div>
        ) : null}
        {data.needs_icon && isLoaded ? (
          <div className="flag">
            <img src={GermanAudio} alt="german audio icon" className="w-full" />
          </div>
        ) : null}
        {data.is_top100 && isLoaded ? (
          <div className="flag">
            <img
              src={iconChange.topIcon[i18n.language as keyof (typeof iconChange)['topIcon']]}
              alt="top 100 movie icon"
              className="w-full" />
          </div>
        ) : null}
      </div>
    </>
  );
};

export default CoverFlags;