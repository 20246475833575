import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SvodPriceModel } from '../../../../../pages/unauthenticated/svod-deal/types';

type Props = {
  data: SvodPriceModel;
  days: number;
  priceOne: number;
  priceTotal: number;
  months: number;
};
const PlanPrice: FC<Props> = ({ data, days, priceOne, priceTotal, months }) => {
  const { t } = useTranslation();

  return (
    <div className={`w-full  mx-auto md:pl-4 mt-4 md:mt-8`}>
      <div
        className={`relative bg-mainGray1 rounded-lg overflow-hidden ${
          data.best_offer === 1 || data.best_offer === '1' ? 'border-2 border-mainRed' : ''
        }`}
      >
        <p
          className={`text-center py-4 ${
            data.best_offer === 1 || data.best_offer === '1' ? 'bg-mainRed' : 'bg-black'
          } ${
            data.best_offer === 1 || data.best_offer === '1' ? 'text-white' : 'text-white'
          } px-4 text-sm font-semibold`}
        >
          {days ? `${days}-${t('Days Test Access')}` : `${months}-${t('Month Membership')}`}
        </p>
        <div className="">
          <p className="text-center text-xl font-semibold mt-6">
            €{priceOne ? priceOne.toFixed(2) : ''}
            {days ? '' : ` / ${t('Month')}`}
          </p>
          <div className="w-12 mt-8 border-b-2 mx-auto border-black"></div>
          <p className="text-center opacity-79 mt-4">{!days ? `${t('Total Today')} €${priceTotal}` : ''}</p>
        </div>
      </div>
    </div>
  );
};

export default PlanPrice;
