import React from 'react';
import LoginForm from '@common/modals/login/components/LoginForm';
import FormButton from '@common/ui-components/buttons/FormButton';
import Translation from '@common/translation';
import Logo from '@images/registration/BUM-logo 2.png';
import { useStoreState } from '@store';


type Props = {
  hide: () => void;
  handleSwithRegister: () => void;
  handleResetShow: () => void;
};

const Login = ({
                 hide,
                 handleSwithRegister,
                 handleResetShow,
               }: Props) => {

  const { showLoading } = useStoreState((state) => state);
  return (
    <div className={'login-form-container gap-6'}>
      <div
        className={`md:absolute mx-auto left-0 right-0 lg:static transition-all duration-300 ease-in-out`}
      >
        <img className={`logo-site pt-2 xxl:pt-0`} src={Logo} alt="site logo" />
      </div>
      <div className={'form-content'}>
        <Translation use={'Sign in'} as={'p'} className={'text-black tracking-wider text-center self-center uppercase'} />
        <LoginForm hide={hide} handleResetShow={handleResetShow} />
        <div className="w-full pt-3">
          <hr className="hor-line" />
          <Translation use={'Not registered yet'} as={'p'} className={'text-center pt-4 pb-2 text-black'} />
          <FormButton
            text="Create an account"
            textColor="white"
            handleClick={handleSwithRegister}
            bgColor="mainRed"
            type="button"
            loading={showLoading}
          />
        </div>
      </div>
    </div>
  );
};


export default Login;