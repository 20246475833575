import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const languagesData = [
  { id: 1, code: 'EN', image: 'https://cdn-gcore.beate-uhse-movie.com/english.png' },
  { id: 2, code: 'DE', image: 'https://cdn-gcore.beate-uhse-movie.com/german.png' },
];

const BasicDropdown: FC = () => {
  const [language, setLanguage] = useState('de');
  const [showMenu, setShowMenu] = useState(false);

  const { i18n } = useTranslation();
  useEffect(() => {
    const storedLan = localStorage.getItem('user_lang');
    if (storedLan) {
      setLanguage(storedLan);
      i18n.changeLanguage(storedLan);
    } else {
      i18n.changeLanguage('de');
      setLanguage('de');
    }
  }, [i18n]);

  // useEffect(() => {
  //   i18n.changeLanguage(language);
  //   alert(language);
  // }, [language, i18n]);

  const handleSetLanguage = async (lan: string): Promise<void> => {
    setLanguage(lan);
    setShowMenu(false);
    localStorage.setItem('user_lang', lan);

    await i18n.changeLanguage(lan);

    setTimeout(() => window.location.reload(), 200);
  };

  return (
    <div className="hidden lg:block relative inline-block text-left z-50 w-16 ml-2">
      <div onMouseEnter={() => setShowMenu(true)} onMouseLeave={() => setShowMenu(false)}>
        <button className="inline-block px-2 py-1 bg-gray-300 text-black rounded-md focus:outline-none">
          {language.includes('en') || language.includes('EN') ? 'EN' : 'DE'} <i className="fas fa-caret-down ml-2"></i>
        </button>
      </div>

      <div
        className={`${showMenu ? 'visible' : 'hidden'} h-${
          showMenu ? 'full' : '0'
        } origin-top-right absolute right-0  w-20 rounded-md shadow-lg`}
      >
        <div
          className="rounded-md shadow-md z-50"
          onMouseEnter={() => setShowMenu(true)}
          onMouseLeave={() => setShowMenu(false)}
        >
          <div className="py-1 rounded shadow-md bg-white" role="menu">
            <>
              {languagesData.map((item) => {
                return (
                  <div
                    onKeyDown={() => handleSetLanguage(item.code.toLowerCase())}
                    tabIndex={0}
                    key={item.id}
                    className="block px-2 py-2 text-sm leading-5 bg-white hover:opacity-75 cursor-pointer inline-flex justify-between w-full"
                    role="menuitem"
                    onClick={() => handleSetLanguage(item.code.toLowerCase())}
                  >
                    <span className="inline-block text-lg">{item.code}</span>
                    <img src={item.image} alt="EN flag icon" className="w-5 h-5" />
                  </div>
                );
              })}
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicDropdown;
