import { action, computed } from 'easy-peasy';
import { MovieModel } from '@store/model/types';


const moviesModel: MovieModel = {
  data: {},
  setPage: action((state, payload) => {
     state.data = {
       ...state.data,
       [payload.type]: {
         ...state.data[payload.type],
         page:  payload.data
       }
     };
  }),
  setMovies: action((state, payload) => {
    const movies = state.data?.[payload.type].movies;
    state.data = {
      ...state.data,
      [payload.type]: {
        ...state.data[payload.type],
        movies: {
          ...movies,
          [payload.data.page]: payload.data.list
        }
      }
    };
  }),
  decTempPage:  action((state, payload) => {
    state.data = {
      ...state.data,
      [payload.type]: {
        ...state.data[payload.type],
        tempPage:  state.data[payload.type]?.tempPage - 1 || 0
      }
    };
  }),
  setTempPage: action((state, payload) => {
    state.data = {
      ...state.data,
      [payload.type]: {
        ...state.data[payload.type],
        tempPage: payload.data
      }
    };
  }),
  clearTempPage: action((state, payload) => {
    state.data = {
      ...state.data,
      [payload.type]: {
        ...state.data[payload.type],
        tempPage: undefined
      }
    };
  }),
  getNextPage: action((state, payload) => {
    state.data = {
      ...state.data,
      [payload.type]: {
        ...state.data[payload.type],
        page: state.data[payload.type]?.page + 1
      }
    };
  }),
  setHasMore: action((state, payload) => {
    state.data = {
      ...state.data,
      [payload.type]: {
        ...state.data[payload.type],
        hasMore:  payload.data
      }
    };
  }),
  setHasMoreTop: action((state, payload) => {
    state.data = {
      ...state.data,
      [payload.type]: {
        ...state.data[payload.type],
        hasMoreTop:  payload.data
      }
    };
  }),
  setScrollPosition: action((state, payload) => {
    state.data = {
      ...state.data,
      [payload.type]: {
        ...state.data[payload.type],
        scrollPosition:  payload.data
      }
    };
  }),
  setType: action((state, payload) => {
    state.data = {
      ...state.data,
      [payload.type]: {
        ...state.data[payload.type],
        ...payload.data
      }
    };
  }),
  setData: action((state, payload) => {
    state.data = {
      ...state.data,
      [payload.type]: {
        ...state.data[payload.type],
        ...payload.data,
        movies: state.data[payload.type]?.movies || {}
      }
    };
  }),
  resetData: action((state, payload) => {
    state.data = {
      ...state.data,
      [payload.type]: {
        ...state.data[payload.type],
        page: 1,
        scrollPosition: 0,
        movies: {},
        hasMore: true,
      }
    };
  }),
  getDataByType: computed((state) => {
    return (type: string, typeId?: string) =>  state?.data?.[`${type}${typeId ? `-${typeId}` : ''}`];
  }),
};


export default moviesModel;