import React, { FC, useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { Star } from './components/Star';
import { useStoreActions } from '@store';
import { rateMovie } from '@http/endpoints/movies';

const arr = [1, 2, 3, 4, 5];

type Props = {
  isAuth: boolean;
  movieID: number;
  refetchData?: () => void;
  averageRate: number;
  ratingsNumber: number;
  closeModal: () => void;
};

const RatingStars: FC<Props> = ({ isAuth, movieID, refetchData, averageRate, ratingsNumber, closeModal }) => {
  const [active, setActive] = useState<boolean>(true);
  const [isHalf, setIsHalf] = useState<number | null>(null);
  const { addToast } = useToasts();
  const { setShowLogin } = useStoreActions((actions) => actions);

  useEffect(() => {
    const iWholeStars = Math.floor(averageRate);
    const blnHalfStar = iWholeStars < averageRate;
    if (blnHalfStar) setIsHalf(iWholeStars);
    else setIsHalf(null);
  }, [averageRate]);

  const handleRate = async (value: number) => {
    if (isAuth) {
      setActive(false);
      const { data, error } = await rateMovie({
        movie_id: movieID,
        rate: value,
      });
      if (data) {
        if (data.data?.message) {
          addToast(data.data.message, {
            appearance: 'success',
          });
        }
        if (refetchData) {
          refetchData();
        }
        if (closeModal) closeModal();
      } else if (error) {
        if (error.response?.data?.message)
          addToast(error.response.data.message, {
            appearance: 'warning',
          });
        if (closeModal) closeModal();
      }
    } else {
      setShowLogin(true);
    }
  };

  return (
    <div
      className={`flex content-center items-center justify-center ${
        active ? 'rating-stars-container-active' : 'rating-stars-container-inactive'
      }`}
    >
      {arr.map((item) => (
        <Star
          handleRate={handleRate}
          type={
            item <= averageRate
              ? 'fas fa-star'
              : isHalf !== null && isHalf + 1 === item
              ? 'fas fa-star-half-alt'
              : ' far fa-star'
          }
          item={item}
          key={item}
        />
      ))}
      <span className="text-sm font-semibold opacity-90">({ratingsNumber})</span>
    </div>
  );
};

export default RatingStars;
