

export enum RegistrationBonusPages {
  VIP = 0x2,
  VIP_FINISH= 0x4,
  BONUS = 0x8,
  BONUS_FINISH = 0x10,
  PROMO = 0x11,
  PROMO_FINISH=0x12,
  REGISTER_PROMO_CODE_PICKER= 0x17
}

export type TRegistrationBonusesReducer = {
  activePage: RegistrationBonusPages
  nextPage?: RegistrationBonusPages
  lastPage?: RegistrationBonusPages
  isNeedBack?: boolean
  formData?: any
  errorData?: any
}



export enum REGISTRATION_BONUSES_EVENTS {
  SET_ACTIVE_PAGE = 'SET_ACTIVE_PAGE',
  SET_NEXT_PAGE = 'SET_NEXT_PAGE',
  CLEAR_NEXT_PAGE = 'CLEAR_NEXT_PAGE',
  GO_BACK = 'GO_BACK'
}


export type TRegistrationBonusesActions = {
  type: REGISTRATION_BONUSES_EVENTS,
  payload?: any
}

export const initialRegistrationBonusesData = {
  activePage: RegistrationBonusPages.REGISTER_PROMO_CODE_PICKER,
};