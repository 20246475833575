import React, { lazy, useCallback, useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { useLocalStorage } from 'react-use';
import { useTranslation } from 'react-i18next';
import { MoviePurchaseInfoModel } from './types';
import { isUserInfoModel, useStoreActions, useStoreState } from '@store';
import { buyStreamMovies, confirmPaymentRedirect } from '@http/endpoints/payments';

const Home = lazy(() => import('./home/Home'));
const AdminLogin = lazy(() => import('./static-pages/AdminLogin'));
const Categories = lazy(() => import('./categories/Categories'));
const Movies = lazy(() => import('./movies/Movies'));
const Promotions = lazy(() => import('./promotions/Promotions'));
const Movie = lazy(() => import('./movie/MoviePage'));
const Scene = lazy(() => import('./scene/Scene'));
const Scenes = lazy(() => import('./scenes/Scenes'));
const Studios = lazy(() => import('./studios/Studios'));
const Stars = lazy(() => import('./stars/Stars'));
const Legal = lazy(() => import('./static-pages/legal/LegalInformation'));
const Page2257 = lazy(() => import('./static-pages/page2257/Page2257'));
const Privacy = lazy(() => import('./static-pages/privacy/Privacy'));
const Terms = lazy(() => import('./static-pages/terms/Terms'));
const Results = lazy(() => import('./search-results/Results'));
const RGSuccess = lazy(() => import('./static-pages/rg-response/RGSuccess'));
const RGError = lazy(() => import('./static-pages/rg-response/RGError'));
const Page404 = lazy(() => import('../page404/Page404'));
const Star = lazy(() => import('./star-page/Star'));
const MemberPlan = lazy(() => import('./member-plan/MemberPlan'));
const EmailCampaigns = lazy(() => import('./email-campaigns/EmailCampaigns'));
const Top100 = lazy(() => import('./top100/Top100'));

const UnauthenticatedRoutes = ({ match: { path } }: { match: { path: string } }) => {
  const root = path === '/' ? '' : path;
  const { userInfo } = useStoreState((state) => state.user);
  const { updateUser } = useStoreActions((state) => state.user);
  const { t } = useTranslation();
  const [paymentID, setPaymentID] = useState<number | null>(null);
  const { setShowDeposit } = useStoreActions((actions) => actions);
  const [storageValue, setStorageValue, removeStorageValue] = useLocalStorage('movie-purchase-info', '');

  const { addToast } = useToasts();

  useEffect(() => {
    if (isUserInfoModel(userInfo) && userInfo?.last_payment_id) {
      setPaymentID(userInfo?.last_payment_id);
    }
  }, [userInfo]);

  const handleBuyMovie = useCallback(
    async (values: MoviePurchaseInfoModel, toReload: boolean) => {
      const { data, error } = await buyStreamMovies(values);
      if (error) {
        addToast(error.response.data.message, { appearance: 'error' });
        await setStorageValue('');
        removeStorageValue();
      }
      if (data) {
        if (data.data?.status === 'success') {
          updateUser();
          addToast(data.data.message, { appearance: 'success' });
          await setStorageValue('');
          await removeStorageValue();
          if (toReload) {
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
        }
      }
    },
    [addToast, removeStorageValue, setStorageValue, updateUser]
  );

  const sendPaymentID = useCallback(async () => {
    const { data, error } = await confirmPaymentRedirect({
      payment_id: paymentID,
    });
    if (error) {
      if (error?.response?.data?.message) {
        addToast(error.response.data.message, { appearance: 'error' });
      }
      if (error?.response?.data?.data?.open_modal) {
        setShowDeposit(true);
      }
    }
    if (data) {
      updateUser();
      addToast(t('Your payment was successful'), { appearance: 'success' });
      if (storageValue) {
        const values = {
          ...JSON.parse(storageValue),
        };
        handleBuyMovie(values, true);
      } else {
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    }
  }, [addToast, handleBuyMovie, paymentID, setShowDeposit, storageValue, t, updateUser]);

  // Check if redirected from payment service, if user have last_payment_id, send
  // paymentID to BE to confirm payment, also check localStorage to countinue buying movie/scene
  // if deposit is updated, buy movie/scene with data from localStorage
  useEffect(() => {
    if (paymentID) {
      sendPaymentID();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addToast, paymentID]);

  return (
    <Switch>
      <Route exact path={`${root}/`} component={Home} />
      <Route path={`${root}/admin-login/:token?`} component={AdminLogin} />
      <Route path={`${root}/categories/:page?`} component={Categories} />
      <Route path={`${root}/studios/:page?`} component={Studios} />
      <Route path={`${root}/stars/:page?`} component={Stars} />
      <Route path={`${root}/star/:id?/:name?`} component={Star} />
      <Route path={`${root}/scenes/:scenestype`} component={Scenes} />
      <Route path={`${root}/top-100/:type?/:page?`} component={Top100} />
      <Route path={`${root}/movies/:moviestype?/:id?/:name?`} component={Movies} />
      <Route path={`${root}/movie/:id?/:title?`} component={Movie} />
      <Route path={`${root}/promotions/:id`} component={Promotions} />
      <Route path={`${root}/scene/:id`} component={Scene} />
      <Route path={`${root}/search/:type?/:value?`} component={Results} />
      <Route path={`${root}/member-plan`} component={MemberPlan} />
      <Route path={`${root}/legal`} component={Legal} />
      <Route path={`${root}/privacy`} component={Privacy} />
      <Route path={`${root}/terms`} component={Terms} />
      <Route path={`${root}/2257`} component={Page2257} />
      <Route path={`${root}/success`} component={RGSuccess} />
      <Route path={`${root}/failed`} component={RGError} />
      <Route path={`${root}/email-campaigns/:id`} component={EmailCampaigns} />
      <Route path={`${root}/404`} component={Page404} />
      <Route path="*" component={Page404} />
    </Switch>
  );
};

export default UnauthenticatedRoutes;
