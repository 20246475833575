/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { FC, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { IActor, ICategory, IMovie, ISearchSuggestion, IStudio } from './types';
import StarIcon from '@images/placeholders/actors.png';
import CategoryIcon from '@images/placeholders/category.png';
import StudioIcon from '@images/placeholders/studio.png';

type Props = {
  data: ISearchSuggestion | [];
  handleSuggestionClick: (id: string | number, type: string, name: string, key?: string) => void;
  focusNext?: boolean;
  handleAllResults?: (click?: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
  setFocusNext?: (value: boolean) => void;
  clearSearch?: () => void;
};
const Suggestions: FC<Props> = ({
  data,
  handleSuggestionClick,
  focusNext,
  handleAllResults,
  setFocusNext,
  clearSearch,
}) => {
  const { t } = useTranslation();
  const ref = useRef<any>(null);
  useEffect(() => {
    if (ref !== null && focusNext) {
      if (ref.current?.firstChild) {
        ref.current.firstChild.focus();
      }
    }
  }, [ref, focusNext]);
  const handleKeyPress = (id: string | number, type: string, name: string, key: string) => {
    if (key === 'Enter') {
      handleSuggestionClick(id, type, name, key);
    }
  };
  const handleKeyPressAllResults = (key: string) => {
    if (key === 'Enter' && handleAllResults) {
      handleAllResults();
    }
  };
  const handleKeyDown = (e: any) => {
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      if (e.target.nextSibling) {
        e.target.nextSibling.focus();
      }
    }
  };
  const handleKeyUp = (e: any) => {
    setFocusNext && setFocusNext(false);
    if (e.key === 'ArrowUp') {
      e.preventDefault();
      if (e.target.previousElementSibling) {
        e.target.previousElementSibling.focus();
      }
    } else if (e.key === 'Escape') {
      if (clearSearch) '';
    }
  };

  return (
    <ul ref={ref}>
      {Object.keys(data).length
        ? Object.keys(data).map((item: string) =>
            (data as ISearchSuggestion)[item as keyof ISearchSuggestion].length
              ? (data as ISearchSuggestion)[item as keyof ISearchSuggestion].map((listItem) => {
                  const type = listItem.pagination_type;
                  const name =
                    type === 'actors'
                      ? (listItem as IActor).full_name
                      : type === 'studios' || type === 'categories'
                      ? (listItem as IStudio | ICategory).name
                      : type === 'movies'
                      ? (listItem as IMovie).title
                      : '';
                  const image =
                    type === 'actors'
                      ? StarIcon
                      : type === 'studios'
                      ? StudioIcon
                      : type === 'categories'
                      ? CategoryIcon
                      : type === 'movies'
                      ? (listItem as IMovie)?.thumbnails?.small
                        ? (listItem as IMovie).thumbnails.small
                        : (listItem as IMovie)?.thumbnails?.original
                        ? (listItem as IMovie).thumbnails.original
                        : ''
                      : '';
                  return (
                    <li
                      key={listItem.id}
                      onKeyDown={(e) => handleKeyDown(e)}
                      onKeyUp={(e) => handleKeyUp(e)}
                      onKeyPress={(e) => handleKeyPress(listItem.id, type, name, e.key)}
                      onClick={() => handleSuggestionClick(listItem.id, type, name)}
                      className="suggestion-list border-b w-full border-mainGray2 hover:bg-mainGray1 focus:outline-none cursor-pointer focus:bg-mainGray1 m-0"
                    >
                      <div className="flex content-center items-center">
                        <div
                          className={`${
                            type === 'actors' || type === 'studios' || type === 'categories' ? 'p-1' : ''
                          } suggestion-placeholder bg-white w-10 flex content-center items-center justify-center`}
                        >
                          <img className="w-full" src={image} alt="img" />
                        </div>
                        <div className="p-3 w-full opacity-8 ellipsis">{name}</div>
                        <div className="p-3 opacity-50 italic text-sm">{t(type[0].toUpperCase() + type.slice(1))}</div>
                      </div>
                    </li>
                  );
                })
              : null
          )
        : null}
      {(data as ISearchSuggestion)?.categories?.length ||
      (data as ISearchSuggestion)?.actors?.length ||
      (data as ISearchSuggestion)?.movies?.length ||
      (data as ISearchSuggestion)?.studios?.length ? (
        <li
          onKeyDown={(e) => handleKeyDown(e)}
          onKeyUp={(e) => handleKeyUp(e)}
          onKeyPress={(e) => handleKeyPressAllResults(e.key)}
          onClick={(e) => handleAllResults && handleAllResults(e)}
          className="suggestion-list border-b w-full border-mainGray2 hover:bg-mainGray1 focus:outline-none cursor-pointer focus:bg-mainGray1 m-0"
        >
          <div className="flex content-center items-center">
            <div className="p-3 w-full opacity-8 ellipsis">{t('All Results')}</div>
          </div>
        </li>
      ) : null}
    </ul>
  );
};

export default Suggestions;
