import moment from 'moment';

export const formatPrice = (number, options= {}) => {
  // eslint-disable-next-line compat/compat
  const formatter  = new Intl.NumberFormat('de-DE', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    ...options
  });
  return formatter.format(number);
};

export const formatCurrency = (number) => formatPrice(number, {
  style: 'currency',
  currency: 'EUR'
});


export const sleep = async (ms) => new Promise(resolve => setTimeout(resolve, ms));


export const formatDate =  (date, format = 'DD.MM.YYYY') => {
  return moment(date).format(format);
};