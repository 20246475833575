import React, { FC } from 'react';
import styles from './MoviePagePriceButton.module.scss';
import PlayIcon from '@images/movie-page-price-buttons/icon-stream.png';
import DownloadIcon from '@images/movie-page-price-buttons/icon-download.png';

type Props = {
  text: string;
  price: { price: string | number; oldPrice: string | number };
  handleClick: () => void;
  type?: string;
};

const MoviePagePriceButton: FC<Props> = ({ text, price, handleClick, type }) => {
  const isDownload = type === 'download';
  return (
    <div onKeyDown={handleClick} role="button" tabIndex={0} onClick={handleClick} className={styles.container}>
      {isDownload ? (
        <img src={DownloadIcon} alt="DownloadIcon" className={styles.download_icon} />
      ) : (
        <img src={PlayIcon} alt="PlayIcon" className={styles.play_icon} />
      )}
      <p className={styles.text_container}>
        <div className={styles.text}>{text}</div>
      </p>
      <p className={styles.price_container}>
        {price.price !== price.oldPrice ? (
          <div className={styles.old_price_container}>
            <del className={styles.old_price}>{`${Number(price.oldPrice).toFixed(2).replace('.', ',')} €`}</del>
            {`${Number(price.price).toFixed(2).replace('.', ',')} €`}
          </div>
        ) : (
          `${Number(price.price).toFixed(2).replace('.', ',')}€`
        )}
      </p>
    </div>
  );
};

export default MoviePagePriceButton;
