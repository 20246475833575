import React, { useCallback, useEffect, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useFormikContext } from 'formik';
import Skeleton from 'react-loading-skeleton';
import style from '../VipPlusForm.module.scss';
import { SvodPriceModel } from '../../../../../../pages/unauthenticated/svod-deal/types';
import { getOnlySvodPrices } from '@http/endpoints/payments';
import Translation from '@common/translation';
import VipPlanPrice from '@common/modals/register/components/vip-plus-packages/Prices/VipPlanPrice';

const VipPlans = () => {


  const { values, errors, setFieldValue } = useFormikContext();

  const { planId } = values as any;

  const { data, isLoading, isFetched } = useQuery(
    ['svod-prices'],
    getOnlySvodPrices,
  );

  const error = useMemo(() => (errors as any)?.planId, [errors]);

  const loading = useMemo(() => isLoading && !isFetched, [isLoading, isFetched]);
  const plans = useMemo(() => {
    const array = data?.data || [];
    if(!array?.length) return;
    return array.sort((a:SvodPriceModel,b:SvodPriceModel) => b?.trial - a?.trial);
  }, [data]);


  useEffect(() => {
    if(!plans?.length || (values as any)?.planId) return;
    const pack = plans?.find((x: any)=> x.price == 4.99);
    setFieldValue('planId',pack?.id, false).then();
    setFieldValue('selectedPackages', pack || null, false).then();
  }, [plans, values]);


  const handleSelectPrice = useCallback((value: number) => {
    setFieldValue('planId', value, false).then();
    setFieldValue('selectedPackages', plans?.find((x: any)=> x.id === value) || null, false).then();
  }, [setFieldValue, plans]);

  return (
    <div className={'flex flex-col flex-1 gap-5 relative'}>
      <Translation use={'selectPackage'} as={'p'} className={'text-md font-normal'} />
      <div className={'flex flex-col gap-2'}>
        {!loading && plans?.length ? plans.map((plan: any, index: number) =>
            <VipPlanPrice
              handleSelectPrice={handleSelectPrice}
              data={plan}
              key={index}
              currentPrice={planId}
            />)
          : [...Array(4).keys()].map((x) => (<div key={x + 1} className={`${style.vipPlusSkeletonDiv}`}><Skeleton
                                                                                  className={'w-full h-full'} /></div>))}
      </div>
      {error && <Translation use={error} as={'div'}  className={`${style.vipPlusErrorShow} text-red-600 text-sm`} />}
    </div>
  );
};

export default VipPlans;