import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useToasts } from 'react-toast-notifications';
import { Form, Formik } from 'formik';
import { object } from 'yup';
import { setUser } from '@sentry/react';
import { LOCAL_STORAGE } from '../../../../types/localStorage';
import { sendPaymentData, testPayments } from '@http/endpoints/payments';
import { RegistrationPages } from '@common/modals/register/context/d';
import { rejectPromoRegistration } from '@http/endpoints/user';
import { sleep } from '@helpers/utils';
import { removeLSItem } from '@helpers/getLocalStorage';
import { useStoreActions, useStoreState } from '@store';
import FormButton from '@common/ui-components/buttons/FormButton';
import { IconArrowBack } from '@common/modals/register/comon/icons';
import Logo from '@images/registration/BUM-logo 2.png';
import { BonusPackagesSchema, VipPlusPackagesSchema } from '@common/modals/register/components/d';
import VipPlusPackages from '@common/modals/register/components/vip-plus-packages';
import RegistrationPromoCodePicker from '@common/modals/register/components/registerPromo';
import { RegistrationBonusPages } from '@common/modals/registerPromo/context/d';
import RegistrationBonusesContext from '@common/modals/registerPromo/context/context';
import Bonus from '@common/modals/register/components/bonus';
import SuccessPage from '@common/modals/registerPromo/success-page';

const url = window.location.protocol + '//' + window.location.host.split(':')[0];
const isTest = false;
const RegPromoBonusForm = ({hide}: {hide: ()=> void}) => {
  const { showLoading } = useStoreState((state) => state);
  const { setLoading } = useStoreActions((actions) => actions);
  const { isAuthenticated } = useStoreState((state) => state.user);
  const { updateUser } = useStoreActions((actions) => actions.user);
  const { addToast } = useToasts();
  const {
    isNeedBack,
    activePage,
    setActivePage,
    nextPage,
    goBackPage,
    formData,
  } = useContext(RegistrationBonusesContext);

  const schema = useMemo(() => {
    if (activePage === RegistrationBonusPages.VIP) return VipPlusPackagesSchema;
    if (activePage === RegistrationBonusPages.BONUS) return BonusPackagesSchema;
    return object({});
  }, [activePage]);


  const Component = useMemo(() => {
    switch (activePage) {
      case RegistrationBonusPages.VIP:
        return VipPlusPackages;
      case RegistrationBonusPages.BONUS:
        return Bonus;
      case RegistrationPages.BONUS_FINISH:
      case RegistrationPages.VIP_FINISH:
      case RegistrationPages.PROMO_FINISH:
        return SuccessPage;
      default:
        return RegistrationPromoCodePicker;
    }
  }, [activePage]);

  const sendDataToPP = useCallback(async (_data: {
    amount: number,
    paymentMethod: string,
    redirectUrl: string,
    filter?: 'svod' | 'deposit',
    promoCode?: string
  }) => {
    const { paymentMethod, amount, redirectUrl, filter, promoCode } = _data;
    const { data, error } = await sendPaymentData({
      amount: amount,
      payment_method: paymentMethod,
      redirect_url: redirectUrl,
      filter: filter || 'deposit',
      is_registration: true,
      promoCode
    });
    if (error?.response?.data?.message) {
      setLoading(false);
      addToast(error.response.data.message, {
        appearance: 'warning'
      });
      return;
    }
    if (data) {
      const redirectUrl = data?.data?.data?.redirect_url;
      window.location.href = redirectUrl ? redirectUrl : `${url}/`;
      setLoading(false);
    } else {
      setLoading(false);
      /** do something if we don't have data */
    }

  }, [addToast, setLoading]);

  const handleRegisterPromoPicker = useCallback(async (values: any) => {
    if (!isAuthenticated) {
      hide();
      return;
    }
    try {
      setLoading(true);
      const { data, error } = await rejectPromoRegistration();
      if(error) {
        throw error;
      }
      if(data?.status === 200) {
        setUser({
          ...data.data.data.user,
          ...data.data.data.user_info
        });
        addToast(data?.data?.message, {
          appearance: 'success'
        });
        hide();
      }
    }catch (error: any) {
      if (error.response?.data?.message) {
        addToast(error.response.data.message, {
          appearance: 'warning'
        });
        hide();
      }
    } finally {
      setLoading(false);
      removeLSItem(LOCAL_STORAGE.BONUS_MODAL_AUTO_OPEN);
      removeLSItem(LOCAL_STORAGE.BONUS_MODAL_DATA);
      removeLSItem(LOCAL_STORAGE.BONUS_SUCCESS);
    }
  }, [setLoading, isAuthenticated, hide, addToast]);

  const isSuccess = useMemo(() => {
    switch (activePage) {
      case RegistrationBonusPages.VIP_FINISH:
      case RegistrationBonusPages.BONUS_FINISH:
      case RegistrationBonusPages.PROMO_FINISH:
        return true;
      default:
        return false;
    }
  }, [activePage]);

  const handleSubmit = useCallback(async (values: any, {
    errors,
    setErrors,
    setFieldTouched,
    setFieldValue,
  }: any): Promise<void> => {
    if (nextPage) {
      if (nextPage === RegistrationPages.PROMO) {
        if (!values?.promoCode?.length) {
          setErrors({
            ...errors,
            'promoCode': 'pleaseEnterPromoCode',
          });
          setFieldTouched('promoCode', true, true);
          setFieldValue('promoCode', ' ');
          return;
        }
      }
      if (errors?.promoCode) {
        setErrors({
          ...errors,
          'promoCode': undefined,
        });
        setFieldTouched('promoCode', false, false);
      }
      setActivePage(nextPage);
      return;
    }
    if(activePage === RegistrationBonusPages.REGISTER_PROMO_CODE_PICKER) {
      await handleRegisterPromoPicker(values);
      return;
    }
    try {
      setLoading(true);
        if ([RegistrationBonusPages.VIP, RegistrationBonusPages.BONUS].includes(activePage)) {
          localStorage.setItem(LOCAL_STORAGE.BONUS_MODAL_DATA, JSON.stringify({
            formData: values,
            redirectURL: `${url}/`,
            activePage: values.activePage,
            isNeedBack: false
          }));
          localStorage.setItem(LOCAL_STORAGE.BONUS_MODAL_AUTO_OPEN, `${new Date().getTime()}`);
          if (isTest) {
            await sleep(4000);
            await testPayments({
              promoCode: values.promoCode,
            });
            await updateUser();
            /** failed payment */
            // window.location.href = 'http://localhost:3000/auth/payment-success?ans=NINVCVV2|1353899745&x_redirect_back=http://localhost:3000';
            /** success payment */
            //window.location.href = 'http://localhost:3000/auth/payment-success?ans=Y|1353899745&x_redirect_back=http://localhost:3000';
            /** Success RG */
            window.location.href='http://localhost:3000/success?flag=1900b64f6de&id=94725&hash=GJBJju67%2FG9cmaljBehqNdq4vqg%3D';
          } else {
            await sendDataToPP({
              amount: values?.priceId || values.planId,
              paymentMethod: values.paymentMethod,
              redirectUrl: `${url}${location.pathname}`,
              filter: activePage === RegistrationBonusPages.VIP ? 'svod' : 'deposit',
              promoCode: values?.promoCode
            });
          }
        }
    } catch (error: any) {
      if (error.response?.data?.message) {
        addToast(error.response.data.message, {
          appearance: 'warning',
        });
      }
      removeLSItem(LOCAL_STORAGE.BONUS_MODAL_AUTO_OPEN);
      removeLSItem(LOCAL_STORAGE.BONUS_MODAL_DATA);
      removeLSItem(LOCAL_STORAGE.BONUS_SUCCESS);
    } finally {
      setLoading(false);
    }
  }, [nextPage, setActivePage, setLoading, activePage, sendDataToPP, addToast]);


  return (
    <Formik
      initialValues={{
        ...(!isTest ? {
          promoCode: '',
          planId: '',
          priceId: '',
          paymentMethod: '',
        } : {
          promoCode: '',
          planId: '',
          priceId: '',
          paymentMethod: '',
        }),
        ...(formData || {}),
      }}
      validationSchema={schema}
      validateOnBlur={false}
      onSubmit={handleSubmit}
    >

      {({ values, setValues }) => {
        const handleGoBack = async () => {
          await setValues(Object.assign({
              ...values,
            },
            activePage === RegistrationBonusPages.VIP ? {
              planId: undefined,
              paymentMethod: undefined,
              selectedPackages: undefined,
            } : {},
            activePage === RegistrationBonusPages.BONUS ? {
              priceId: undefined,
              paymentMethod: undefined,
              promoCode: undefined,
              selectedPackages: undefined,
            } : {},
          ), false);
          goBackPage();
        };

        return (
          <div className={'wizard-form-container gap-6'}>
            {isNeedBack && !isSuccess &&
              <FormButton bgColor={''} textColor={'black'} className={'registration-back-button rounded'}
                          disabled={showLoading} icon={IconArrowBack} handleClick={handleGoBack} />}
            <div
              className={`mx-auto lg:static transition-all duration-300 ease-in-out`}
            >
              <img className={`logo-site pt-2 xxl:pt-0`} src={Logo} alt="site logo" />
            </div>
            <div className={'registration-form-container'}>
              <Form className={'registration-form'}>
                <Component hide={hide} />
              </Form>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

export default RegPromoBonusForm;