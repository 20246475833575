import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import AuthMenu from './AuthMenu';
import LanguageDropdown from '@common/ui-components/dropdowns/LanguageDropdown';
import { useStoreState } from '@store';

type Props = {
  handleLoginShow: () => void;
};

const LoginLang: FC<Props> = ({ handleLoginShow }) => {
  const { isAuthenticated } = useStoreState((state) => state.user);
  const { t } = useTranslation();

  return (
    <div className="flex content-center items-center">
      <LanguageDropdown />

      <div className="hidden lg:inline-flex justify-end space-x-2 items-center flex">
        {isAuthenticated ? null : (
          <button
            onClick={handleLoginShow}
            className="base-buttons py-1 px-1 focus:outline-none font-semibold mt-2 md:mt-0 w-24 transition duration-150 ease-in-out bg-mainRed outline-none hover:opacity-75 text-white rounded-md"
          >
            {t('Sign in')}
          </button>
        )}
        {isAuthenticated ? <AuthMenu /> : null}
      </div>
    </div>
  );
};

export default LoginLang;
